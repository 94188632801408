import Vue from 'vue';
import i18n from '../../i18n';
import { setUserInSentry } from '../../plugins/sentry';

const nullUserState = () => ({
  id: null,
  name: '',
  email: '',
  contactNumber: '',
  twofaMethod: null,
  tenants: [],
  emailVerifiedAt: null,
  language: '',
});

const getDefaultState = () => ({
  user: nullUserState(),
  userFetchingPromise: null,
});

function setUserForAnalytics(userId) {
  userId = App.helpers.getSubdomain() + userId;
  Vue.$ga.set('userId', userId);
  Vue.$ga.set('dimension1', userId);
}

const state = getDefaultState();

const getters = {
  user: (userState) => userState.user,

  userFetchingPromise: (userState) => userState.userFetchingPromise,

  isAuthenticated: (userState) => userState.user.id !== null,

  currentTenant: (userState) => {
    if (process.env.NODE_ENV !== 'production') {
      return state.user.tenants[0];
    }
    const subdomain = App.helpers.getSubdomain();

    // current Tenant will be null if tenant manager or user does not belong to that tenant.
    return userState.user.tenants.find(
      (tenantsWithAllowedApp) =>
        tenantsWithAllowedApp.subdomain === subdomain ||
        tenantsWithAllowedApp.initial_subdomain === subdomain,
    );
  },

  isFeatureSsoSubscribed: (_, userGetters) =>
    userGetters.currentTenant?.feature_sso_users_limit !== 0,
  isFeatureIpRestrictionSubscribed: (_, userGetters) =>
    userGetters.currentTenant?.is_feature_ip_restriction_subscribed === true,

  isSsoEnabled: (_, userGetters) =>
    userGetters.currentTenant?.is_sso_enabled === 1,

  isIPRestrictionEnabled: (_, userGetters) =>
    userGetters.currentTenant?.is_ip_restriction_enabled === true,

  isAdmin: (_, userGetters) =>
    userGetters.currentTenant?.tenant_user?.is_admin === true,

  isTenantManager: (userState) => userState.user.isTenantManager === 1,
};

const mutations = {
  updateUser: (userState, payload) => {
    userState.user = payload;
  },
  updateUserFetchingPromise: (userState, payload) => {
    userState.userFetchingPromise = payload;
  },
  resetUser: (userState) => {
    Object.assign(userState, getDefaultState());
  },
};

const actions = {
  getUserInfo(context) {
    const promise = axios.get('user');
    context.commit('updateUserFetchingPromise', promise);
    return promise
      .then((response) => {
        const user = response.data;
        context.commit('updateUser', {
          id: user.id,
          name: user.name,
          email: user.email,
          contactNumber: user.contact_number,
          twofaMethod: user.twofa_method,
          emailVerifiedAt: user.email_verified_at
            ? user.email_verified_at
            : null,
          language: user.language,
          tenants: user.tenants,
          isTenantManager: user.is_tenant_manager,
        });
        i18n.locale = user.language === null ? 'ja' : user.language;
        setUserForAnalytics(user.id);
        setUserInSentry(user.email);
      })
      .catch((error) => {
        this.dispatch('updateSSOUrl', error.response.data.ssoUrl);
        this.dispatch('updateSSOSubdomain', error.response.data.ssoSubdomain);
        this.dispatch(
          'updateStatusSSOActiveUsers',
          error.response.data.statusSSOActiveUsers,
        );
      });
  },
  storeUser: ({ commit }, user) => {
    const userId = user.id ? user.id : null;
    commit('updateUser', {
      id: userId,
      name: user.name ? user.name : null,
      email: user.email ? user.email : null,
      contactNumber: user.contact_number ? user.contact_number : null,
      twofaMethod: user.twofa_method ? user.twofa_method : null,
      tenants: user.tenants,
      emailVerifiedAt: user.email_verified_at ? user.email_verified_at : null,
      language: user.language ? user.language : 'ja',
      isTenantManager: user.is_tenant_manager,
    });
    setUserForAnalytics(userId);
    setUserInSentry(user.email);
  },
  setUserFetchingPromiseToNull: ({ commit }) => {
    commit('updateUserFetchingPromise', null);
  },
  resetUser: ({ commit }) => {
    commit('resetUser');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
