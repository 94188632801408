<template>
  <v-snackbar
    :value="snackbar.open"
    :timeout="snackbar.timeout"
    :multi-line="true"
    :color="snackbar.color"
    @input="snackbarStateChanged"
  >
    {{ snackbar.message }}

    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="CLOSE_SNACKBAR">
        {{ $t('common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('snackbar', ['snackbar']),
  },
  methods: {
    ...mapMutations('snackbar', ['CLOSE_SNACKBAR']),
    snackbarStateChanged(newState) {
      if (newState === false) {
        this.CLOSE_SNACKBAR();
      }
    },
  },
};
</script>
