<template>
  <v-row align="center">
    <v-col cols="5">
      <v-checkbox
        v-model="isEnabled"
        :label="message"
        hide-details
        class="mt-0"
        @change="$emit('updated:is-enabled', isEnabled)"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="selectedItem"
        :items="items"
        return-object
        :disabled="!isEnabled"
        hide-details
        outlined
        @change="$emit('updated:count', selectedItem)"
      />
    </v-col>
    <v-col cols="4">
      {{ subscriptionStatus }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    subscriptionStatus: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isEnabled: false,
    selectedItem: null,
  }),
};
</script>

<style></style>
