import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 title"},[(_vm.isEditing)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('departmentAddEditModal.editDepartment'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('departmentAddEditModal.addDepartment'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardText,{staticClass:"pb-0"},[_c(VTextField,{attrs:{"label":_vm.$t('departmentAddEditModal.name'),"rules":_vm.nameRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":!_vm.valid,"color":"accent"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('common.submit'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }