import { formatDistance, format, parse, subDays } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import i18n from '../i18n';

const dateTimeHelpers = {
  computed: {
    locale() {
      if (i18n.locale === 'ja') return ja;
      return enUS;
    },
  },

  methods: {
    getDateObject(dateTimeString = null) {
      if (dateTimeString === null || dateTimeString === '') {
        return new Date();
      }
      if (
        typeof dateTimeString === 'string' &&
        dateTimeString.split(' ').length > 1
      )
        return new Date(dateTimeString.replace(/-/g, '/'));
      return new Date(dateTimeString);
    },

    getTimeFromNow(dateString) {
      const date = this.getDateObject(dateString);

      if (date > new Date()) {
        return this.$t('common.fewMomentsAgo');
      }
      return formatDistance(date, new Date(), {
        locale: this.locale,
        addSuffix: true,
        includeSeconds: true,
      });
    },

    formatDate(dateString) {
      const date = this.getDateObject(dateString);
      return format(date, 'yyyy/M/d', { locale: this.locale });
    },

    getDateOfYesterday(dateString) {
      const date = this.getDateObject(dateString);
      const yesterday = subDays(date, 1);
      return format(yesterday, 'yyyy/M/d', { locale: this.locale });
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return format(date, 'yyyy/M/d (E) HH:mm:ss', { locale: this.locale });
    },

    formatDateTime(dateString) {
      const date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
      return format(date, 'yyyy/M/d (E) HH:mm', { locale: this.locale });
    },

    getCurrentTimestamp() {
      const now = new Date();
      return format(now, 'yyyyMMddHHmmss', { locale: this.locale });
    },
  },
};

export default dateTimeHelpers;
