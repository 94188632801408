import store from './store/index';

const openSnackbar = (message, color = 'info', timeout = 3000) => {
  store.commit('snackbar/OPEN_SNACKBAR', {
    message,
    color,
    timeout,
  });
};

export default openSnackbar;
