<template>
  <v-main>
    <v-container :style="{ 'max-width': '1200px' }">
      <div class="pl-3 pb-2 headline">{{ $t('twofa.twofa') }}</div>
      <div class="pl-3 pb-2 body-2">{{ $t('twofa.setUp') }}</div>
      <v-card
        v-if="user.twofaMethod !== null && steps === 1"
        class="my-3 pa-3 pl-6 body-2"
      >
        <span class="subtitle-2">{{ $t('twofa.isOn') }}</span>
        <v-btn class="ml-12" color="primary" @click="disableTwofa">
          {{ $t('twofa.turnOff') }}
        </v-btn>
      </v-card>

      <v-stepper v-model="steps">
        <v-stepper-header>
          <v-stepper-step :complete="steps > 1" step="1">
            {{ $t('twofa.chooseMethod') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="steps > 2" step="2">
            {{ $t('twofa.storeBackupCodes') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">{{ $t('twofa.setup') }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <choose-method @chosen="handleChosenMethod" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <backup-codes
              ref="backup"
              @backupCodeSavedByUser="isStep2ContinueDisabled = false"
            />
            <div class="d-flex pr-6 justify-end">
              <v-btn
                color="primary"
                outlined
                @click="$router.push({ name: 'my-account' })"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                class="ml-5"
                :disabled="isStep2ContinueDisabled"
                @click="steps = 3"
              >
                {{ $t('twofa.continue') }}
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <app-setup v-if="method === 'app'" ref="app" @set-otp="setOtp" />
            <sms-setup v-if="method === 'sms'" @set-otp="setOtp" />

            <div class="d-flex pr-6 justify-end">
              <v-btn
                color="primary"
                outlined
                @click="$router.push({ name: 'my-account' })"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                class="ml-5"
                :disabled="isEnableTwofaButtonDisabled"
                @click="enableTwofa"
              >
                {{ $t('twofa.enable') }}
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { mapGetters } from 'vuex';
import ChooseMethod from './ChooseMethod.vue';
import BackupCodes from './BackupCodes.vue';
import AppSetup from './Setup/AppSetup.vue';
import SmsSetup from './Setup/SmsSetup.vue';
import openSnackbar from '../../helpers';

export default {
  components: {
    ChooseMethod,
    BackupCodes,
    AppSetup,
    SmsSetup,
  },
  beforeRouteEnter(to, from, next) {
    // NOTE: This is commented out because of ms office api beforeRouteEnter was executing twice
    // and thus twofa page was never opened after entering the password

    // if (from.name === 'verify-password') {
    next((vm) => {
      vm.setTheme();
    });
    // } else {
    //   next({
    //     name: 'verify-password',
    //     query: { redirect: 'twofa' },
    //   });
    // }
  },
  data: () => ({
    steps: 1,
    method: null,
    otp: '',
    isStep2ContinueDisabled: true,
    isEnableTwofaButtonDisabled: true,
  }),
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    steps(newVal) {
      switch (newVal) {
        case 2:
          this.$refs.backup.getBackupCodes();
          break;
        case 3:
          if (this.method === 'app') {
            this.$refs.app.getManualAndQrCodes();
          }
          break;
        default:
          break;
      }
    },
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.deepPurple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },
    handleChosenMethod(method) {
      this.method = method;
      this.steps = 2;
    },
    setOtp(otp) {
      this.otp = otp;
      if (otp.length === 6) {
        this.isEnableTwofaButtonDisabled = false;
      }
    },
    enableTwofa() {
      axios
        .post(`/twofa/${this.method}`, {
          otp: this.otp,
        })
        .then(() => {
          openSnackbar(this.$t('twofa.successMessage'), 'success', -1);
          this.$store.dispatch('getUserInfo');
          this.$router.push({ name: 'my-account' });
        })
        .catch((error) => {
          switch (error.response.data.message) {
            case 'invalid-otp':
            case 'The given data was invalid.':
              openSnackbar(this.$t('otp.incorrectOtp'), 'error');
              return;
            default:
              this.$router.push({ name: 'my-account' });
              openSnackbar(this.$t('common.somethingWentWrong'), 'error');
              throw error;
          }
        });
    },
    disableTwofa() {
      axios
        .delete('/twofa')
        .then(() => {
          this.$store.dispatch('getUserInfo');
          this.$router.push({ name: 'my-account' });
        })
        .catch((error) => {
          console.log(error);
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },
  },
};
</script>
