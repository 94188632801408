import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('TheSwitchOrganizationModal.switchOrganization'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"text":"","icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VList,_vm._l((_vm.user.tenants),function(tenant){return _c(VListItem,{key:tenant.id,attrs:{"href":_vm.getUrl(tenant),"disabled":_vm.currentTenant.id === tenant.id}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(tenant.name)+" "+_vm._s(_vm.currentTenant.id === tenant.id ? `(${_vm.$t('TheSwitchOrganizationModal.current')})` : '')+" ")])],1),(_vm.currentTenant.id !== tenant.id)?_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-arrow-right-bold")])],1):_vm._e()],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }