<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>
                <v-btn
                  v-if="subdomain != null && emailEditingDisabled"
                  icon
                  @click="goToEmailEnterField"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn
                  v-if="subdomain === null && backOptionEnabled"
                  icon
                  @click="goToAllOptions"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                {{ $t('common.appName') }} -
                {{ $t('login.logIn') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-progress-linear
              v-if="isLoading"
              :indeterminate="true"
              color="pink"
              class="mt-0 mb-0"
              height="6"
            />
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent
              >
                <v-text-field
                  id="loginEmail"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('login.email')"
                  prepend-icon="mdi-account"
                  type="email"
                  required
                  autofocus
                  :disabled="emailEditingDisabled"
                  name="identifier"
                  autocomplete="username"
                  @keyup.enter="doActionOnEnterEmail"
                />

                <template v-if="shouldSignInUsingPassword">
                  <v-text-field
                    id="loginPassword"
                    v-model="password"
                    :rules="[rules.required]"
                    :label="$t('login.password')"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                    @keyup.enter="submit"
                  />
                  <v-alert
                    :value="!!errorMessage"
                    outlined
                    transition="scale-transition"
                    type="error"
                    >{{ errorMessage }}</v-alert
                  >
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="checkbox"
                      :label="$t('login.keepMeLoggedIn')"
                      name="remember"
                      dense
                    />

                    <a
                      class="caption"
                      style="text-decoration: none; margin-left: auto"
                      @click="
                        $router.push({ name: 'forgot', query: $route.query })
                      "
                    >
                      {{ $t('login.forgotPassword') }}
                    </a>
                  </div>
                  <v-card-actions>
                    <v-btn
                      id="loginButton"
                      block
                      :disabled="isLoginButtonDisabled"
                      color="primary"
                      @click="submit"
                    >
                      {{ $t('login.logIn') }}
                    </v-btn>
                  </v-card-actions>
                </template>

                <template v-else>
                  <v-alert
                    :value="!!errorMessage"
                    outlined
                    transition="scale-transition"
                    type="error"
                  >
                    {{ errorMessage }}
                  </v-alert>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      :disabled="isNextButtonDisabled"
                      @click="
                        subdomain !== null
                          ? checkIsSSOConfiguredForEmailForCurrentTenant()
                          : checkIsSSOConfiguredForEmailForAnyTenant()
                      "
                    >
                      {{ $t('login.next') }}
                    </v-btn>
                  </v-card-actions>
                </template>
                <template v-if="subdomain === null && showSSOOption">
                  <div class="d-flex align-center justify-space-around ma-2">
                    <v-divider></v-divider>
                    <span class="mx-2 grey--text text--darken-1">
                      {{ $t('login.or') }}
                    </span>
                    <v-divider></v-divider>
                  </div>
                  <v-card-actions>
                    <v-btn
                      block
                      outlined
                      color="primary"
                      @click="enterEmailForSSOLogin"
                    >
                      {{ $t('login.loginWithSSO') }}
                    </v-btn>
                  </v-card-actions>
                </template>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <maintenance-message />
    </v-container>
    <login-footer />
    <RedirectionModal ref="redirectionModal" />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import colors from 'vuetify/es5/util/colors';
import LoginFooter from '../components/LoginFooter.vue';
import RedirectionModal from '../components/RedirectionModal.vue';
import MaintenanceMessage from '../components/MaintenanceMessage.vue';

import throttleErrorMessage from '../mixins/throttleErrorMessage';
import accountsUrl from '../accountsUrl';
import {
  checkIfThisUserHasSSOForCurrentTenant,
  checkIfThisUserHasSSOForAnyTenant,
} from '@/api/sso.api';
import outlookLogin from '../mixins/outlookLogin';

const zxcvbn = require('zxcvbn');

export default {
  components: {
    LoginFooter,
    MaintenanceMessage,
    RedirectionModal,
  },

  mixins: [throttleErrorMessage, outlookLogin],

  beforeRouteEnter(to, from, next) {
    if (from.name === 'backup-login') {
      next((vm) => {
        vm.setIncorrectBackupCodeMessage();
        vm.setTheme();
      });
    }
    next((vm) => {
      vm.setTheme();
    });
  },

  data: () => ({
    valid: true,
    email: '',
    password: null,
    isLoading: false,
    isLoginButtonDisabled: false,
    checkbox: false,
    shouldSignInUsingPassword: true,
    emailEditingDisabled: false,
    backOptionEnabled: false,
    showSSOOption: true,
    isNextButtonDisabled: false,
    isUserAuthenticated: false,
  }),

  computed: {
    ...mapGetters(['ssoSubdomain', 'statusSSOActiveUsers']),

    rules() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return {
        required: (value) => !!value || this.$t('common.required'),
        email: (value) =>
          emailRegex.test(value) || this.$t('login.emailMustBeValid'),
      };
    },

    subdomain() {
      return App.helpers.getSubdomain();
    },
  },

  watch: {
    valid(isValid) {
      if (isValid) {
        this.isLoginButtonDisabled = false;
        this.isNextButtonDisabled = false;
      } else {
        this.isLoginButtonDisabled = true;
        this.isNextButtonDisabled = true;
      }
    },
  },

  created() {
    this.handleOutlookInitialize();
  },

  mounted() {
    const { userFetchingPromise } = this.$store.getters;
    if (userFetchingPromise !== null) {
      this.$store.getters.userFetchingPromise
        .then((response) => {
          this.$store.dispatch('setUserFetchingPromiseToNull');
          this.$store.dispatch('storeUser', response.data);

          if (this.isOutlookAddInLogin) {
            this.handleOutlookLogin();
            return;
          }
          this.$refs.redirectionModal.open();
        })
        .catch(() => {
          if (this.statusSSOActiveUsers === 'all') {
            axios.get('saml2/check').then((response) => {
              if (response.data) {
                this.redirectToSamlLogin(this.ssoSubdomain);
              }
            });
          } else if (this.statusSSOActiveUsers === 'some') {
            this.shouldSignInUsingPassword = false;
          }
        });
    }
    this.loadBowNowTracking();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.blue.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },

    setIncorrectBackupCodeMessage() {
      this.errorMessage = this.$t('backup.incorrectBackupCode'); // TODO: Fix message when back pressed from recovery page
    },

    submit() {
      this.errorMessage = null;
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        axios
          .post('login', {
            email: this.email,
            password: this.password,
            remember: this.checkbox,
          })
          .then((response) => {
            const { data } = response;

            if (data.message === 'redirect-to-sso') {
              this.redirectToSamlLogin(data.initial_subdomain);
            }

            this.$store.dispatch('storeUser', data.user);

            if (data.message === 'enter-otp') {
              this.$router.push({
                name: 'enter-otp',
                query: this.$route.query,
              });
              return;
            }

            if (this.isOutlookAddInLogin) {
              this.handleOutlookLogin();
              return;
            }

            if (this.isPasswordResetRequired()) {
              this.$store.dispatch('setIsWeakPasswordChangeRequired');
              this.$store.dispatch('openChangePasswordModal');
            } else {
              this.$refs.redirectionModal.open();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            try {
              this.displayErrorMessage(error);
            } catch (err) {
              console.log(err);
              throw err;
            }
          });
      }
    },

    isPasswordResetRequired() {
      const response = zxcvbn(this.password);
      return response.score < 2 || this.password.length < 8;
    },

    displayErrorMessage(error) {
      const errorResponse = error.response;
      if (errorResponse.status === 429) {
        this.disableLoginButton();
        this.disableNextButton();
        this.displayRetryAfterXSecondsError(
          errorResponse.data.duration,
          'login',
        );
        return;
      }
      switch (errorResponse.data.error) {
        case 'invalid-credentials':
          this.errorMessage = this.$t('login.doNotMatch');
          break;
        case 'deactivated':
          this.errorMessage = this.$t('login.userDeactivated');
          break;
        case 'restricted-ip':
          this.errorMessage = this.$t('login.ipRestricted');
          break;
        default:
          throw error;
      }
    },

    disableLoginButton() {
      this.isLoginButtonDisabled = true;
    },

    disableNextButton() {
      this.isNextButtonDisabled = true;
    },

    enableLoginButton() {
      this.isLoginButtonDisabled = false;
    },

    enableNextButton() {
      this.isNextButtonDisabled = false;
    },

    loadBowNowTracking() {
      const bownowTs = document.createElement('script');
      bownowTs.charset = 'utf-8';
      bownowTs.src =
        'https://contents.bownow.jp/js/UTC_406e8dbcc7d1b41e3edd/trace.js';
      document.getElementsByTagName('head')[0].appendChild(bownowTs);
    },

    async checkIsSSOConfiguredForEmailForCurrentTenant() {
      this.errorMessage = null;
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          const response = await checkIfThisUserHasSSOForCurrentTenant(
            this.email,
          );

          if (response.data.is_idp_configured) {
            this.redirectToSamlLogin(response.data.initial_subdomain);
          } else {
            this.emailEditingDisabled = true;
            this.shouldSignInUsingPassword = true;
            this.isLoading = false;
          }
        } catch (error) {
          this.isLoading = false;
          try {
            this.displayErrorMessage(error);
          } catch (err) {
            console.log(err);
            throw err;
          }
        }
      }
    },

    async checkIsSSOConfiguredForEmailForAnyTenant() {
      this.errorMessage = null;
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          const response = await checkIfThisUserHasSSOForAnyTenant(this.email);

          if (response.data.is_idp_configured) {
            this.redirectToSamlLogin(response.data.initial_subdomain);
          } else {
            this.isLoading = false;
            this.errorMessage = this.$t('login.ssoNotConfiguredForThisEmail');
          }
        } catch (error) {
          this.isLoading = false;
          try {
            this.displayErrorMessage(error);
          } catch (err) {
            console.log(err);
            throw err;
          }
        }
      }
    },

    goToEmailEnterField() {
      this.shouldSignInUsingPassword = false;
      this.emailEditingDisabled = false;
      this.errorMessage = null;
    },

    enterEmailForSSOLogin() {
      this.shouldSignInUsingPassword = false;
      this.backOptionEnabled = true;
      this.errorMessage = null;
      this.showSSOOption = false;
    },

    goToAllOptions() {
      this.shouldSignInUsingPassword = true;
      this.showSSOOption = true;
      this.backOptionEnabled = false;
      this.errorMessage = null;
    },

    doActionOnEnterEmail() {
      if (this.shouldSignInUsingPassword) {
        this.submit();
      } else if (this.subdomain === null) {
        this.checkIsSSOConfiguredForEmailForAnyTenant();
      } else {
        this.checkIsSSOConfiguredForEmailForCurrentTenant();
      }
    },

    redirectToSamlLogin(subdomain) {
      const baseUrl = accountsUrl(subdomain);
      window.location.href = `${baseUrl}saml2/all/login?RelayState=${window.location.href}`;
    },
  },
};
</script>
<style scoped>
.v-divider {
  display: inline-block;
  vertical-align: middle;
  width: calc(50% - 20px);
}
</style>
