<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('importLogs.importLogs') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :disable-sort="true"
          :disable-pagination="true"
          :hide-default-footer="true"
          :items="logs"
          :loading="loading"
        >
          <template #body="{ items }">
            <tbody v-if="items.length === 0">
              <td :colspan="headers.length" class="text-center">
                {{ $t('importLogs.importLogsWillBeShownHere') }}
              </td>
            </tbody>
            <tbody>
              <tr v-for="log in items" :key="log.id">
                <td>{{ log.filename }}</td>
                <td>
                  {{ log.start_time }}
                </td>
                <td>
                  {{ log.user.name }}
                </td>
                <td>
                  {{ log.status }}
                </td>
                <td>{{ log.number_of_users_updated }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { getUpdateByImportLogs } from '../../../api/admin.api';

export default {
  data() {
    return {
      logs: [],
      loading: false,
      panel: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('importLogs.filename'),
        },
        {
          text: this.$t('importLogs.importedAt'),
        },
        {
          text: this.$t('importLogs.importedBy'),
        },
        {
          text: this.$t('importLogs.status'),
        },
        {
          text: this.$t('importLogs.numberOfUsersUpdated'),
        },
      ];
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    open() {
      this.panel = 0;
      this.fetch();
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'openEditByImportLogs',
        eventValue: 1,
      });
    },
    close() {
      this.panel = null;
    },
    async fetch() {
      this.loading = true;
      const response = await getUpdateByImportLogs();
      this.loading = false;
      this.logs = response.data;
    },
  },
};
</script>
