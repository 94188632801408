<template>
  <div>
    <v-menu offset-y min-width="155" left>
      <template #activator="{ on: menu }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn icon color="white" v-on="{ ...tooltip, ...menu }">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('toolbar.support') }}</span>
        </v-tooltip>
      </template>
      <v-list class="pa-0">
        <v-list-item ripple @click="openManualInBrowser">
          <v-list-item-icon>
            <v-icon>mdi-text-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('toolbar.manual') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item ripple @click="openFaqInBrowser">
          <v-list-item-icon>
            <v-icon>mdi-help-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('toolbar.faq') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  methods: {
    openManualInBrowser() {
      const manualUrl =
        'https://s3-ap-northeast-1.amazonaws.com/nexta-meishi/manual.pdf';
      window.open(manualUrl);
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'userClickedOpenManual',
        eventValue: 1,
      });
    },

    openFaqInBrowser() {
      const faqPageUrl = 'https://tnexta.com/faq';
      window.open(faqPageUrl);
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'userClickedOpenFAQ',
        eventValue: 1,
      });
    },
  },
};
</script>
