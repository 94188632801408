const state = {
  departments: [],
  originalDepartmentsWithUsers: [],
  isFetchingDepartments: false,
};

const getters = {
  departments: (state) => state.departments,
  isFetchingDepartments: (state) => state.isFetchingDepartments,
  originalDepartmentsWithUsers: (state) => state.originalDepartmentsWithUsers,
  usersWithDepartments() {
    const users = [];
    state.originalDepartmentsWithUsers.forEach((department) => {
      department.users.forEach((user) => {
        const userDepartment = { ...department };
        delete userDepartment.users;
        user.department = userDepartment;
        users.push(user);
      });
    });
    return users;
  },
};

const mutations = {
  updateDepartments: (state, payload) => {
    state.departments = payload;
  },
  updateOriginalDepartmentsWithUsers: (state, payload) => {
    state.originalDepartmentsWithUsers = payload;
  },
  updateIsFetchingDepartments: (state, payload) => {
    state.isFetchingDepartments = payload;
  },
};

const actions = {
  updateDepartmentsFromServer: ({ commit }) => {
    commit('updateIsFetchingDepartments', true);
    const promiseUpdateDepartmentsFromServer = axios.get('/admin/departments');
    promiseUpdateDepartmentsFromServer.then((response) => {
      commit('updateDepartments', response.data);
      commit('updateIsFetchingDepartments', false);
    });
  },
  updateOriginalDepartmentsWithUsers: ({ commit }) => {
    axios.get('/api/departments-with-users').then((response) => {
      commit('updateOriginalDepartmentsWithUsers', response.data);
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
