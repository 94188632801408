<template>
  <div class="pt-2">
    <span
      v-html="
        $t('PremiumFeatureExplanation.explanation', {
          color: accent,
        })
      "
    />
  </div>
</template>

<script>
export default {
  computed: {
    accent() {
      return this.$vuetify.theme.themes.light.accent;
    },
  },
};
</script>
