import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{attrs:{"lines":"three"}},[_c(VListItemAction,[_c('DeviceModalListItemDeviceIcon',{attrs:{"agent":_vm.trustedDevice.agent}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.getDevice(_vm.trustedDevice.agent))+"・"+_vm._s(_vm.getApp(_vm.trustedDevice.agent))+" ")]),_c(VListItemSubtitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getLocation(_vm.trustedDevice.location))+"・"+_vm._s(_vm.getTimeFromNow(_vm.trustedDevice.created_at))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('TrustedDevicesModalListItem.createdAt', { timestamp: _vm.formatTimestamp(_vm.trustedDevice.created_at), })))])])],1)],1),_c(VListItemAction,[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-0",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":_vm.confirmDelete}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('TrustedDevicesModalListItem.deleteTrustedDevice'))+" ")])],1)],1)],1)],1),_c('Confirm',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }