<template>
  <VMain>
    <VContainer :style="{ 'max-width': '800px' }">
      <VCard>
        <VCardTitle>
          {{ $t('TenantSettingsPage.title') }}
        </VCardTitle>

        <VCardText>
          <div>
            {{ $t('TenantSettingsPage.youCanSetCustomSubdomain') }}

            <br />
            {{ $t('TenantSettingsPage.initialSubdomainWillStillBeAccessible') }}
          </div>

          <VSimpleTable class="mt-4" width="300px">
            <template #default>
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('TenantSettingsPage.subdomain') }}</th>
                  <th>{{ $t('TenantSettingsPage.nextaUrl') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('TenantSettingsPage.initialSubdomain') }}</td>
                  <td>{{ initialSubdomain }}</td>
                  <td>
                    {{ initialSubdomainUrl }}
                    <TooltipButton
                      icon="mdi-content-copy"
                      :tooltip="$t('TenantSettingsPage.copy')"
                      @click="copy(initialSubdomainUrl)"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>{{ $t('TenantSettingsPage.customSubdomain') }}</td>
                  <template v-if="customSubdomainUrl">
                    <td>{{ customSubdomain }}</td>
                    <td>
                      {{ customSubdomainUrl }}
                      <TooltipButton
                        icon="mdi-content-copy"
                        :tooltip="$t('TenantSettingsPage.copy')"
                        @click="copy(customSubdomainUrl)"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td>{{ $t('TenantSettingsPage.na') }}</td>
                    <td>{{ $t('TenantSettingsPage.na') }}</td>
                  </template>
                  <td>
                    <TooltipButton
                      icon="mdi-pencil"
                      :tooltip="$t('TenantSettingsPage.editCustomSubdomain')"
                      color="accent"
                      @click="openSubdomainEditDialog"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </VSimpleTable>
        </VCardText>
      </VCard>
    </VContainer>

    <SubdomainEditDialog
      ref="subdomainEditDialog"
      @updated:subdomain="refreshPage"
    />
  </VMain>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { mapGetters } from 'vuex';
import TooltipButton from '../../../components/TooltipButton.vue';
import SubdomainEditDialog from './SubdomainEditDialog.vue';
import openSnackbar from '../../../helpers';

export default {
  components: {
    TooltipButton,
    SubdomainEditDialog,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  computed: {
    ...mapGetters(['currentTenant']),

    initialSubdomain() {
      return this.currentTenant.initial_subdomain;
    },

    customSubdomain() {
      return this.currentTenant.subdomain;
    },

    initialSubdomainUrl() {
      return this.getUrlAsPerEnv(this.initialSubdomain);
    },

    customSubdomainUrl() {
      if (this.customSubdomain === null) {
        return null;
      }
      return this.getUrlAsPerEnv(this.customSubdomain);
    },
  },

  created() {
    if (this.$route.query.status === 'changed') {
      openSnackbar(
        this.$t('TenantSettingsPage.subdomainResetSuccess'),
        'success',
      );
      this.removeStatusQueryParam();
    }
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    openSubdomainEditDialog() {
      this.$refs.subdomainEditDialog.open(this.customSubdomain);
    },

    removeStatusQueryParam() {
      this.$router.replace({ name: 'tenant-settings' });
    },

    async copy(text) {
      await this.updateClipboardPromise(text);
      openSnackbar(
        this.$t('TenantSettingsPage.copiedUrlToTheClipboard'),
        'success',
      );
    },

    async updateClipboardPromise(text) {
      // Refer: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
      const result = await navigator.permissions.query({
        name: 'clipboard-write',
      });
      if (result.state === 'granted' || result.state === 'prompt') {
        await navigator.clipboard.writeText(text);
      }
    },

    refreshPage(newCustomSubdomainUrl) {
      let redirectTo = '';
      if (newCustomSubdomainUrl.length === 0) {
        redirectTo = this.initialSubdomainUrl;
      } else {
        redirectTo = this.getUrlAsPerEnv(newCustomSubdomainUrl);
      }
      window.location = `${redirectTo + this.$route.path}?status=changed`;
    },

    getUrlAsPerEnv(subdomain) {
      switch (process.env.VUE_APP_BUILD_FOR) {
        case 'staging':
          return `https://${subdomain}.tnexta.com/staging-accounts`;
        default:
          return `https://${subdomain}.tnexta.com/accounts`;
      }
    },
  },
};
</script>
