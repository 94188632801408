<template>
  <VMain>
    <VContainer>
      <VRow no-gutters justify="center">
        <VCard width="600px">
          <VCardTitle>
            {{ $t('ContactDetailsPage.title') }}
          </VCardTitle>

          <VProgressLinear v-if="isLoading" indeterminate />

          <template v-else>
            <VCardText>
              <VRow no-gutters class="mb-4" align="center">
                <span class="text-body-1 font-weight-black">
                  {{ $t('ContactDetailsPage.primaryContact') }}
                </span>

                <VSpacer />

                <VBtn
                  :loading="isPrimaryContactLoading"
                  outlined
                  color="accent"
                  @click="openPrimaryContactEditDialog"
                >
                  {{ $t('common.edit') }}
                </VBtn>
              </VRow>

              <VProgressLinear v-if="isPrimaryContactLoading" indeterminate />

              <VSimpleTable v-if="primaryContact" class="simple-table">
                <template #default>
                  <tbody>
                    <tr v-for="(row, index) in primaryContactRows" :key="index">
                      <td width="30%">
                        <span>
                          {{ row.title }}
                        </span>
                      </td>

                      <td width="70%">
                        <span style="word-break: break-all">
                          {{ row.value }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </VSimpleTable>
            </VCardText>

            <VCardText class="pb-8">
              <VRow no-gutters class="mb-4" align="center">
                <span class="text-body-1 font-weight-black">
                  {{ $t('ContactDetailsPage.billingContact') }}
                </span>

                <VSpacer />

                <template v-if="billingContact">
                  <VBtn
                    :loading="isBillingContactLoading"
                    outlined
                    color="accent"
                    @click="openBillingContactEditDialog"
                  >
                    {{ $t('common.edit') }}
                  </VBtn>

                  <VMenu offset-y>
                    <template #activator="{ on, attrs }">
                      <VBtn
                        icon
                        color="accent"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <VIcon>mdi-dots-vertical</VIcon>
                      </VBtn>
                    </template>
                    <VList>
                      <VListItem @click="confirmAndHandleDelete">
                        <VListItemIcon>
                          <VIcon>mdi-delete</VIcon>
                        </VListItemIcon>
                        <VListItemTitle>
                          {{ $t('common.delete') }}
                        </VListItemTitle>
                      </VListItem>
                    </VList>
                  </VMenu>
                </template>

                <VBtn
                  v-else
                  :loading="isBillingContactLoading"
                  outlined
                  color="accent"
                  @click="openBillingContactAddDialog"
                >
                  {{ $t('ContactDetailsPage.register') }}
                </VBtn>
              </VRow>

              <VProgressLinear v-if="isBillingContactLoading" indeterminate />

              <VSimpleTable v-if="billingContact" class="simple-table">
                <template #default>
                  <tbody>
                    <tr v-for="(row, index) in billingContactRows" :key="index">
                      <td width="30%">
                        <span>
                          {{ row.title }}
                        </span>
                      </td>

                      <td width="70%">
                        <span>
                          {{ row.value }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </VSimpleTable>

              <span v-else>
                {{ $t('ContactDetailsPage.billingContactNotPresentMessage') }}
              </span>
            </VCardText>
          </template>
        </VCard>
      </VRow>

      <TenantContactAddEditDialog
        ref="tenantContactAddEditDialog"
        @edit:primary-contact="handleUpdatePrimaryContact"
        @add:billing-contact="handleStoreBillingContact"
        @edit:billing-contact="handleUpdateBillingContact"
      />

      <ConfirmDeleteDialog ref="confirm" />
    </VContainer>
  </VMain>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import TenantContactAddEditDialog from '../../components/TenantContactAddEditDialog.vue';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog.vue';
import openSnackbar from '@/helpers';
import {
  getTenantContacts,
  storeBillingContact,
  updateTenantContact,
  deleteTenantContact,
} from '../../api/admin.api';

export default {
  components: {
    TenantContactAddEditDialog,
    ConfirmDeleteDialog,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data() {
    return {
      primaryContact: null,
      billingContact: null,

      isLoading: false,
      isPrimaryContactLoading: false,
      isBillingContactLoading: false,
    };
  },

  computed: {
    primaryContactRows() {
      return [
        {
          title: this.$t('ContactDetailsPage.companyName'),
          value: this.primaryContact?.company_name,
        },
        {
          title: this.$t('ContactDetailsPage.name'),
          value: this.primaryContact?.name,
        },
        {
          title: this.$t('ContactDetailsPage.department'),
          value: this.primaryContact?.department,
        },
        {
          title: this.$t('ContactDetailsPage.jobTitle'),
          value: this.primaryContact?.position,
        },
        {
          title: this.$t('ContactDetailsPage.email'),
          value: this.primaryContact?.email,
        },
        {
          title: this.$t('ContactDetailsPage.phoneNumber'),
          value: this.primaryContact?.contact_number,
        },
        {
          title: this.$t('ContactDetailsPage.address'),
          value: this.getFormattedAddress(
            this.primaryContact?.postal_code,
            this.primaryContact?.address,
          ),
        },
      ];
    },

    billingContactRows() {
      return [
        {
          title: this.$t('ContactDetailsPage.companyName'),
          value: this.billingContact?.company_name,
        },
        {
          title: this.$t('ContactDetailsPage.name'),
          value: this.billingContact?.name,
        },
        {
          title: this.$t('ContactDetailsPage.department'),
          value: this.billingContact?.department,
        },
        {
          title: this.$t('ContactDetailsPage.jobTitle'),
          value: this.billingContact?.position,
        },
        {
          title: this.$t('ContactDetailsPage.email'),
          value: this.billingContact?.email,
        },
        {
          title: this.$t('ContactDetailsPage.phoneNumber'),
          value: this.billingContact?.contact_number,
        },
        {
          title: this.$t('ContactDetailsPage.address'),
          value: this.getFormattedAddress(
            this.billingContact?.postal_code,
            this.billingContact?.address,
          ),
        },
      ];
    },
  },

  created() {
    this.fetchAndSetTenantContacts();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    async fetchAndSetTenantContacts() {
      this.isLoading = true;

      try {
        const { data } = await getTenantContacts();

        this.primaryContact = data.primary_contact;
        this.billingContact = data.billing_contact;
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
      }

      this.isLoading = false;
    },

    getFormattedAddress(postalCode, address) {
      const formattedPostalCode = postalCode || '';
      const formattedAddress = address || '';

      return `${formattedPostalCode}${formattedAddress}`;
    },

    openPrimaryContactEditDialog() {
      this.$refs.tenantContactAddEditDialog.openEditForPrimary(
        this.primaryContact,
      );
    },

    openBillingContactAddDialog() {
      this.$refs.tenantContactAddEditDialog.openAddForBilling();
    },

    openBillingContactEditDialog() {
      this.$refs.tenantContactAddEditDialog.openEditForBilling(
        this.billingContact,
      );
    },

    async handleUpdatePrimaryContact(details) {
      this.isPrimaryContactLoading = true;

      try {
        const { data } = await updateTenantContact(details.id, {
          companyName: details.companyName,
          name: details.name,
          department: details.department,
          position: details.position,
          email: details.email,
          contactNumber: details.contactNumber,
          postalCode: details.postalCode,
          address: details.address,
        });
        this.primaryContact = { ...data };

        openSnackbar(
          this.$t('ContactDetailsPage.primaryContactUpdatedSuccessfully'),
          'success',
        );
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
      }

      this.isPrimaryContactLoading = false;
    },

    async handleStoreBillingContact(details) {
      this.isBillingContactLoading = true;

      try {
        const { data } = await storeBillingContact({
          companyName: details.companyName,
          name: details.name,
          department: details.department,
          position: details.position,
          email: details.email,
          contactNumber: details.contactNumber,
          postalCode: details.postalCode,
          address: details.address,
        });
        this.billingContact = { ...data };

        openSnackbar(
          this.$t('ContactDetailsPage.billingContactCreatedSuccessfully'),
          'success',
        );
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
      }

      this.isBillingContactLoading = false;
    },

    async handleUpdateBillingContact(details) {
      this.isBillingContactLoading = true;

      try {
        const { data } = await updateTenantContact(details.id, {
          companyName: details.companyName,
          name: details.name,
          department: details.department,
          position: details.position,
          email: details.email,
          contactNumber: details.contactNumber,
          postalCode: details.postalCode,
          address: details.address,
        });
        this.billingContact = { ...data };

        openSnackbar(
          this.$t('ContactDetailsPage.billingContactUpdatedSuccessfully'),
          'success',
        );
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
      }

      this.isBillingContactLoading = false;
    },

    async confirmAndHandleDelete() {
      const confirm = await this.openConfirmDeleteDialog();

      if (confirm) {
        this.handleDeleteBillingContact();
      }
    },

    openConfirmDeleteDialog() {
      return this.$refs.confirm.open(
        this.$t('ContactDetailsPage.deleteTitle'),
        this.$t('ContactDetailsPage.deleteMessage'),
        { width: '400' },
      );
    },

    async handleDeleteBillingContact() {
      this.isBillingContactLoading = true;

      try {
        await deleteTenantContact(this.billingContact.id);
        this.fetchAndSetTenantContacts();

        openSnackbar(
          this.$t('ContactDetailsPage.billingContactDeletedSuccessfully'),
          'success',
        );
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
      }

      this.isBillingContactLoading = false;
    },
  },
};
</script>
