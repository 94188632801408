<template>
  <v-main>
    <v-container :style="{ 'max-width': '900px' }">
      <v-card>
        <v-card-title>
          {{ $t('GetMoreServicesPage.title') }}
        </v-card-title>

        <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>

        <v-card-text v-else class="pb-0">
          <div
            class="pb-8"
            v-html="
              $t('GetMoreServicesPage.description', {
                url: 'https://tnexta.com/introduction/plan',
                color: accent,
              })
            "
          />
          <v-list>
            <GetMoreServicesPageCheckboxWithInput
              v-if="contractDetails.meishi_users_limit !== null"
              :message="$t('GetMoreServicesPage.addMeishiUsers')"
              :suffix="$t('GetMoreServicesPage.users')"
              :subscription-status="
                $t('GetMoreServicesPage.currentlySubscribedToXUsers', {
                  count: contractDetails.meishi_users_limit,
                })
              "
              @updated:is-enabled="isAddMeishiUsersEnabled = $event"
              @updated:count="addMeishiUsersCount = $event"
            />

            <GetMoreServicesPageCheckboxWithInput
              v-if="contractDetails.scheduler_users_limit !== null"
              :message="$t('GetMoreServicesPage.addSchedulerUsers')"
              :suffix="$t('GetMoreServicesPage.users')"
              :subscription-status="
                $t('GetMoreServicesPage.currentlySubscribedToXUsers', {
                  count: contractDetails.scheduler_users_limit,
                })
              "
              @updated:is-enabled="isAddSchedulerUsersEnabled = $event"
              @updated:count="addSchedulerUsersCount = $event"
            />

            <GetMoreServicesPageCheckboxWithInput
              v-if="contractDetails.feature_negotiations_users_limit !== null"
              :message="$t('GetMoreServicesPage.addMeishiBnUsers')"
              :suffix="$t('GetMoreServicesPage.users')"
              :subscription-status="
                $t('GetMoreServicesPage.currentlySubscribedToXUsers', {
                  count: contractDetails.feature_negotiations_users_limit,
                })
              "
              @updated:is-enabled="isAddMeishiBnUsersEnabled = $event"
              @updated:count="addMeishiBnUsersCount = $event"
            />

            <GetMoreServicesPageCheckboxWithDropdown
              v-if="isBulkEmailDisplayed"
              :message="
                $t('GetMoreServicesPage.subscribeToHigherBulkEmailPlan')
              "
              :items="getFilteredBulkEmailLimits"
              :subscription-status="
                $t('GetMoreServicesPage.currentlySubscribedToXBulkMails', {
                  count: contractDetails.feature_bulk_email_monthly_limit,
                })
              "
              @updated:is-enabled="isIncreasedBulkEmailLimitEnabled = $event"
              @updated:count="onIncreasedBulkLimitUpdate"
            />

            <v-row
              v-if="!contractDetails.is_feature_kintone_subscribed"
              align="center"
            >
              <v-col cols="7">
                <v-checkbox
                  v-model="isSubscribeToKintone"
                  :label="$t('GetMoreServicesPage.subscribeToKintone')"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
              </v-col>
            </v-row>

            <v-row
              v-if="!contractDetails.is_feature_salesforce_subscribed"
              align="center"
            >
              <v-col cols="7">
                <v-checkbox
                  v-model="isSubscribeToSalesforce"
                  :label="$t('GetMoreServicesPage.subscribeToSalesforce')"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
              </v-col>
            </v-row>

            <v-row
              v-if="!contractDetails.is_feature_relation_subscribed"
              align="center"
            >
              <v-col cols="7">
                <v-checkbox
                  v-model="isSubscribeToRelation"
                  :label="$t('GetMoreServicesPage.subscribeToRelation')"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="!contractDetails.is_feature_geniee_subscribed"
              align="center"
            >
              <v-col cols="7">
                <v-checkbox
                  v-model="isSubscribeToGeniee"
                  :label="$t('GetMoreServicesPage.subscribeToGeniee')"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
              </v-col>
            </v-row>

            <GetMoreServicesPageCheckboxWithInput
              v-if="contractDetails.feature_sso_users_limit !== null"
              :message="$t('GetMoreServicesPage.addSsoUsers')"
              :suffix="$t('GetMoreServicesPage.users')"
              :subscription-status="
                $t('GetMoreServicesPage.currentlySubscribedToXUsers', {
                  count: contractDetails.feature_sso_users_limit,
                })
              "
              @updated:is-enabled="isAddSsoUsersEnabled = $event"
              @updated:count="addSsoUsersCount = $event"
            />

            <v-row
              v-if="!contractDetails.is_feature_ip_restriction_subscribed"
              align="center"
            >
              <v-col cols="7">
                <v-checkbox
                  v-model="isSubscribeToIpRestriction"
                  :label="$t('GetMoreServicesPage.subscribeToIpRestriction')"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
              </v-col>
            </v-row>

            <GetMoreServicesPageCheckboxWithInput
              :message="$t('GetMoreServicesPage.addScannerKeysWithNote')"
              :suffix="$t('GetMoreServicesPage.keys')"
              :subscription-status="
                $t('GetMoreServicesPage.currentlySubscribedToXKeys', {
                  count: contractDetails.feature_scanner_app_keys_limit,
                })
              "
              @updated:is-enabled="isAddScannerKeysEnabled = $event"
              @updated:count="addScannerKeysCount = $event"
            />
          </v-list>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            color="accent"
            :disabled="isSendButtonDisabled"
            @click="openConfirmationDialog"
          >
            {{ $t('GetMoreServicesPage.sendRequest') }}</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>

      <GetMoreServicesPageConfirmationDialog
        ref="confirmationDialog"
        :fields="confirmationFields"
        @submit="submit"
      />
    </v-container>
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';

import dateTimeHelpers from '../../mixins/dateTimeHelpers';
import GetMoreServicesPageCheckboxWithInput from '../../components/GetMoreServicesPageCheckboxWithInput.vue';
import GetMoreServicesPageCheckboxWithDropdown from '../../components/GetMoreServicesPageCheckboxWithDropdown.vue';
import { getContractDetails, submitGetMoreServices } from '../../api/admin.api';
import openSnackbar from '../../helpers';
import {
  isCurrentLimitNotMaximum,
  getHigherLimits,
} from '../../modules/BulkEmailHelpers';
import GetMoreServicesPageConfirmationDialog from '../../components/GetMoreServicesPageConfirmationDialog.vue';

export default {
  components: {
    GetMoreServicesPageCheckboxWithInput,
    GetMoreServicesPageConfirmationDialog,
    GetMoreServicesPageCheckboxWithDropdown,
  },

  mixins: [dateTimeHelpers],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data() {
    return {
      isLoading: true,
      contractDetails: {},
      isAddMeishiUsersEnabled: false,
      addMeishiUsersCount: '0',
      isAddSchedulerUsersEnabled: false,
      addSchedulerUsersCount: '0',
      isAddMeishiBnUsersEnabled: false,
      isIncreasedBulkEmailLimitEnabled: false,
      addMeishiBnUsersCount: '0',
      IncreasedBulkLimit: '0',
      isAddSsoUsersEnabled: false,
      addSsoUsersCount: '0',
      isAddScannerKeysEnabled: false,
      addScannerKeysCount: '0',
      isSubscribeToKintone: false,
      isSubscribeToSalesforce: false,
      isSubscribeToRelation: false,
      isSubscribeToGeniee: false,
      isSubscribeToIpRestriction: false,
      isSubscribeToSSO: false,
    };
  },

  computed: {
    isSendButtonDisabled() {
      const isNoCheckboxEnabled =
        this.isAddMeishiUsersEnabled === false &&
        this.isAddSchedulerUsersEnabled === false &&
        this.isAddMeishiBnUsersEnabled === false &&
        this.isIncreasedBulkEmailLimitEnabled === false &&
        this.isAddSsoUsersEnabled === false &&
        this.isAddScannerKeysEnabled === false &&
        this.isSubscribeToKintone === false &&
        this.isSubscribeToSalesforce === false &&
        this.isSubscribeToRelation === false &&
        this.isSubscribeToGeniee === false &&
        this.isSubscribeToIpRestriction === false &&
        this.isSubscribeToSSO === false;

      if (isNoCheckboxEnabled) {
        return true;
      }

      const isCheckboxEnabledButOnlyWithZeroValues =
        (!this.isAddMeishiUsersEnabled || this.addMeishiUsersCount === '0') &&
        (!this.isAddSchedulerUsersEnabled ||
          this.addSchedulerUsersCount === '0') &&
        (!this.isAddMeishiBnUsersEnabled ||
          this.addMeishiBnUsersCount === '0') &&
        (!this.isAddScannerKeysEnabled || this.addScannerKeysCount === '0') &&
        (!this.isAddSsoUsersEnabled || this.addSsoUsersCount === '0') &&
        (!this.isIncreasedBulkEmailLimitEnabled ||
          this.IncreasedBulkLimit === '0');

      const isAllSubscribeCheckboxDisabled =
        this.isSubscribeToKintone === false &&
        this.isSubscribeToSalesforce === false &&
        this.isSubscribeToRelation === false &&
        this.isSubscribeToGeniee === false &&
        this.isSubscribeToIpRestriction === false &&
        this.isSubscribeToSSO === false;

      return (
        isCheckboxEnabledButOnlyWithZeroValues && isAllSubscribeCheckboxDisabled
      );
    },

    confirmationFields() {
      const fields = [];

      if (this.isAddMeishiUsersEnabled && this.addMeishiUsersCount !== '0') {
        fields.push({
          key: 'addMeishiUsers',
          value: this.addMeishiUsersCount,
        });
      }
      if (
        this.isAddSchedulerUsersEnabled &&
        this.addSchedulerUsersCount !== '0'
      ) {
        fields.push({
          key: 'addSchedulerUsers',
          value: this.addSchedulerUsersCount,
        });
      }
      if (
        this.isAddMeishiBnUsersEnabled &&
        this.addMeishiBnUsersCount !== '0'
      ) {
        fields.push({
          key: 'addMeishiBnUsers',
          value: this.addMeishiBnUsersCount,
        });
      }
      if (
        this.isIncreasedBulkEmailLimitEnabled &&
        this.IncreasedBulkLimit !== '0'
      ) {
        fields.push({
          key: 'subscribeToHigherBulkEmailPlan',
          value: this.IncreasedBulkLimit,
        });
      }
      if (this.isSubscribeToKintone) {
        fields.push({ key: 'subscribeToKintone', value: true });
      }
      if (this.isSubscribeToSalesforce) {
        fields.push({ key: 'subscribeToSalesforce', value: true });
      }
      if (this.isSubscribeToRelation) {
        fields.push({ key: 'subscribeToRelation', value: true });
      }
      if (this.isSubscribeToGeniee) {
        fields.push({ key: 'subscribeToGeniee', value: true });
      }
      if (this.isSubscribeToSSO) {
        fields.push({ key: 'subscribeToSSO', value: true });
      }
      if (this.isAddSsoUsersEnabled && this.addSsoUsersCount !== '0') {
        fields.push({ key: 'addSsoUsers', value: this.addSsoUsersCount });
      }

      if (this.isSubscribeToIpRestriction) {
        fields.push({ key: 'subscribeToIpRestriction', value: true });
      }

      if (this.isAddScannerKeysEnabled && this.addScannerKeysCount !== '0') {
        fields.push({
          key: 'addScannerKeys',
          value: this.addScannerKeysCount,
        });
      }

      return fields;
    },

    accent() {
      return this.$vuetify.theme.themes.light.accent;
    },

    isBulkEmailDisplayed() {
      return isCurrentLimitNotMaximum(
        this.contractDetails.feature_bulk_email_monthly_limit,
      );
    },

    getFilteredBulkEmailLimits() {
      const items = getHigherLimits(
        this.contractDetails.feature_bulk_email_monthly_limit,
      );
      const suffix = this.$t('GetMoreServicesPage.emailsPerMonth');

      return items.map((limit) => ({
        text: `${limit.toLocaleString()} ${suffix}`,
        value: limit,
      }));
    },
  },

  mounted() {
    this.fetchContractDetails();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    async fetchContractDetails() {
      this.isLoading = true;
      try {
        const { data } = await getContractDetails();
        this.contractDetails = data;
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
      } finally {
        this.isLoading = false;
      }
    },

    onIncreasedBulkLimitUpdate(selectedItem) {
      this.IncreasedBulkLimit = selectedItem.value;
    },

    openConfirmationDialog() {
      this.$refs.confirmationDialog.open();
    },

    async submit() {
      try {
        await submitGetMoreServices(this.confirmationFields);
        this.resetData();
        openSnackbar(
          this.$t('GetMoreServicesPage.requestSuccessful'),
          'success',
        );
      } catch (e) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw e;
      }
    },

    resetData() {
      this.isAddMeishiUsersEnabled = false;
      this.addMeishiUsersCount = '0';
      this.isAddSchedulerUsersEnabled = false;
      this.addSchedulerUsersCount = '0';
      this.isAddMeishiBnUsersEnabled = false;
      this.isIncreasedBulkEmailLimitEnabled = false;
      this.addMeishiBnUsersCount = '0';
      this.IncreasedBulkLimit = '0';
      this.isAddSsoUsersEnabled = false;
      this.addSsoUsersCount = '0';
      this.isAddScannerKeysEnabled = false;
      this.addScannerKeysCount = '0';
      this.isSubscribeToKintone = false;
      this.isSubscribeToSalesforce = false;
      this.isSubscribeToRelation = false;
      this.isSubscribeToGeniee = false;
      this.isSubscribeToIpRestriction = false;
      this.isSubscribeToSSO = false;
      this.fetchContractDetails();
    },
  },
};
</script>
