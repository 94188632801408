import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[_c(VCheckbox,{staticClass:"shrink mr-2 mt-0",attrs:{"label":_vm.message,"hide-details":""},on:{"change":function($event){return _vm.$emit('updated:is-enabled', _vm.isEnabled)}},model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"disabled":!_vm.isEnabled,"suffix":_vm.suffix,"hide-details":"","outlined":"","type":"number","min":"0"},on:{"input":function($event){return _vm.$emit('updated:count', _vm.count)}},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.subscriptionStatus)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }