<template>
  <v-main>
    <v-alert v-if="!currentTenant && isTenantManager" type="info">
      {{ $t('TenantManagerWarning.alert') }}

      {{ tenantName }}</v-alert
    >

    <v-container :style="{ 'max-width': '800px' }">
      <v-card>
        <v-toolbar text color="white elevation-0">
          <v-btn
            :disabled="isFetchingDepartments"
            color="accent"
            @click="openDepartmentAddModal"
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t('adminPage.department.addDepartment') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="query"
            append-icon="mdi-magnify"
            :label="$t('search.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text class="pt-0">
          <DepartmentManagementTable
            :departments="departments"
            :query="query"
            @edit-department="editDepartment"
            @department-deleted="updateDepartmentsFromServer"
          />
        </v-card-text>
      </v-card>
    </v-container>

    <DepartmentAddEditModal
      ref="departmentAddEditModal"
      @department-added="updateDepartmentsFromServer"
      @department-edited="updateDepartmentsFromServer"
    />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { mapActions, mapGetters } from 'vuex';
import DepartmentManagementTable from '../../../components/DepartmentManagementTable.vue';
import DepartmentAddEditModal from './DepartmentAddEditModal.vue';

export default {
  components: {
    DepartmentAddEditModal,
    DepartmentManagementTable,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },
  data: () => ({
    query: null,
  }),
  computed: {
    ...mapGetters([
      'departments',
      'isFetchingDepartments',
      'currentTenant',
      'isTenantManager',
      'currentTenantFromSubdomain',
    ]),
    tenantName() {
      if (this.currentTenantFromSubdomain === '') {
        return '';
      }
      return this.currentTenantFromSubdomain.name;
    },
  },
  created() {
    this.updateDepartmentsFromServer();
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },
    ...mapActions(['updateDepartmentsFromServer']),
    openDepartmentAddModal() {
      this.$refs.departmentAddEditModal.open();
    },
    editDepartment(department) {
      this.$refs.departmentAddEditModal.open(department, true);
    },
  },
};
</script>
