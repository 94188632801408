<template>
  <v-app-bar :clipped-left="true" color="primary" dark fixed app>
    <v-app-bar-nav-icon>
      <v-icon @click="toggleNavDrawer"> mdi-menu </v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title :style="{ cursor: 'pointer' }" @click="redirectToHome">
      <span class="headline font-weight-medium">{{ suiteName }}</span>
      <span class="headline">
        {{ $t('common.appNameShort') }}
      </span>
    </v-toolbar-title>
    <v-spacer />
    <SupportMenu class="pr-3" />
    <UserMenu />
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import UserMenu from './UserMenu.vue';
import SupportMenu from './SupportMenu.vue';

export default {
  components: {
    UserMenu,
    SupportMenu,
  },
  computed: {
    ...mapGetters(['currentTenant', 'isTenantManager']),
    suiteName() {
      return App.suite;
    },
  },
  methods: {
    ...mapMutations(['toggleNavDrawer']),
    redirectToHome() {
      this.$router.push({ name: 'my-account' });
    },
  },
};
</script>
