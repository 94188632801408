const MAX_BULK_EMAIL_LIMIT = 700000;
const BULK_EMAIL_LIMITS = [
  500,
  10000,
  20000,
  30000,
  50000,
  100000,
  300000,
  MAX_BULK_EMAIL_LIMIT,
];

function isCurrentLimitNotMaximum(value) {
  return value !== MAX_BULK_EMAIL_LIMIT;
}
function getHigherLimits(value) {
  return BULK_EMAIL_LIMITS.filter((limit) => limit > value);
}

export { isCurrentLimitNotMaximum, getHigherLimits };
