import httpClient from './httpClient';

const END_POINT = '/admin/users';

const getUsers = () => httpClient.get(END_POINT);

const deactivateUser = (userId) =>
  httpClient.post(`${END_POINT}/${userId}/deactivate`); // POST is used to set Content-Length automatically which gave 411 error using patch no payload

const reactivateUser = (userId) =>
  httpClient.post(`${END_POINT}/${userId}/reactivate`); // POST is used to set Content-Length automatically which gave 411 error using patch no payload

const fetchMeishiCount = (userId) =>
  httpClient.get(`${END_POINT}/${userId}/cards-count`);

const fetchSchedulesCount = (userId) =>
  httpClient.get(`${END_POINT}/${userId}/schedules-count`);

const deleteUser = (userId) => httpClient.delete(`${END_POINT}/${userId}`);

const getUpdateByImportLogs = () =>
  httpClient.get(`${END_POINT}/import/update/logs`);

const addUser = (
  name,
  email,
  departmentId,
  isAdmin,
  allowedApps,
  additionalDepartmentIds,
  samlNameId,
  isSSOEnabled,
) =>
  httpClient.post(END_POINT, {
    name,
    email,
    department_id: departmentId,
    is_admin: isAdmin,
    allowedApps,
    additionalDepartmentIds,
    saml_name_id: samlNameId,
    is_sso_enabled: isSSOEnabled,
  });

const editUser = (
  userId,
  name,
  email,
  departmentId,
  isAdmin,
  allowedApps,
  additionalDepartmentIds,
  samlNameId,
  isSSOEnabled,
) =>
  httpClient.patch(`${END_POINT}/${userId}`, {
    name,
    email,
    department_id: departmentId,
    is_admin: isAdmin,
    allowedApps,
    additionalDepartmentIds,
    saml_name_id: samlNameId,
    is_sso_enabled: isSSOEnabled,
  });

const addUsersFromCsv = (csvFile, onUploadProgress) => {
  const formData = new FormData();
  formData.append('csvFile', csvFile);
  const config = {
    onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return httpClient.post(`${END_POINT}/import`, formData, config);
};

const downloadSampleForAddOrUpdateUsersFromCSV = () =>
  httpClient({
    url: `${END_POINT}/import`,
    method: 'GET',
    responseType: 'blob', // important
  });

const updateUsersFromCsv = (csvFile, onUploadProgress) => {
  const formData = new FormData();
  formData.append('csvFile', csvFile);
  const config = {
    onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return httpClient.post(`${END_POINT}/import/update`, formData, config);
};

const resendInvite = (users) =>
  httpClient.post(`${END_POINT}/invite`, {
    users,
  });

const getActivityLogs = () => httpClient.get('admin/activity-logs');

const getActivityLogsExportCsv = () =>
  httpClient.get('admin/activity-logs/export-csv');

const getContractDetails = () => httpClient.get('admin/contract-details');

const getSsoSettings = () => httpClient.get('/admin/saml');

const storeSsoSettings = (idpEntityId, idpSsoUrl, idpSloUrl, idpCertificate) =>
  httpClient.post('/admin/saml', {
    idpEntityId,
    idpSsoUrl,
    idpSloUrl,
    idpCertificate,
  });

const updateSamlIdpConfiguration = (status) =>
  httpClient.patch('/admin/saml/configure-idp-status', {
    isIdpConfigured: status,
  });

const enableSsoForAllUsers = () =>
  httpClient.post('/admin/users/enable-sso-for-all');

const disableSsoForAllUsers = () =>
  httpClient.post('/admin/users/disable-sso-for-all');

const getTenantContacts = () => httpClient.get('/admin/tenant-contacts');

const storeBillingContact = (data) =>
  httpClient.post('/admin/tenant-contacts/billing-contact', data);

const updateTenantContact = (tenantContactId, data) =>
  httpClient.patch(`/admin/tenant-contacts/${tenantContactId}`, data);

const deleteTenantContact = (tenantContactId) =>
  httpClient.delete(`/admin/tenant-contacts/${tenantContactId}`);

const submitGetMoreServices = (fields) =>
  httpClient.post('admin/get-more-services', { fields });

export {
  getUsers,
  deactivateUser,
  reactivateUser,
  fetchMeishiCount,
  fetchSchedulesCount,
  deleteUser,
  getUpdateByImportLogs,
  addUser,
  editUser,
  addUsersFromCsv,
  downloadSampleForAddOrUpdateUsersFromCSV,
  updateUsersFromCsv,
  resendInvite,
  getActivityLogs,
  getActivityLogsExportCsv,
  getContractDetails,
  getSsoSettings,
  storeSsoSettings,
  updateSamlIdpConfiguration,
  enableSsoForAllUsers,
  disableSsoForAllUsers,
  getTenantContacts,
  storeBillingContact,
  updateTenantContact,
  deleteTenantContact,
  submitGetMoreServices,
};
