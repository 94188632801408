<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card v-if="isShowMessage" width="800">
            <v-alert
              outlined
              :type="isSomethingWrong ? 'error' : 'warning'"
              prominent
              text
            >
              <v-row align="center">
                <v-col class="grow">
                  <span v-if="isLinkExpired">
                    {{ $t('verifyEmail.linkHasExpired') }}<br />
                    <v-btn color="warning" @click="resendVerificationMail">
                      {{ $t('verifyEmail.resendLink') }}
                    </v-btn>
                  </span>
                  <span v-else-if="isSomethingWrong">
                    {{ $t('verifyEmail.somethingWentWrong') }}
                  </span>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <login-footer />
  </v-main>
</template>

<script>
import LoginFooter from '../components/LoginFooter.vue';
import openSnackbar from '../helpers';

export default {
  components: {
    LoginFooter,
  },
  data: () => ({
    isLinkExpired: false,
    isSomethingWrong: false,
  }),
  computed: {
    isShowMessage() {
      return this.isLinkExpired || this.isSomethingWrong;
    },
  },
  created() {
    if (this.hasLinkExpired()) {
      this.isLinkExpired = true;
      return;
    }
    this.verifyEmail();
  },
  methods: {
    hasLinkExpired() {
      const urlParams = new URLSearchParams(window.location.search);
      let expiresAt = urlParams.get('expires');
      expiresAt = parseInt(expiresAt, 10) * 1000;
      const now = Date.now();
      return now > expiresAt;
    },
    verifyEmail() {
      const signature =
        this.$route.query.signature !== undefined
          ? this.$route.query.signature
          : this.$route.query['amp;signature'];
      const postUrl = `users/verify/${this.$route.params.email}?expires=${this.$route.query.expires}&signature=${signature}`;
      axios
        .post(postUrl)
        .then(() => {
          openSnackbar(this.$t('verifyEmail.success'), 'success', 8000);
          this.$router.push({ name: 'login', query: { redirect: '/' } });
        })
        .catch((error) => {
          if (error.response.data === 'already-verified') {
            this.$router.push({ name: 'login', query: { redirect: '/' } });
          } else {
            this.isSomethingWrong = true;
            throw error;
          }
        });
    },
    resendVerificationMail() {
      axios
        .post(`verification-mail/${this.$route.params.email}`)
        .then(() => {
          openSnackbar(this.$t('verifyEmail.emailSent'), 'success', 8000);
        })
        .catch((error) => {
          console.log(error.response);
          this.isSomethingWrong = true;
          throw error;
        });
    },
  },
};
</script>
