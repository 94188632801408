import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"clipped-left":true,"color":"primary","dark":"","fixed":"","app":""}},[_c(VAppBarNavIcon,[_c(VIcon,{on:{"click":_vm.toggleNavDrawer}},[_vm._v(" mdi-menu ")])],1),_c(VToolbarTitle,{style:({ cursor: 'pointer' }),on:{"click":_vm.redirectToHome}},[_c('span',{staticClass:"headline font-weight-medium"},[_vm._v(_vm._s(_vm.suiteName))]),_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('common.appNameShort'))+" ")])]),_c(VSpacer),_c('SupportMenu',{staticClass:"pr-3"}),_c('UserMenu')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }