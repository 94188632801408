const throttleErrorMessage = {
  data: () => ({
    retryAfterSeconds: 0,
    errorMessage: null,
  }),
  methods: {
    displayRetryAfterXSecondsError(retryAfterSeconds, page) {
      this.retryAfterSeconds = retryAfterSeconds;
      this.displayErrorWithRemainingSeconds(page);
      const timer = window.setInterval(() => {
        this.decrementRemainingSeconds();
        this.displayErrorWithRemainingSeconds(page);
        this.terminateIfCountdownReachesZero(timer, page);
      }, 1000);
    },
    displayErrorWithRemainingSeconds(page) {
      this.errorMessage = this.$t(`${page}.tooManyAttempts`, {
        seconds: this.retryAfterSeconds,
      });
    },
    decrementRemainingSeconds() {
      this.retryAfterSeconds -= 1;
    },
    terminateIfCountdownReachesZero(timer, page) {
      if (this.retryAfterSeconds <= 0) {
        this.errorMessage = null;
        window.clearInterval(timer);
        if (page === 'login') {
          this.enableLoginButton();
          this.enableNextButton();
        }
        if (page === 'otp') {
          this.enableOtpSubmitButton();
        }
        if (page === 'backup') {
          this.enableBackupSubmitButton();
        }
      }
    },
  },
};
export default throttleErrorMessage;
