<template>
  <v-data-table
    :headers="headers"
    :items="formattedDepartments"
    :items-per-page="25"
    :footer-props="footerProps"
    sort-by="created_at"
    :sort-desc="true"
    :search="query"
  >
    <template #[`item.actions`]="{ item }">
      <DepartmentManagementTableRowActions
        :department="item"
        @edit-department="emitEditDepartment"
        @department-deleted="emitDepartmentDeleted"
      />
    </template>
  </v-data-table>
</template>

<script>
import { format } from 'date-fns';
import DepartmentManagementTableRowActions from './DepartmentManagementTableRowActions.vue';

export default {
  components: { DepartmentManagementTableRowActions },

  props: {
    departments: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: '',
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('adminPage.department.department'),
          value: 'name',
          width: '35%',
        },
        {
          text: this.$t('adminPage.department.usersCount'),
          value: 'total_users_count',
          width: '25%',
        },
        {
          text: this.$t('adminPage.department.createdAt'),
          value: 'created_at',
          align: 'center',
          width: '25%',
        },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          align: 'center',
          width: '15%',
          sortable: false,
        },
      ];
    },
    formattedDepartments() {
      return this.departments.map((department) => {
        const date = new Date(department.created_at);
        const totalUsersCount =
          department.users_count + department.additional_users_count;
        return {
          ...department,
          created_at: format(date, 'yyyy-MM-dd HH:mm:ss'),
          total_users_count: totalUsersCount,
        };
      });
    },
    footerProps() {
      return {
        'items-per-page-options': [5, 10, 25, 100],
      };
    },
  },

  methods: {
    emitEditDepartment(department) {
      this.$emit('edit-department', department);
    },
    emitDepartmentDeleted() {
      this.$emit('department-deleted');
    },
  },
};
</script>
