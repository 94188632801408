import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"fill-height"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"xs":"12","sm":"8","md":"6","lg":"5","xl":"4"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('otp.enterOtp')))])],1),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VOtpInput,{attrs:{"type":"number"},model:{value:(_vm.otp.otp),callback:function ($$v) {_vm.$set(_vm.otp, "otp", $$v)},expression:"otp.otp"}}),_c('p',{staticClass:"pt-2 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('common.pleaseEnterHalfWidthNumbersOnly'))+" ")]),_c(VCheckbox,{staticClass:"pl-4",attrs:{"label":_vm.$t('otp.rememberDevice'),"hide-details":""},model:{value:(_vm.otp.rememberDevice),callback:function ($$v) {_vm.$set(_vm.otp, "rememberDevice", $$v)},expression:"otp.rememberDevice"}}),_c(VAlert,{attrs:{"value":!!_vm.errorMessage,"outlined":"","transition":"scale-transition","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))]),_c(VCardActions,{staticClass:"pb-4"},[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isSubmitButtonDisabled,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('common.submit')))])],1),_c(VDivider),_c(VSubheader,{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t('otp.havingProblems')))]),_c('ul',{staticClass:"ml-4"},[(_vm.$store.getters.user.twofaMethod === 'sms')?_c('li',[_c('resend-otp')],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"routerLink",attrs:{"to":{ name: 'backup-login', query: _vm.$route.query }}},[_vm._v(" "+_vm._s(_vm.$t('otp.loginBackupCodes'))+" ")])],1)])],1)],1)],1)],1)],1)],1),_c('login-footer'),_c('RedirectionModal',{ref:"redirectionModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }