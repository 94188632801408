<template>
  <v-row align="center">
    <v-col cols="5">
      <v-checkbox
        v-model="isEnabled"
        :label="message"
        hide-details
        class="shrink mr-2 mt-0"
        @change="$emit('updated:is-enabled', isEnabled)"
      />
    </v-col>
    <v-spacer />
    <v-col cols="3">
      <v-text-field
        v-model="count"
        :disabled="!isEnabled"
        :suffix="suffix"
        hide-details
        outlined
        type="number"
        min="0"
        @input="$emit('updated:count', count)"
      />
    </v-col>
    <v-spacer />
    <v-col cols="4">
      {{ subscriptionStatus }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: true,
    },
    subscriptionStatus: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isEnabled: false,
    count: 0,
  }),
};
</script>

<style></style>
