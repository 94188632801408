import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import appSettings from './modules/appSettings';
import snackbar from './modules/snackbar';
import changePassword from './modules/changePassword';
import departments from './modules/departments';
import user from './modules/user';
import users from './modules/users';
import navDrawer from './modules/navDrawer';
import redirection from './modules/redirectionModal';
import tenant from './modules/tenant';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appSettings,
    snackbar,
    changePassword,
    departments,
    user,
    users,
    navDrawer,
    redirection,
    tenant,
  },
  plugins: [
    createPersistedState({
      paths: ['appSettings.language'],
    }),
  ],
});
