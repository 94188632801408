<template>
  <v-list>
    <v-alert
      v-if="user.twofaMethod !== null"
      type="warning"
      dense
      text
      border="left"
      class="subtitle-2"
      >{{ $t('twofa.editWarning') }}</v-alert
    >
    <template v-for="(item, i) in items">
      <v-list-item :key="i" three-line>
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-text="item.subtitle" />
          <v-list-item-subtitle v-text="item.text" />
        </v-list-item-content>
        <v-btn color="primary" @click="choose(item.id)">{{
          $t('twofa.choose')
        }}</v-btn>
      </v-list-item>
      <v-divider v-if="i < items.length - 1" :key="'div' + i" />
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user']),
    items() {
      return [
        {
          id: 'app',
          title: this.$i18n.t('twofa.app'),
          subtitle: this.$i18n.t('twofa.appSubtitle'),
          text: this.$i18n.t('twofa.appText'),
        },
        {
          id: 'sms',
          title: this.$i18n.t('twofa.sms'),
          subtitle: this.$i18n.t('twofa.smsSubtitle'),
          text: this.$i18n.t('twofa.smsText'),
        },
      ];
    },
  },
  methods: {
    choose(id) {
      this.$emit('chosen', id);
    },
  },
};
</script>

<style></style>
