const state = {
  isRedirectionModalOpen: false,
};

const getters = {
  isRedirectionModalOpen: (state) => state.isRedirectionModalOpen,
};

const mutations = {
  updateIsRedirectionModalOpen: (state, payload) => {
    state.isRedirectionModalOpen = payload;
  },
};

const actions = {
  setIsRedirectionModalOpen: ({ commit }) => {
    commit('updateIsRedirectionModalOpen', true);
  },
  resetIsRedirectionModalOpen: ({ commit }) => {
    commit('updateIsRedirectionModalOpen', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
