<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn
        icon
        :color="color"
        :loading="isLoading"
        :disabled="isDisabled"
        v-on="on"
        @click.stop="emitClick"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>
      {{ tooltip }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitClick($mouseEvent) {
      this.$emit('click', $mouseEvent);
    },
  },
};
</script>
