import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[(_vm.isShowMessage)?_c(VCard,{attrs:{"width":"800"}},[_c(VAlert,{attrs:{"outlined":"","type":_vm.isSomethingWrong ? 'error' : 'warning',"prominent":"","text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[(_vm.isLinkExpired)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('verifyEmail.linkHasExpired'))),_c('br'),_c(VBtn,{attrs:{"color":"warning"},on:{"click":_vm.resendVerificationMail}},[_vm._v(" "+_vm._s(_vm.$t('verifyEmail.resendLink'))+" ")])],1):(_vm.isSomethingWrong)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('verifyEmail.somethingWentWrong'))+" ")]):_vm._e()])],1)],1)],1):_vm._e()],1)],1)],1),_c('login-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }