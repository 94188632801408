import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-12 elevation-0"},[_c('div',{staticClass:"pl-3 pb-2 subtitle-1"},[_vm._v(_vm._s(_vm.$t('twofa.backupCodes')))]),_c('div',{staticClass:"pl-3 body-2 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('twofa.backupCodesText'))+" ")]),_c('div',{staticClass:"pl-3 body-2 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('twofa.downloadPrintOrCopy'))+" ")]),_c(VRow,{staticClass:"justify-center align-center pt-3"},[_c(VCol,{attrs:{"md":"6"}},[_c(VCard,[_vm._l((_vm.backupCodes),function(code,index){return [_c(VListItem,{key:`item.${index}`},[_c(VListItemTitle,[_c('b',[_vm._v(_vm._s(code))])])],1),(index < _vm.backupCodes.length - 1)?_c(VDivider,{key:'div.' + index}):_vm._e()]})],2),_c(VRow,{staticClass:"pt-5",attrs:{"justify":"space-around"}},[_c(VBtn,{on:{"click":_vm.download}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t('twofa.download'))+" ")],1),(!_vm.isMobile)?_c(VBtn,{on:{"click":_vm.print}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-printer")]),_vm._v(" "+_vm._s(_vm.$t('twofa.print'))+" ")],1):_vm._e(),_c(VBtn,{on:{"click":_vm.copy}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-content-copy")]),_vm._v(" "+_vm._s(_vm.$t('twofa.copy'))+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }