import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"mb-16",attrs:{"cols":"12"}},[_c(VImg,{attrs:{"src":_vm.logoPath,"height":"80","contain":""}})],1),_c(VCol,{staticClass:"mt-16",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mx-auto text-start pa-4 text-caption-1",staticStyle:{"background-color":"#e3f2fd"},attrs:{"outlined":"","rounded":"lg","max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.$t('OpenMeishiMobile.explanation'))+" ")])],1),_c(VCol,{attrs:{"align":"center"}},[_c(VBtn,{attrs:{"dark":"","x-large":"","color":"primary","width":_vm.buttonWidth,"height":"60"},on:{"click":_vm.open}},[_vm._v(" "+_vm._s(_vm.$t('OpenMeishiMobile.openApp'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }