<template>
  <v-dialog v-model="dialog" max-width="640">
    <v-card>
      <v-card-title class="title">
        {{ $t('SessionDeviceModal.devicesWhereYouAreLoggedIn') }}
        <v-spacer />
        <v-btn icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="text-body-1 pb-0">
              {{ $t('SessionDeviceModal.thisDevice') }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-layout v-if="isLoading" align-center justify-center column>
                <v-flex row align-center>
                  <v-progress-circular indeterminate color="primary" />
                </v-flex>
              </v-layout>

              <v-card v-else>
                <v-list>
                  <SessionDevice :session-device="currentSession" />
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-body-1 pb-0">
              {{ $t('SessionDeviceModal.otherDevices') }}
            </v-col>
          </v-row>

          <v-row v-if="otherSessions.length > 0">
            <v-col>
              <SecureAccountDialog
                :button-text="
                  $t('SessionDeviceModal.isThereADeviceThatYouDoNotRecognise')
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-layout v-if="isLoading" align-center justify-center column>
                <v-flex row align-center>
                  <v-progress-circular indeterminate color="primary" />
                </v-flex>
              </v-layout>
              <v-card v-else height="420px" class="v-card-scrollable">
                <v-card-text class="v-card__text-scrollable">
                  <p
                    v-if="otherSessions.length === 0"
                    class="align-center justify-center"
                  >
                    {{
                      $t(
                        'SessionDeviceModal.currentlyYouAreNotLoggedInOnAnyOtherDevice',
                      )
                    }}
                  </p>
                  <v-list v-else>
                    <div
                      v-for="sessionDevice in otherSessions"
                      :key="sessionDevice.id"
                    >
                      <SessionDevice
                        :session-device="sessionDevice"
                        show-logout-button
                        @refresh="fetchSessionDevices"
                      />
                      <v-divider />
                    </div>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="otherSessions.length > 0">
            <v-col class="text-right pt-0">
              <v-btn
                text
                small
                :loading="isLoggingOutAllOtherDevices"
                @click="logOutAllOtherDevices"
              >
                {{ $t('SessionDeviceModal.logOutFromOtherDevices') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="text-body-2">
            <v-col>
              {{ $t('Devices.locationsBasedOnIp') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <Confirm ref="confirm" />
  </v-dialog>
</template>

<script>
import SecureAccountDialog from '@/components/Security/SecureAccountDialog.vue';
import {
  logOutNonCurrentSessions,
  getSessionDevices,
} from '@/api/sessionDevices.api';
import SessionDevice from '@/components/Security/SessionDevicesModalListItem.vue';
import Confirm from '@/components/Confirm.vue';

export default {
  components: { Confirm, SessionDevice, SecureAccountDialog },

  data() {
    return {
      dialog: false,
      isLoading: false,
      currentSession: null,
      otherSessions: [],
      isLoggingOutAllOtherDevices: false,
    };
  },

  computed: {
    isPasswordChanged() {
      return this.$store.getters.isPasswordChanged;
    },
  },

  watch: {
    isPasswordChanged(newVal) {
      if (newVal === true) {
        this.fetchSessionDevices();
        this.$store.dispatch('resetPasswordChanged');
      }
    },
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
      this.fetchSessionDevices();
    },

    close() {
      this.dialog = false;
    },

    reset() {
      this.currentSession = null;
      this.otherSessions = [];
    },

    async fetchSessionDevices() {
      this.isLoading = true;
      const response = await getSessionDevices();
      this.isLoading = false;
      this.currentSession = response.data.currentSession;
      this.otherSessions = response.data.otherSessions;
    },

    async logOutAllOtherDevices() {
      const confirm = await this.$refs.confirm.open(
        this.$t('SessionDeviceModal.logOutFromOtherDevices'),
        this.$t(
          'SessionDeviceModal.areYouSureYouWantToLogOutFromAllOtherDevices',
        ),
        {
          color: 'warning',
          width: 500,
        },
      );
      if (confirm === true) {
        this.isLoggingOutAllOtherDevices = true;
        await logOutNonCurrentSessions();
        this.isLoggingOutAllOtherDevices = false;
        await this.fetchSessionDevices();
      }
    },
  },
};
</script>

<style scoped>
.v-card-scrollable {
  display: flex !important;
  flex-direction: column;
}

.v-card__text-scrollable {
  flex-grow: 1;
  overflow: auto;
}
</style>
