import { getUsers } from '../../api/admin.api';
import i18n from '../../i18n';

function getAllowedAppsForUser(user, allowedAppsForTenant) {
  return allowedAppsForTenant
    .map((app) => app.key)
    .filter((app) => user.pivot[`${app}_access_granted_at`]);
}

function formatUser(user, allowedAppsForTenant) {
  return {
    id: user.id,
    department:
      user.departments.length > 0
        ? user.departments[0]
        : { id: null, name: null },
    name: user.name,
    email: user.email,
    role:
      user.pivot.is_admin === 1
        ? i18n.t('adminPage.user.admin')
        : i18n.t('adminPage.user.normal'),
    is_admin: user.pivot.is_admin,
    is_sso_enabled: user.pivot.is_sso_enabled,
    operationStatus: user.pivot.operation_status,
    isTwofaEnabled: user.twofa_method !== null,
    last_active_at: user.pivot.last_active_at,
    deactivated_at: user.pivot.deactivated_at,
    allowedApps: getAllowedAppsForUser(user, allowedAppsForTenant),
    additionalDepartments: user.additional_departments_for_tenant,
    saml_name_id: user.saml_name_id,
    created_at: user.created_at,
  };
}

const state = {
  users: [],
  isFetchingUsers: false,
  allowedAppsForTenant: [],
};

const getters = {
  activeUsers: (state) => {
    const activeUsers = state.users.filter(
      (user) => user.pivot.deactivated_at === null,
    );
    const formattedActiveUsers = activeUsers.map((user) =>
      formatUser(user, state.allowedAppsForTenant),
    );
    return formattedActiveUsers;
  },
  deactivatedUsers: (state) => {
    const deactivatedUsers = state.users.filter(
      (user) => user.pivot.deactivated_at !== null,
    );
    const formattedDeactivatedUsers = deactivatedUsers.map((user) =>
      formatUser(user, state.allowedAppsForTenant),
    );
    return formattedDeactivatedUsers;
  },
  countActiveUsersWithSSOEnabled: (state, getters) => {
    const activeUsersWithSSOEnabled = getters.activeUsers.filter(
      (user) => user.is_sso_enabled === true,
    );
    return activeUsersWithSSOEnabled.length;
  },

  isFetchingUsers: (state) => state.isFetchingUsers,
  allowedAppsForTenant: (state) => state.allowedAppsForTenant,
};

const mutations = {
  updateUsers: (state, payload) => {
    state.users = payload;
  },
  updateIsFetchingUsers: (state, payload) => {
    state.isFetchingUsers = payload;
  },
  updateUsersLimit: (state, payload) => {
    state.usersLimit = payload;
  },
  updateAllowedAppsForTenant: (state, payload) => {
    state.allowedAppsForTenant = payload;
  },
};

const actions = {
  updateUsersFromServer: async ({ commit }) => {
    commit('updateIsFetchingUsers', true);
    const response = await getUsers();
    commit('updateUsers', response.data);
    commit('updateIsFetchingUsers', false);
  },
  updateAllowedAppsForTenant: async ({ commit }) => {
    const response = await axios.get('/admin/apps/allowed');
    commit('updateAllowedAppsForTenant', response.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
