import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-2 title"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('SubdomainEditDialog.editCustomSubdomain'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","icon":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pb-0 py-4"},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"placeholder":_vm.$t('SubdomainEditDialog.placeholder'),"prefix":"https://","suffix":".tnexta.com","outlined":"","rules":_vm.subdomainRules},model:{value:(_vm.subdomain),callback:function ($$v) {_vm.subdomain=$$v},expression:"subdomain"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":_vm.shouldDisableSubmit,"color":"accent"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('common.submit'))+" ")])],1)],1),_c('ConfirmPasswordDialog',{ref:"confirmPasswordDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }