import httpClient from './httpClient';

const END_POINT = '/session-devices';

const getSessionDevices = () => httpClient.get(END_POINT);

const getSessionDevice = (sessionDeviceId) =>
  httpClient.get(`${END_POINT}/${sessionDeviceId}`);

const logOutGivenSession = (sessionDevice) =>
  httpClient.post(`${END_POINT}/${sessionDevice.id}/logout`);

const logOutNonCurrentSessions = () =>
  httpClient.post(`${END_POINT}/logout-others`);

export {
  getSessionDevices,
  getSessionDevice,
  logOutGivenSession,
  logOutNonCurrentSessions,
};
