<template>
  <v-menu offset-y offset-x>
    <template #activator="{ on }">
      <v-avatar
        v-ripple
        size="40px"
        color="accent"
        class="white--text title"
        style="cursor: pointer"
        v-on="on"
        >{{ user.name.charAt(0) }}</v-avatar
      >
    </template>

    <v-list class="pa-0">
      <template v-if="doesUserBelongToMultipleTenants">
        <v-list-item @click.stop="openSwitchOrganizationModal">
          <v-list-item-icon>
            <v-icon>mdi-domain-switch</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ $t('UserMenu.switchOrganization') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
      </template>

      <v-list-item ripple @click="logout">
        <v-list-item-action>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ $t('toolbar.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <TheSwitchOrganizationModal ref="theSwitchOrganizationModal" />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import TheSwitchOrganizationModal from './TheSwitchOrganizationModal.vue';

export default {
  components: {
    TheSwitchOrganizationModal,
  },

  computed: {
    ...mapGetters(['user']),

    doesUserBelongToMultipleTenants() {
      return this.user.tenants.length > 1;
    },
  },

  methods: {
    logout() {
      axios.post('/logout').then((response) => {
        this.$store.dispatch('resetUser');
        if (response.data.sloUrl) {
          const redirectUrl = `${window.location.origin}`;
          window.location = `${response.data.sloUrl}?fromURI=${redirectUrl}`;
          return;
        }
        this.$router.push({ name: 'login' });
      });
    },

    openSwitchOrganizationModal() {
      this.$refs.theSwitchOrganizationModal.open();
    },
  },
};
</script>
