<template>
  <v-row v-if="showThisComponent" justify="center">
    <v-card width="700px">
      <v-card-text>
        <v-alert
          v-model="data.accounts.alert"
          color="cyan"
          border="left"
          colored-border
          icon="mdi-cogs"
        >
          <strong> ネクスタ・アカウント: </strong>
          {{ data.accounts.message }}
        </v-alert>
        <v-alert
          v-model="data.meishi.alert"
          color="cyan"
          border="left"
          colored-border
          icon="mdi-cogs"
        >
          <strong> {{ $t('apps.meishi') }}: </strong>
          {{ data.meishi.message }}
        </v-alert>
        <v-alert
          v-model="data.scheduler.alert"
          color="cyan"
          border="left"
          colored-border
          icon="mdi-cogs"
        >
          <strong> {{ $t('apps.scheduler') }}: </strong>
          {{ data.scheduler.message }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      data: {
        accounts: {
          alert: false,
          message: null,
        },
        meishi: {
          alert: false,
          message: null,
        },
        scheduler: {
          alert: false,
          message: null,
        },
      },
    };
  },
  computed: {
    showThisComponent() {
      return (
        this.data.accounts.alert !== false ||
        this.data.meishi.alert !== false ||
        this.data.scheduler.alert !== false
      );
    },
  },
  async created() {
    const response = await axios.get('maintenance-messages');
    const { data } = response;
    if (data.accounts) {
      this.data.accounts.message = data.accounts.message;
      this.data.accounts.alert = true;
    }
    if (data.meishi) {
      this.data.meishi.message = data.meishi.message;
      this.data.meishi.alert = true;
    }
    if (data.scheduler) {
      this.data.scheduler.message = data.scheduler.message;
      this.data.scheduler.alert = true;
    }
  },
};
</script>
