import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-12 elevation-0"},[_c('div',{staticClass:"pl-3 pb-2 subtitle-1"},[_vm._v(_vm._s(_vm.$t('twofa.scanBarcode')))]),_c('div',{staticClass:"pl-3 body-2 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('twofa.scanBarcodeText'))+" "),_c('span',{staticClass:"pl-1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isCodeShown = true}}},[_c('u',[_vm._v(_vm._s(_vm.$t('twofa.manualCode')))])])]),(_vm.isCodeShown)?_c('div',{staticClass:"pl-3 subtitle-1"},[_vm._v(_vm._s(_vm.manualCode))]):_vm._e(),_c('div',{staticClass:"pt-2",attrs:{"justify":"center","align":"center"}},[_c(VImg,{attrs:{"src":_vm.qrCode,"height":"200px","contain":""}})],1),_c(VRow,{staticClass:"pl-3 pt-3 pb-2 subtitle-1"},[_vm._v(_vm._s(_vm.$t('twofa.enterOtp')))]),_c(VRow,{staticClass:"pl-3 body-2 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('twofa.enterOtpText'))+" ")]),_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"md":"6"}},[_c(VOtpInput,{attrs:{"type":"number"},on:{"finish":_vm.setOtp},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('p',{staticClass:"pt-2 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('common.pleaseEnterHalfWidthNumbersOnly'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }