const devicesModalListItemMixin = {
  methods: {
    getDevice(agent) {
      if (agent.device === null) {
        return this.$t('SessionDeviceModal.unknownDevice');
      }
      return agent.device;
    },

    getApp(agent) {
      if (agent.isNextaMeishiApp === true) {
        return this.$t('apps.meishiSmartphoneApp');
      }
      if (agent.browser === null) {
        return this.$t('SessionDeviceModal.unknownBrowser');
      }
      return agent.browser;
    },

    getLocation(location) {
      if (location.countryCode === 'ZZ') {
        return this.$t('Devices.unknownLocation');
      }
      return `${location.city}, ${location.state}, ${location.country}`;
    },
  },
};

export default devicesModalListItemMixin;
