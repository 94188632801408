import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VDivider),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"mt-4 text--primary",domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":_vm.options.color,"text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c(VBtn,{attrs:{"color":_vm.options.color},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('common.yes'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }