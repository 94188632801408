<template>
  <div>
    <v-main>
      <v-alert v-if="!currentTenant && isTenantManager" type="info">
        {{ $t('TenantManagerWarning.alert') }}

        {{ tenantName }}</v-alert
      >
      <v-container fluid>
        <v-row justify="center" align="center">
          <v-card min-width="1000">
            <v-container class="py-6">
              <v-row class="px-4">
                <v-btn
                  text
                  rounded
                  class="px-2"
                  @click="$router.push({ name: 'admin-user-management' })"
                >
                  <span class="title">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('toolbar.back') }}
                  </span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="query"
                  append-icon="mdi-magnify"
                  :label="$t('search.search')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-row>
            </v-container>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="deactivatedUsers"
                :options.sync="pagination"
                :footer-props="footerProps"
                :search="query"
                :loading="isFetchingUsers"
              >
                <template #item="{ item }">
                  <tr>
                    <td>
                      {{ item.department.name }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                      {{ item.role }}
                    </td>
                    <td>{{ formatTimestamp(item.deactivated_at) }}</td>
                    <td class="text-center">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            color="accent"
                            class="mx-0"
                            :disabled="
                              (allowedAppsForTenant[0]?.isSeatAvailable ??
                                false) === false
                            "
                            v-on="on"
                            @click="reactivate(item.id)"
                          >
                            <v-icon>mdi-account-arrow-left</v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          $t('deactivatedUserManagementPage.reactivateAccount')
                        }}</span>
                      </v-tooltip>
                      <v-menu>
                        <template #activator="{ on }">
                          <v-btn icon color="accent" class="mx-0" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="editUser(item)">
                            <v-btn icon>
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-list-item-title>
                              {{ $t('common.edit') }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteUser(item)">
                            <v-btn icon>
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-list-item-title>
                              {{ $t('common.delete') }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>

    <confirm ref="confirm" />

    <DeleteUserWithCards
      ref="deleteUserWithCards"
      @deleted="updateUsersFromServer"
    />

    <user-edit-modal ref="userEditModal" @user-edited="updateUsersFromServer" />
  </div>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { mapActions, mapGetters } from 'vuex';
import dateTimeHelpers from '@/mixins/dateTimeHelpers';
import { reactivateUser } from '../../../api/admin.api';
import Confirm from '../../../components/Confirm.vue';
import openSnackbar from '../../../helpers';
import DeleteUserWithCards from '../UserManagement/DeleteUserWithCards.vue';
import UserEditModal from '../UserManagement/Edit/UserEditModal.vue';

export default {
  components: {
    Confirm,
    DeleteUserWithCards,
    UserEditModal,
  },
  mixins: [dateTimeHelpers],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },
  data: () => ({
    query: '',
    pagination: {
      sortBy: ['deactivated_at'],
      sortDesc: [true],
      itemsPerPage: 25,
    },
    footerProps: {
      'items-per-page-options': [5, 10, 25, 100],
    },
  }),
  computed: {
    ...mapGetters([
      'deactivatedUsers',
      'isFetchingUsers',
      'allowedAppsForTenant',
      'currentTenant',
      'isTenantManager',
      'currentTenantFromSubdomain',
    ]),
    headers() {
      return [
        {
          text: this.$i18n.t('adminPage.user.department'),
          value: 'department.name',
          sortable: true,
        },
        {
          text: this.$i18n.t('adminPage.user.name'),
          value: 'name',
          sortable: true,
        },
        {
          text: this.$i18n.t('adminPage.user.email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$i18n.t('adminPage.user.userType'),
          value: 'role',
          sortable: true,
        },
        {
          text: this.$i18n.t('deactivatedUserManagementPage.deactivatedAt'),
          value: 'deactivated_at',
          sortable: true,
        },
        {
          text: this.$i18n.t(
            'deactivatedUserManagementPage.reactivateOrDelete',
          ),
          sortable: false,
        },
      ];
    },
    tenantName() {
      if (this.currentTenantFromSubdomain === '') {
        return '';
      }
      return this.currentTenantFromSubdomain.name;
    },
  },
  created() {
    this.updateUsersFromServer();
    this.updateDepartmentsFromServer();
    this.updateAllowedAppsForTenant();
  },
  methods: {
    ...mapActions([
      'updateUsersFromServer',
      'updateAllowedAppsForTenant',
      'updateDepartmentsFromServer',
    ]),
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },
    async reactivate(id) {
      const confirm = await this.$refs.confirm.open(
        this.$t('deactivatedUserManagementPage.reactivateAccount'),
        this.$t('deactivatedUserManagementPage.reactivateConfirmation'),
        { color: 'success', width: '400' },
      );
      if (confirm !== true) {
        return;
      }

      await reactivateUser(id);
      this.updateUsersFromServer();
      openSnackbar(
        this.$t('deactivatedUserManagementPage.userReactivatedSuccessfully'),
        'success',
      );
    },
    async deleteUser(user) {
      this.$refs.deleteUserWithCards.open(user);
    },
    editUser(user) {
      this.$refs.userEditModal.open(user, true);
    },
  },
};
</script>
