const App = Object.freeze({
  suite: process.env.VUE_APP_SUITE || 'NEXTa',
  helpers: {
    getSubdomain() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const hostnameArr = url.host.split('.');
      if (hostnameArr.length < 3) {
        return null;
      }
      const subdomain = hostnameArr[0];
      return subdomain;
    },
  },
});

window.App = App;

export default App;
