import httpClient from './httpClient';

const END_POINT = '/trusted-devices';

const getTrustedDevices = () => httpClient.get(END_POINT);

const deleteTrustedDevice = (trustedDevice) =>
  httpClient.delete(`${END_POINT}/${trustedDevice.id}`);

const postTrustedDevice = (sessionDevice) =>
  httpClient.post(END_POINT, {
    sessionDeviceId: sessionDevice.id,
  });

export { getTrustedDevices, deleteTrustedDevice, postTrustedDevice };
