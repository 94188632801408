<template>
  <v-main>
    <v-container :style="{ 'max-width': '900px' }">
      <v-card>
        <v-card-title>
          {{ $t('SubscriptionsPage.title') }}
        </v-card-title>
        <v-alert
          v-if="!isLoading && isTenantUnderTrial"
          type="warning"
          border="top"
          prominent
          outlined
          class="ma-2 mt-0"
        >
          {{ $t('SubscriptionsPage.alertUnderTrial') }}
        </v-alert>

        <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>

        <v-card-text v-else class="pb-0">
          <v-list>
            <v-list-item v-if="isTenantUnderTrial" class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.trialEndsAt') }}
              </v-col>
              <v-col cols="5">
                {{
                  $t('SubscriptionsPage.until', {
                    date: formatTrialDate(contractDetails.trial_ends_at),
                  })
                }}
              </v-col>
            </v-list-item>

            <v-divider v-if="isTenantUnderTrial" />

            <v-list-item v-if="!isTenantUnderTrial" class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.contractRenewalDate') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.contract_renews_at
                    ? $t('SubscriptionsPage.until', {
                        date: formatDate(contractDetails.contract_renews_at),
                      })
                    : $t('SubscriptionsPage.na')
                }}
              </v-col>
            </v-list-item>

            <v-divider v-if="!isTenantUnderTrial" />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('apps.meishi') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.meishi_users_limit === null
                    ? $t('SubscriptionsPage.unlimitedAndActiveAccounts', {
                        active: contractDetails.meishi_active_accounts,
                      })
                    : $t('SubscriptionsPage.totalAndActiveAccounts', {
                        total: contractDetails.meishi_users_limit,
                        active: contractDetails.meishi_active_accounts,
                      })
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('apps.scheduler') }}
              </v-col>
              <v-col cols="5">
                {{ getSchedulerMessage() }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.bnFeature') }}
              </v-col>
              <v-col cols="5">
                {{ getBnFeatureMessage() }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.bulkEmailFeature') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.feature_bulk_email_monthly_limit > 0
                    ? $t('SubscriptionsPage.totalAndUsedEmails', {
                        total: contractDetails.feature_bulk_email_monthly_limit,
                        used: contractDetails.bulk_email_monthly_usage,
                      })
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.kintoneLinkage') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.is_feature_kintone_subscribed
                    ? $t('SubscriptionsPage.subscribed')
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.salesforceLinkage') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.is_feature_salesforce_subscribed
                    ? $t('SubscriptionsPage.subscribed')
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.relationLinkage') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.is_feature_relation_subscribed
                    ? $t('SubscriptionsPage.subscribed')
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.genieeLinkage') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.is_feature_geniee_subscribed
                    ? $t('SubscriptionsPage.subscribed')
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.ssoFeature') }}
              </v-col>
              <v-col cols="5">
                {{ getSsoFeatureMessage() }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.ipRestriction') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.is_feature_ip_restriction_subscribed
                    ? $t('SubscriptionsPage.subscribed')
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>

            <v-divider />

            <v-list-item class="pl-0">
              <v-col cols="7">
                {{ $t('SubscriptionsPage.scannerApp') }}
              </v-col>
              <v-col cols="5">
                {{
                  contractDetails.feature_scanner_app_keys_limit > 0
                    ? $t('SubscriptionsPage.totalAndActiveKeys', {
                        total: contractDetails.feature_scanner_app_keys_limit,
                        active: contractDetails.scanner_app_active_keys,
                      })
                    : $t('SubscriptionsPage.notSubscribed')
                }}
              </v-col>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { isFuture, format } from 'date-fns';
import { getContractDetails } from '../../api/admin.api';

import dateTimeHelpers from '../../mixins/dateTimeHelpers';

export default {
  mixins: [dateTimeHelpers],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data() {
    return {
      contractDetails: [],
      isLoading: true,
    };
  },

  computed: {
    isTenantUnderTrial() {
      if (this.isTenantUnderContract) {
        return false;
      }

      return isFuture(new Date(this.contractDetails.trial_ends_at));
    },

    isTenantUnderContract() {
      if (this.contractDetails.contract_starts_at === null) {
        return false;
      }
      if (isFuture(new Date(this.contractDetails.contract_starts_at))) {
        return false;
      }
      if (this.contractDetails.contract_ends_at === null) return true;

      return isFuture(new Date(this.contractDetails.contract_ends_at));
    },
  },

  mounted() {
    this.fetchContractDetails();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    async fetchContractDetails() {
      this.isLoading = true;
      try {
        const { data } = await getContractDetails();
        this.contractDetails = data;
      } catch (e) {
        // something went wrong
      } finally {
        this.isLoading = false;
      }
    },

    formatTrialDate(date) {
      return format(new Date(date), 'yyyy/MM/dd HH:mm:ss');
    },

    getBnFeatureMessage() {
      if (this.contractDetails.feature_negotiations_users_limit === null) {
        return this.$t('SubscriptionsPage.unlimitedAndActiveAccounts', {
          active: this.contractDetails.negotiations_active_accounts,
        });
      }
      if (this.contractDetails.feature_negotiations_users_limit < 1) {
        return this.$t('SubscriptionsPage.notSubscribed');
      }
      return this.$t('SubscriptionsPage.totalAndActiveAccounts', {
        total: this.contractDetails.feature_negotiations_users_limit,
        active: this.contractDetails.negotiations_active_accounts,
      });
    },

    getSsoFeatureMessage() {
      if (this.contractDetails.feature_sso_users_limit === null) {
        return this.$t('SubscriptionsPage.unlimitedAndActiveAccounts', {
          active: this.contractDetails.sso_active_accounts,
        });
      }
      if (this.contractDetails.feature_sso_users_limit < 1) {
        return this.$t('SubscriptionsPage.notSubscribed');
      }
      return this.$t('SubscriptionsPage.totalAndActiveAccounts', {
        total: this.contractDetails.feature_sso_users_limit,
        active: this.contractDetails.sso_active_accounts,
      });
    },

    getSchedulerMessage() {
      if (!this.contractDetails.is_tenant_subscribed_to_scheduler) {
        return this.$t('SubscriptionsPage.notSubscribed');
      }
      return this.contractDetails.scheduler_users_limit === null
        ? this.$t('SubscriptionsPage.unlimitedAndActiveAccounts', {
            active: this.contractDetails.scheduler_active_accounts,
          })
        : this.$t('SubscriptionsPage.totalAndActiveAccounts', {
            total: this.contractDetails.scheduler_users_limit,
            active: this.contractDetails.scheduler_active_accounts,
          });
    },
  },
};
</script>
