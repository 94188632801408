import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMain,[(!_vm.currentTenant && _vm.isTenantManager)?_c(VAlert,{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('TenantManagerWarning.alert'))+" "+_vm._s(_vm.tenantName))]):_vm._e(),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCard,{attrs:{"min-width":"1000"}},[_c(VContainer,{staticClass:"py-6"},[_c(VRow,{staticClass:"px-4"},[_c(VBtn,{staticClass:"px-2",attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.$router.push({ name: 'admin-user-management' })}}},[_c('span',{staticClass:"title"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t('toolbar.back'))+" ")],1)]),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search.search'),"single-line":"","hide-details":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.deactivatedUsers,"options":_vm.pagination,"footer-props":_vm.footerProps,"search":_vm.query,"loading":_vm.isFetchingUsers},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.department.name)+" ")]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(" "+_vm._s(item.role)+" ")]),_c('td',[_vm._v(_vm._s(_vm.formatTimestamp(item.deactivated_at)))]),_c('td',{staticClass:"text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-0",attrs:{"icon":"","color":"accent","disabled":(_vm.allowedAppsForTenant[0]?.isSeatAvailable ??
                              false) === false},on:{"click":function($event){return _vm.reactivate(item.id)}}},on),[_c(VIcon,[_vm._v("mdi-account-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('deactivatedUserManagementPage.reactivateAccount')))])]),_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-0",attrs:{"icon":"","color":"accent"}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.editUser(item)}}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")])],1),_c(VListItem,{on:{"click":function($event){return _vm.deleteUser(item)}}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-delete")])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")])],1)],1)],1)],1)])]}}])})],1)],1)],1)],1)],1),_c('confirm',{ref:"confirm"}),_c('DeleteUserWithCards',{ref:"deleteUserWithCards",on:{"deleted":_vm.updateUsersFromServer}}),_c('user-edit-modal',{ref:"userEditModal",on:{"user-edited":_vm.updateUsersFromServer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }