import httpClient from './httpClient';

const END_POINT = '/admin/departments';

const addDepartment = (name) => httpClient.post(`${END_POINT}`, { name });

const editDepartment = (departmentId, name) =>
  httpClient.patch(`${END_POINT}/${departmentId}`, { name });

const deleteDepartment = (departmentId) =>
  httpClient.delete(`${END_POINT}/${departmentId}`);

export { addDepartment, editDepartment, deleteDepartment };
