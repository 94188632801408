import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 title"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('userAddEditModal.editUser'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('UserAddEditForm',{attrs:{"dialog":_vm.dialog,"is-editing":true,"user":_vm.user,"is-deactivated":_vm.isDeactivated},on:{"user-edited":_vm.userEdited}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }