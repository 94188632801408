import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,[(_vm.user.twofaMethod !== null)?_c(VAlert,{staticClass:"subtitle-2",attrs:{"type":"warning","dense":"","text":"","border":"left"}},[_vm._v(_vm._s(_vm.$t('twofa.editWarning')))]):_vm._e(),_vm._l((_vm.items),function(item,i){return [_c(VListItem,{key:i,attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.subtitle)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.text)}})],1),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.choose(item.id)}}},[_vm._v(_vm._s(_vm.$t('twofa.choose')))])],1),(i < _vm.items.length - 1)?_c(VDivider,{key:'div' + i}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }