import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{style:({ 'max-width': '900px' })},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('IpAddressRestrictionPage.title'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('IpAddressRestrictionPage.description'))+" "),(!_vm.isFeatureIpRestrictionSubscribed)?_c('PremiumFeatureExplanation'):[_c('IpAddressRestrictionPageTable',{staticClass:"my-4",attrs:{"loading":_vm.loading,"whitelisted-ips":_vm.whitelistedIps,"is-ip-restriction-enabled":_vm.isIPRestrictionEnabled},on:{"refresh":_vm.refresh}}),_c(VSwitch,{staticClass:"d-inline-block mx-4",attrs:{"label":_vm.$t('IpAddressRestrictionPage.enableIpRestriction'),"disabled":_vm.whitelistedIps.length === 0},nativeOn:{"!click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleUpdateIPRestrictionConfiguration.apply(null, arguments)}},model:{value:(_vm.isIPRestrictionEnabled),callback:function ($$v) {_vm.isIPRestrictionEnabled=$$v},expression:"isIPRestrictionEnabled"}})]],2)],1),_c('Confirm',{ref:"confirm"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }