<template>
  <v-card class="mb-12 elevation-0">
    <div class="pl-3 pb-2 subtitle-1">{{ $t('twofa.backupCodes') }}</div>
    <div class="pl-3 body-2 grey--text text--darken-2">
      {{ $t('twofa.backupCodesText') }}
    </div>
    <div class="pl-3 body-2 grey--text text--darken-2">
      {{ $t('twofa.downloadPrintOrCopy') }}
    </div>
    <v-row class="justify-center align-center pt-3">
      <v-col md="6">
        <v-card>
          <template v-for="(code, index) in backupCodes">
            <v-list-item :key="`item.${index}`">
              <v-list-item-title>
                <b>{{ code }}</b>
              </v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="index < backupCodes.length - 1"
              :key="'div.' + index"
            />
          </template>
        </v-card>
        <v-row justify="space-around" class="pt-5">
          <v-btn @click="download">
            <v-icon small left>mdi-download</v-icon>
            {{ $t('twofa.download') }}
          </v-btn>
          <v-btn v-if="!isMobile" @click="print">
            <v-icon small left>mdi-printer</v-icon>
            {{ $t('twofa.print') }}
          </v-btn>
          <v-btn @click="copy">
            <v-icon small left>mdi-content-copy</v-icon>
            {{ $t('twofa.copy') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { saveAs } from 'file-saver';
import openSnackbar from '../../helpers';

export default {
  data: () => ({
    backupCodes: [],
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },

  methods: {
    getBackupCodes() {
      axios
        .get('twofa/backup-codes')
        .then((response) => {
          this.backupCodes = this.formatCodes(response.data.backupCodes);
        })
        .catch((error) => {
          this.$router.push({ name: 'my-account' });
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },

    formatCodes(codes) {
      return codes.map((code) => code.replace(/(\d{5})(\d{5})/, '$1 $2'));
    },

    download() {
      const codes = this.$i18n.t('twofa.backupCodesFile', this.backupCodes);

      // To handle download btn click in flutter webview
      if (window.flutterTwoFABackupCodesDownloadChannel) {
        const backupCodesStr = encodeURIComponent(codes);
        window.flutterTwoFABackupCodesDownloadChannel.postMessage(
          backupCodesStr,
        );
      } else {
        const blob = new Blob([codes], {
          type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, 'nexta_backup_codes.txt');
      }
      this.$emit('backupCodeSavedByUser');
    },

    print() {
      const a = window.open('', '', 'height=500, width=500');
      a.document.write('<html><body>');
      a.document.write(
        this.$i18n.t('twofa.backupCodesPrint', this.backupCodes),
      );
      a.document.write('</body></html>');
      a.document.close();
      a.print();
      this.$emit('backupCodeSavedByUser');
    },

    async copy() {
      const codes = this.$i18n.t('twofa.backupCodesCopy', this.backupCodes);

      // To handle copy btn click in flutter webview
      if (window.flutterTwoFABackupCodesCopyChannel) {
        const backupCodesStr = encodeURIComponent(codes);
        window.flutterTwoFABackupCodesCopyChannel.postMessage(backupCodesStr);
      } else {
        await this.updateClipboardPromise(codes);
        openSnackbar(this.$t('twofa.copied'), 'success');
      }
      this.$emit('backupCodeSavedByUser');
    },

    async updateClipboardPromise(newClip) {
      // Refer: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
      const result = await navigator.permissions.query({
        name: 'clipboard-write',
      });
      if (result.state === 'granted' || result.state === 'prompt') {
        await navigator.clipboard.writeText(newClip);
      }
    },
  },
};
</script>
