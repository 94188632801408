<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="5" xl="4">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>
                {{ $t('common.appName') }} -
                {{ $t('forgotPassword.forgotPassword') }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                outlined
                color="white"
                @click="
                  $router.push({
                    name: 'login',
                    query: $route.query,
                  })
                "
              >
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $t('login.logIn') }}
              </v-btn>
            </v-toolbar>
            <v-progress-linear
              v-if="sendingMail"
              :indeterminate="true"
              color="pink"
              class="mt-0 mb-0"
              height="6"
            />
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent
              >
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('forgotPassword.enterRegisteredEmail')"
                  prepend-icon="mdi-account"
                  type="email"
                  required
                  autofocus
                  @keyup.enter="submit"
                />
                <v-alert
                  :value="serverResponse.message !== null"
                  :type="serverResponse.type"
                  outlined
                  transition="scale-transition"
                >
                  {{ serverResponse.message }}
                </v-alert>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="isLoginButtonDisabled"
                    color="primary"
                    @click="submit"
                  >
                    {{ $t('forgotPassword.getLink') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <login-footer />
    <RedirectionModal ref="redirectionModal" />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import LoginFooter from '../components/LoginFooter.vue';
import RedirectionModal from '../components/RedirectionModal.vue';

export default {
  components: {
    LoginFooter,
    RedirectionModal,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data: () => ({
    valid: true,
    email: '',
    serverResponse: {
      message: null,
      type: 'error',
    },
    sendingMail: false,
  }),

  computed: {
    rules() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return {
        required: (value) => !!value || this.$t('common.required'),
        email: (value) =>
          emailRegex.test(value) || this.$t('login.emailMustBeValid'),
      };
    },

    isLoginButtonDisabled() {
      if (this.valid === false) {
        return true;
      }
      if (this.serverResponse.type === 'success') {
        return true;
      }
      return false;
    },
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.primary = colors.blue.darken2;
      this.$vuetify.theme.accent = colors.pink.base;
    },

    submit() {
      this.serverResponse.message = null;
      if (this.$refs.form.validate()) {
        this.sendingMail = true;
        axios
          .post('password/email', {
            email: this.email,
          })
          .then((response) => {
            switch (response.data) {
              case 'email_sent':
                this.serverResponse.message = this.$t(
                  'forgotPassword.emailSent',
                );
                this.serverResponse.type = 'success';
                break;
              case 'error_sending_mail':
                this.serverResponse.message = this.$t(
                  'forgotPassword.errorSendingMail',
                );
                this.serverResponse.type = 'error';
                break;
              case 'user_is_not_registered':
                this.serverResponse.message = this.$t(
                  'forgotPassword.userIsNotRegistered',
                );
                this.serverResponse.type = 'error';
                break;
              default:
                break;
            }
          })
          .catch(() => {
            this.serverResponse.message = this.$t(
              'forgotPassword.errorSendingMail',
            );
            this.serverResponse.type = 'error';
          })
          .finally(() => {
            this.sendingMail = false;
          });
      }
    },
  },
};
</script>
