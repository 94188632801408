import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-5 elevation-0"},[_c('div',{staticClass:"pl-3 pb-2 subtitle-1"},[_vm._v(_vm._s(_vm.$t('twofa.enableSms')))]),_c('div',{staticClass:"pl-3 body-2 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('twofa.enableSmsText'))+" ")]),_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{staticClass:"mt-3",attrs:{"md":"6"}},[_c(VForm,{model:{value:(_vm.isContactValid),callback:function ($$v) {_vm.isContactValid=$$v},expression:"isContactValid"}},[_c(VAutocomplete,{ref:"code",attrs:{"label":_vm.$t('twofa.countryCode'),"items":_vm.countryData,"filter":_vm.filterCountries,"outlined":"","item-value":"dialCode","rules":[_vm.rules.required]},on:{"input":function($event){_vm.$refs.code.blur();
            _vm.$refs.num.focus();}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name + ' ')+" "),_c(VSubheader,{staticClass:"pl-2"},[_vm._v(_vm._s('+' + item.dialCode))])]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name + ' ')+" "),_c(VSubheader,{staticClass:"pl-2"},[_vm._v(_vm._s('+' + item.dialCode))])]}}]),model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}}),_c(VTextField,{ref:"num",attrs:{"outlined":"","label":_vm.$t('twofa.contactNum'),"rules":[_vm.rules.required, _vm.rules.num],"autofocus":""},model:{value:(_vm.contactNum),callback:function ($$v) {_vm.contactNum=$$v},expression:"contactNum"}}),(_vm.isSendClicked)?_c(VRow,{staticClass:"pl-3 pb-2 body-2 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('twofa.smsOtpSent'))+_vm._s(_vm.$t('twofa.didNotRecieve'))+" "),_c('resend-otp')],1):_vm._e(),_c(VBtn,{attrs:{"disabled":!_vm.isContactValid || _vm.isSendClicked,"color":"primary"},on:{"click":_vm.sendOtp}},[_vm._v(_vm._s(_vm.$t('twofa.send')))])],1)],1)],1),_c(VRow,{staticClass:"pl-3 pt-3 pb-2 subtitle-1"},[_vm._v(_vm._s(_vm.$t('twofa.enterSmsOtp')))]),_c(VRow,{staticClass:"pl-3 body-2 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('twofa.enterSmsOtpText')))]),_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"md":"6"}},[_c(VOtpInput,{attrs:{"type":"number"},on:{"finish":_vm.setOtp},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('p',{staticClass:"pt-2 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('common.pleaseEnterHalfWidthNumbersOnly'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }