import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"dropbox align-center",attrs:{"cols":"12"}},[_c('form',{attrs:{"method":"post","action":"","enctype":"multipart/form-data","width":"100%"}},[_c('input',{staticClass:"input-file",attrs:{"id":"file","type":"file","name":"users-csv","accept":".csv, text/csv"},on:{"change":_vm.uploadFile}})]),_c('p',{staticClass:"text-center mb-0"},[_c(VIcon,{attrs:{"size":"150","color":"primary"}},[_vm._v("mdi-cloud-upload")])],1),_c('p',{staticClass:"text-center mx-4 mb-3 subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('importUsersModal.chooseOrDrag'))+" ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center mb-0"},[_c('span',{staticClass:"subheading blue--text hyperlink",on:{"click":_vm.downloadSample}},[_vm._v(" "+_vm._s(_vm.$t('importUsersModal.downloadSample'))+" ")])])])],1),(_vm.filename)?[_c(VRow,[_c(VCol,{staticClass:"pb-0",domProps:{"innerHTML":_vm._s(_vm.progressBarTitle)}})],1),_c(VRow,[_c(VCol,[_c(VProgressLinear,{attrs:{"value":_vm.percentCompleted}})],1)],1),(_vm.errorMessage)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"color":"error","border":"left","elevation":"2","colored-border":"","prominent":"","icon":"mdi-alert"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})])],1)],1)],1)],1):_vm._e()]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }