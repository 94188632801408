import Vue from './vue';
import './plugins/axios';
import App from './App.vue';
import './globalAppObject';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import store from './store/index';
import './plugins/vue-analytics';

window.EventBus = new Vue();

Vue.config.productionTip = false;

function renderApp() {
  new Vue({
    router,
    vuetify,
    i18n,
    store,
    render(h) {
      return h(App);
    },
  }).$mount('#app');
}

function bootApp() {
  store.dispatch('getUserInfo').finally(() => {
    renderApp();
  });
}

bootApp();
