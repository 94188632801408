<template>
  <v-main>
    <v-progress-linear
      v-if="verifyingResetToken"
      indeterminate
      height="7"
      color="pink"
    />
    <v-container v-else class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card v-if="isResetTokenValid" class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>
                {{ $t('common.appName') }} -
                {{ $t('resetPassword.resetPassword') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-progress-linear
              v-if="loading"
              :indeterminate="true"
              color="pink"
              class="mt-0 mb-0"
              height="6"
            />
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('login.email')"
                  type="email"
                  required
                  autofocus
                  @keyup.enter="submit"
                />
                <v-text-field
                  v-model="password"
                  :label="$t('userSettings.newPassword')"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.required, rules.minPasswordLength]"
                  :hint="$t('userSettings.min8CharsHint')"
                  required
                  counter
                  @click:append="showPassword = !showPassword"
                />
                <password-strength-meter :password="password" />
                <v-text-field
                  v-model="passwordConfirmation"
                  :label="$t('userSettings.repeatNewPassword')"
                  :append-icon="
                    showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'
                  "
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  :rules="[
                    rules.required,
                    passwordMatch,
                    rules.minPasswordLength,
                  ]"
                  :hint="$t('userSettings.min8CharsHint')"
                  required
                  counter
                  @click:append="
                    showPasswordConfirmation = !showPasswordConfirmation
                  "
                />
                <v-alert
                  :value="serverResponse.message"
                  :type="serverResponse.type"
                  outlined
                  transition="scale-transition"
                >
                  {{ serverResponse.message }}
                </v-alert>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="isLoginButtonDisabled"
                    color="primary"
                    @click="submit"
                  >
                    {{ $t('resetPassword.resetPassword') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card v-else width="800">
            <v-alert outlined type="warning" prominent text>
              <v-row align="center">
                <v-col class="grow">
                  {{ $t('resetPassword.sorryTheLinkHasExpiredOrIsInvalid') }}
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="$router.push({ name: 'forgot' })">
                    {{ $t('resetPassword.tryAgain') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <login-footer />
    <RedirectionModal ref="redirectionModal" />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import LoginFooter from '../components/LoginFooter.vue';
import RedirectionModal from '../components/RedirectionModal.vue';

export default {
  components: {
    LoginFooter,
    RedirectionModal,
    PasswordStrengthMeter: () =>
      import(
        /* webpackChunkName: "password-strength" */ '../components/PasswordStrengthMeter.vue'
      ),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data() {
    return {
      valid: true,
      email: '',
      password: '',
      passwordConfirmation: '',
      token: '',
      isLoginButtonDisabled: false,
      showPassword: false,
      showPasswordConfirmation: false,
      loading: false,
      serverResponse: {
        message: null,
        type: 'error',
      },
      redirectIn: 3,
      interval: null,
      verifyingResetToken: false,
      isResetTokenValid: false,
    };
  },

  computed: {
    rules() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return {
        required: (value) => !!value || this.$t('common.required'),
        email: (value) =>
          emailRegex.test(value) || this.$t('login.emailMustBeValid'),
        minPasswordLength: (value) =>
          (!!value && value.length >= 8) || this.$t('login.min8CharsRule'),
      };
    },
  },

  watch: {
    valid(isValid) {
      if (isValid === false) {
        this.isLoginButtonDisabled = true;
      } else {
        this.isLoginButtonDisabled = false;
      }
    },
  },

  created() {
    this.email = this.$route.query.email;
    this.token = this.$route.query.token;
    this.verifyResetToken();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.primary = colors.blue.darken2;
      this.$vuetify.theme.accent = colors.pink.base;
    },

    verifyResetToken() {
      this.verifyingResetToken = true;
      axios
        .post('password/verify-reset-token', {
          email: this.email,
          token: this.token,
        })
        .then(() => {
          this.isResetTokenValid = true;
        })
        .finally(() => {
          this.verifyingResetToken = false;
        });
    },

    passwordMatch() {
      return (
        this.password === this.passwordConfirmation ||
        this.$t('userSettings.passwordsDontMatch')
      );
    },

    submit() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        axios
          .post('password/reset', {
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            token: this.token,
          })
          .then((response) => {
            if (response.data === 'failed_to_reset_password') {
              this.serverResponse.message = this.$t(
                'resetPassword.errorResettingPassword',
              );
              this.serverResponse.type = 'error';
            } else {
              this.$store.dispatch('storeUser', response.data.user);
              this.showSuccessMessageAndRedirect();
            }
          })
          .catch((error) => {
            this.serverResponse.message = this.$t(
              'resetPassword.errorResettingPassword',
            );
            this.serverResponse.type = 'error';
            console.log(error);
            console.log(error.response);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    showSuccessMessageAndRedirect() {
      this.interval = setInterval(() => this.updateMessageAndRedirect(), 1000);
    },

    updateMessageAndRedirect() {
      if (this.redirectIn === 0) {
        clearInterval(this.interval);
        this.$refs.redirectionModal.open();
        return;
      }
      this.serverResponse.message = this.$t(
        'resetPassword.successAndRedirectIn',
        { seconds: this.redirectIn },
      );
      this.serverResponse.type = 'success';
      this.redirectIn -= 1;
    },
  },
};
</script>
