<template>
  <v-data-table
    :headers="headers"
    :items="whitelistedIps"
    :loading="loading"
    disable-sort
    hide-default-footer
    :no-data-text="$t('IpAddressRestrictionPage.noData')"
  >
    <template #[`item.ip_address`]="{ item }">
      <span v-if="item.prefix_length == 32">{{ item.ip_address }} </span>
      <span v-else> {{ item.ip_address }}/{{ item.prefix_length }} </span>
    </template>

    <template #[`item.actions`]="{ item, index }">
      <TooltipButton
        icon="mdi-delete"
        :tooltip="$t('common.delete')"
        :is-loading="deletedIndexes.includes(index)"
        @click="handleDelete(item, index)"
      />
    </template>

    <template #foot>
      <tr class="ma-2">
        <td width="40%">
          <v-text-field
            ref="ipAddress"
            v-model="ipAddress"
            class="ma-2"
            outlined
            dense
            :placeholder="$t('IpAddressRestrictionPage.ipAddressPlaceholder')"
            :rules="rules"
            :error-messages="errorMessages"
          />
        </td>
        <td width="40%">
          <v-text-field
            v-model="description"
            class="ma-2"
            outlined
            dense
            :placeholder="$t('IpAddressRestrictionPage.descriptionPlaceholder')"
          />
        </td>
        <td style="vertical-align: top" align="center" width="20%">
          <v-btn
            class="mt-2"
            style="border-color: gray"
            height="40"
            :loading="isAdding"
            :disabled="!$refs.ipAddress?.validate()"
            @click="addIP"
          >
            {{ $t('IpAddressRestrictionPage.add') }}
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { deleteIp, storeIp } from '../../api/ipRestriction.api';
import TooltipButton from '../../components/TooltipButton.vue';
import openSnackbar from '../../helpers';

export default {
  components: { TooltipButton },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    whitelistedIps: {
      type: Array,
      default: () => [],
    },

    isIpRestrictionEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ipAddress: '',
      description: '',
      valid: false,
      isAdding: false,
      deletedIndexes: [],
      errorMessages: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('IpAddressRestrictionPage.ipAddress'),
          value: 'ip_address',
          width: '40%',
        },
        {
          text: this.$t('IpAddressRestrictionPage.ipDescription'),
          value: 'description',
          width: '40%',
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          width: '20%',
          sortable: false,
        },
      ];
    },

    rules() {
      const regex =
        /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(\/(?:[1-9]|[1-2][0-9]|3[0-2]))?$/;

      const localhostRegex =
        /^(?!(127\.))(?:[0-9]{1,3}\.){3}[0-9]{1,3}(\/(?:[1-9]|[1-2][0-9]|3[0-2]))?$/;

      return [
        (v) => v !== '' || this.$t('common.required'),
        (v) =>
          !v || regex.test(v) || this.$t('IpAddressRestrictionPage.invalidIp'),
        (v) =>
          !v ||
          localhostRegex.test(v) ||
          this.$t('IpAddressRestrictionPage.localhostIpsAreNotAllowed'),
      ];
    },
  },

  watch: {
    ipAddress() {
      this.errorMessages = [];
    },

    whitelistedIps() {
      this.deletedIndexes = [];
    },
  },

  methods: {
    async addIP() {
      if (!this.$refs.ipAddress.validate()) {
        return;
      }

      this.isAdding = true;
      let [ipAddress, prefixLength] = this.ipAddress.split('/');
      prefixLength = prefixLength ?? 32;
      try {
        await storeIp(ipAddress, prefixLength, this.description);
        openSnackbar(
          this.$t('IpAddressRestrictionPage.successfullyStoreIp'),
          'success',
        );
        this.reset();
        this.$emit('refresh');
      } catch (error) {
        this.handleAddIpErrors(error);
      } finally {
        this.isAdding = false;
        this.$ga.event({
          eventCategory: this.$route.meta.page,
          eventAction: 'adminAddedWhitelistedIp',
        });
      }
    },

    reset() {
      this.ipAddress = '';
      this.description = '';
      this.errorMessages = [];
      this.$refs.ipAddress?.resetValidation();
    },

    async handleDelete(ip, index) {
      this.deletedIndexes.push(index);
      try {
        await deleteIp(ip);
        openSnackbar(
          this.$t('IpAddressRestrictionPage.successfullyDeletedIp'),
          'success',
        );
        this.$emit('refresh');
      } catch (error) {
        this.handleDeleteIpErrors(error);
      } finally {
        this.deletedIndexes = this.deletedIndexes.filter(
          (deletedIndex) => deletedIndex !== index,
        );
        this.$ga.event({
          eventCategory: this.$route.meta.page,
          eventAction: 'adminDeletedWhitelistedIp',
        });
      }
    },

    handleAddIpErrors(error) {
      if (error.response.data === 'duplicate-ip') {
        this.errorMessages = [
          this.$t('IpAddressRestrictionPage.alreadyExists'),
        ];
      } else if (
        error.response.data.message ===
        'The ip address must be a public IP address.'
      ) {
        this.errorMessages = [this.$t('IpAddressRestrictionPage.notPublicIp')];
      } else {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      }
    },

    handleDeleteIpErrors(error) {
      if (error.response.data === 'cannot-delete') {
        openSnackbar(this.$t('IpAddressRestrictionPage.cannotDelete'), 'error');
      } else {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      }
    },
  },
};
</script>
