import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '../router/index';

const logAnalyticsInDevelopment = false;

const isProduction = process.env.NODE_ENV === 'production';

const config = {
  id: 'UA-161810145-1',
  router,
  autoTracking: {
    exception: true,
  },
  debug: {
    enabled: !isProduction && logAnalyticsInDevelopment,
    sendHitTask: isProduction && !logAnalyticsInDevelopment,
  },
};

Vue.use(VueAnalytics, config);
