<template>
  <v-dialog v-model="dialog" max-width="640">
    <v-card>
      <v-card-title class="title">
        {{ $t('TrustedDevicesModal.devicesThatYouTrust') }}
        <v-spacer />
        <v-btn icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              {{
                $t(
                  'TrustedDevicesModal.ifALoginIsDetectedFromTrustedDeviceWeWillNotSendLoginAlert',
                )
              }}
              <br />
              {{
                $t(
                  'TrustedDevicesModal.alsoIfYouHaveEnabled2FAWeWillNotAskForLoginCode',
                )
              }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-layout v-if="isLoading" align-center justify-center column>
                <v-flex row align-center>
                  <v-progress-circular indeterminate color="primary" />
                </v-flex>
              </v-layout>
              <v-card v-else height="420px" class="v-card-scrollable">
                <v-card-text class="v-card__text-scrollable">
                  <p
                    v-if="trustedDevices.length === 0"
                    class="align-center justify-center"
                  >
                    {{
                      $t(
                        'TrustedDevicesModal.currentlyYouHaveNotMarkedAnyDeviceAsTrusted',
                      )
                    }}
                    {{
                      $t(
                        'TrustedDevicesModal.youCanMarkADeviceTrustedFromSuspiciousLoginMail',
                      )
                    }}
                    {{
                      $t(
                        'TrustedDevicesModal.alsoYouCanMarkADeviceTrustedWhenDoing2FA',
                      )
                    }}
                  </p>
                  <v-list v-else>
                    <div
                      v-for="sessionDevice in trustedDevices"
                      :key="sessionDevice.id"
                    >
                      <TrustedDeviceModalListItem
                        :trusted-device="sessionDevice"
                        show-logout-button
                        @refresh="fetchTrustedDevices"
                      />
                      <v-divider />
                    </div>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="text-body-2">
            <v-col>
              {{ $t('Devices.locationsBasedOnIp') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getTrustedDevices } from '@/api/trustedDevices.api';
import TrustedDeviceModalListItem from '@/components/Security/TrustedDevicesModalListItem.vue';

export default {
  components: { TrustedDeviceModalListItem },

  data() {
    return {
      dialog: false,
      isLoading: false,
      trustedDevices: [],
      isLoggingOutAllOtherDevices: false,
    };
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
      this.fetchTrustedDevices();
    },

    close() {
      this.dialog = false;
    },

    reset() {
      this.trustedDevices = [];
    },

    async fetchTrustedDevices() {
      this.isLoading = true;
      const response = await getTrustedDevices();
      this.isLoading = false;
      this.trustedDevices = response.data;
    },
  },
};
</script>

<style scoped>
.v-card-scrollable {
  display: flex !important;
  flex-direction: column;
}

.v-card__text-scrollable {
  flex-grow: 1;
  overflow: auto;
}
</style>
