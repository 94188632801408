import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":_vm.tab === _vm.Tab.SingleAdd,"max-width":_vm.width},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"overflow-x-hidden"},[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v(_vm._s(_vm.$t('usersAddTabs.addSingleUser')))]),_c(VTab,[_vm._v(_vm._s(_vm.$t('usersAddTabs.addMultipleUsersUsingCsv')))])],1)],1),_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"large":"","icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('UserAddEditForm',{attrs:{"dialog":_vm.dialog,"is-editing":false},on:{"user-added":_vm.userAdded,"close":_vm.close}})],1),_c(VTabItem,[_c('import-users-modal',{attrs:{"dialog":_vm.dialog},on:{"user-added":_vm.userAdded}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }