const state = {
  usesSSO: false,
  ssoUrl: null,
  ssoSubdomain: null,
  statusSSOActiveUsers: 'none', // This is 'none' in those cases too where sso is not configured or subscribed
};

const getters = {
  usesSSO: (state) => state.usesSSO,
  ssoUrl: (state) => state.ssoUrl,
  ssoSubdomain: (state) => state.ssoSubdomain,
  statusSSOActiveUsers: (state) => state.statusSSOActiveUsers,
};

const mutations = {
  updateSSOUsage: (state, payload) => {
    state.usesSSO = payload;
  },
  updateSSOUrl: (state, payload) => {
    state.ssoUrl = payload;
  },
  updateSSOSubdomain: (state, payload) => {
    state.ssoSubdomain = payload;
  },
  updateStatusSSOActiveUsers: (state, payload) => {
    state.statusSSOActiveUsers = payload;
  },
};

const actions = {
  updateSSOUsage: ({ commit }, payload) => {
    commit('updateSSOUsage', payload);
  },
  updateSSOUrl: ({ commit }, payload) => {
    commit('updateSSOUrl', payload);
  },
  updateSSOSubdomain: ({ commit }, payload) => {
    commit('updateSSOSubdomain', payload);
  },
  updateStatusSSOActiveUsers: ({ commit }, payload) => {
    commit('updateStatusSSOActiveUsers', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
