import mapOutlookAccessTokenToUser from '../api/outlook.api';

const outlookLogin = {
  computed: {
    isOutlookAddInLogin() {
      return (
        this.$route.query.source === 'o365' &&
        this.$route.query.startURL === 'https://outlook.office.com/'
      );
    },
  },

  data: () => ({
    outlookToken: null,
  }),

  methods: {
    handleOutlookInitialize() {
      if (this.isOutlookAddInLogin) {
        /* global Office */
        Office.onReady(() => {
          Office.context.ui.addHandlerAsync(
            Office.EventType.DialogParentMessageReceived,
            this.onMessageFromParent,
          );
          Office.context.ui.messageParent('give-token', {
            targetOrigin: '*',
          });
        });
      }
    },

    onMessageFromParent(arg) {
      console.log('arg received');
      this.outlookToken = arg.message;
      if (this.isUserAuthenticated) {
        this.handleOutlookLogin();
      }
    },

    async handleOutlookLogin() {
      this.isUserAuthenticated = true;
      if (this.outlookToken !== null) {
        try {
          await mapOutlookAccessTokenToUser(this.outlookToken);

          Office.context.ui.messageParent('token-mapped-successfully', {
            targetOrigin: '*',
          });
        } catch (e) {
          Office.context.ui.messageParent('token-mapping-failed', {
            targetOrigin: '*',
          });
        }
      }
    },
  },
};

export default outlookLogin;
