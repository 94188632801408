<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="grey lighten-4 py-2 title">
        <span v-if="isEditing">
          {{ $t('departmentAddEditModal.editDepartment') }}
        </span>
        <span v-else>
          {{ $t('departmentAddEditModal.addDepartment') }}
        </span>
        <v-spacer />
        <v-btn text icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="name"
            :label="$t('departmentAddEditModal.name')"
            :rules="nameRules"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="!valid"
            color="accent"
            @click="submit"
          >
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { addDepartment, editDepartment } from '../../../api/department.api';
import openSnackbar from '../../../helpers';

export default {
  data() {
    return {
      dialog: false,
      isEditing: false,
      departmentId: '',
      name: '',
      valid: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['departments']),
    nameRules() {
      return [(v) => !!v || this.$t('validation.required')];
    },
  },
  methods: {
    open(department, isEditing = false) {
      this.resetForm();

      this.dialog = true;
      if (isEditing === true) {
        this.isEditing = true;
        this.$nextTick(() => {
          // this.resetForm() is kind of async
          this.populateForm(department);
        });
      }
    },
    close() {
      this.dialog = false;
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.isEditing = false;
      this.departmentId = '';
      this.name = '';
      this.valid = false;
    },
    populateForm(department) {
      this.departmentId = department.id;
      this.name = department.name;
    },
    submit() {
      this.loading = true;
      if (this.isEditing === true) {
        this.submitAsEditForm();
      } else {
        this.submitAsAddForm();
      }
    },
    async submitAsAddForm() {
      try {
        await addDepartment(this.name);
        this.$emit('department-added');
        openSnackbar(
          this.$t('departmentAddEditModal.addedSuccessfully'),
          'success',
        );
      } catch (error) {
        const errorMessage =
          error.response.status === 422
            ? this.$t('departmentAddEditModal.departmentNameAlreadyExists')
            : this.$t('departmentAddEditModal.errorAdding');
        openSnackbar(errorMessage, 'error');
      }
      this.loading = false;
      this.close();
    },
    async submitAsEditForm() {
      try {
        await editDepartment(this.departmentId, this.name);
        this.$emit('department-edited');
        openSnackbar(
          this.$t('departmentAddEditModal.editedSuccessfully'),
          'success',
        );
      } catch (error) {
        const errorMessage =
          error.response.status === 422
            ? this.$t('departmentAddEditModal.departmentNameAlreadyExists')
            : this.$t('departmentAddEditModal.errorEditing');
        openSnackbar(errorMessage, 'error');
      }
      this.loading = false;
      this.close();
    },
  },
};
</script>
