<template>
  <div :class="[isActive ? 'link' : 'disabledLink']" @click="resendOtp">
    {{ $t('otp.resendOtp') }}
    <span v-if="!isActive">({{ '00:' + remainingTimeDisplay }})</span>
  </div>
</template>

<script>
import openSnackbar from '../../helpers';

export default {
  data: () => ({
    isActive: true,
    remainingTime: 0,
    finishTime: 0,
  }),

  computed: {
    url() {
      if (this.$route.meta.requiresAuth) {
        return '/twofa/sms/otp/resend';
      }
      return '/otp/resend';
    },

    remainingTimeDisplay() {
      return this.remainingTime > 9
        ? this.remainingTime
        : `0${this.remainingTime}`;
    },
  },

  methods: {
    resendOtp() {
      axios
        .post(this.url)
        .then(() => {
          openSnackbar(this.$t('otp.otpSent'), 'success');
          this.showTimer(parseInt(Date.now() / 1000, 10) + 60);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message === 'Too Many Attempts.') {
            this.showTimer(error.response.headers['x-ratelimit-reset']);
            return;
          }
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },

    showTimer(finishTime) {
      this.remainingTime = finishTime - parseInt(Date.now() / 1000, 10);
      this.isActive = false;
      const timer = window.setInterval(() => {
        this.remainingTime -= 1;
        this.terminateIfCountdownReachesZero(timer);
      }, 1000);
    },

    terminateIfCountdownReachesZero(timer) {
      if (this.remainingTime <= 0) {
        this.isActive = true;
        window.clearInterval(timer);
      }
    },
  },
};
</script>

<style>
.link {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}

.disabledLink {
  color: #dddddd;
  text-decoration: underline;
  cursor: not-allowed;
}
</style>
