<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="grey lighten-4 py-2 title">
        <span>
          {{ $t('userAddEditModal.editUser') }}
        </span>
        <v-spacer />
        <v-btn text icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <UserAddEditForm
        :dialog="dialog"
        :is-editing="true"
        :user="user"
        :is-deactivated="isDeactivated"
        @user-edited="userEdited"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import UserAddEditForm from '../UserAddEditForm.vue';

export default {
  components: {
    UserAddEditForm,
  },
  data() {
    return {
      dialog: false,
      user: null,
      isDeactivated: false,
    };
  },
  methods: {
    open(user, isDeactivated = false) {
      this.user = user;
      this.isDeactivated = isDeactivated;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.user = null;
      this.isDeactivated = false;
    },
    userEdited() {
      this.close();
      this.$emit('user-edited');
    },
  },
};
</script>
