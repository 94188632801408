import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{attrs:{"lines":"three"}},[_c(VListItemAction,[_c('DeviceModalListItemDeviceIcon',{attrs:{"agent":_vm.sessionDevice.agent}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.getDevice(_vm.sessionDevice.agent))+"・"+_vm._s(_vm.getApp(_vm.sessionDevice.agent))+" ")]),_c(VListItemSubtitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getLocation(_vm.sessionDevice.location))+"・"+_vm._s(_vm.getTimeFromNow(_vm.sessionDevice.lastActivity))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('SessionDevicesModalListItem.lastActiveAt', { timestamp: _vm.formatDateTime(_vm.sessionDevice.lastActivity), })))])])],1)],1),(_vm.showLogoutButton)?_c(VListItemAction,[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-0",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2635371260)},[_c(VList,[_c(VListItem,{on:{"click":_vm.confirmLogOut}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('SessionDevicesModalListItem.logOut'))+" ")])],1)],1)],1)],1):_vm._e(),_c('Confirm',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }