<template>
  <v-card class="mb-5 elevation-0">
    <div class="pl-3 pb-2 subtitle-1">{{ $t('twofa.enableSms') }}</div>
    <div class="pl-3 body-2 grey--text text--darken-2">
      {{ $t('twofa.enableSmsText') }}
    </div>
    <v-row justify="center" align="center">
      <v-col md="6" class="mt-3">
        <v-form v-model="isContactValid">
          <v-autocomplete
            ref="code"
            v-model="countryCode"
            :label="$t('twofa.countryCode')"
            :items="countryData"
            :filter="filterCountries"
            outlined
            item-value="dialCode"
            :rules="[rules.required]"
            @input="
              $refs.code.blur();
              $refs.num.focus();
            "
          >
            <template #item="{ item }">
              {{ item.name + ' ' }}
              <v-subheader class="pl-2">{{ '+' + item.dialCode }}</v-subheader>
            </template>
            <template #selection="{ item }">
              {{ item.name + ' ' }}
              <v-subheader class="pl-2">{{ '+' + item.dialCode }}</v-subheader>
            </template>
          </v-autocomplete>
          <v-text-field
            ref="num"
            v-model="contactNum"
            outlined
            :label="$t('twofa.contactNum')"
            :rules="[rules.required, rules.num]"
            autofocus
          />
          <v-row
            v-if="isSendClicked"
            class="pl-3 pb-2 body-2 grey--text text--darken-2"
          >
            {{ $t('twofa.smsOtpSent') }}{{ $t('twofa.didNotRecieve') }}
            <resend-otp />
          </v-row>
          <v-btn
            :disabled="!isContactValid || isSendClicked"
            color="primary"
            @click="sendOtp"
            >{{ $t('twofa.send') }}</v-btn
          >
        </v-form>
      </v-col>
    </v-row>

    <v-row class="pl-3 pt-3 pb-2 subtitle-1">{{
      $t('twofa.enterSmsOtp')
    }}</v-row>
    <v-row class="pl-3 body-2 grey--text text--darken-2">{{
      $t('twofa.enterSmsOtpText')
    }}</v-row>

    <v-row justify="center" align="center">
      <v-col md="6">
        <v-otp-input v-model="otp" type="number" @finish="setOtp" />
        <p class="pt-2 pl-2">
          {{ $t('common.pleaseEnterHalfWidthNumbersOnly') }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import { countryData } from '../../../mixins/countryData';
import ResendOtp from '../../Otp/ResendOtp.vue';
import openSnackbar from '../../../helpers';

export default {
  components: {
    ResendOtp,
  },
  mixins: [countryData],
  data: () => ({
    isContactValid: false,
    isSendClicked: false,
    countryCode: '81',
    contactNum: '',
    otp: '',
  }),
  computed: {
    rules() {
      return {
        required: (v) => !!v || this.$t('common.required'),
        num: () => this.isValidNum() || this.$t('twofa.invalidMobile'),
      };
    },
    contact() {
      return `+${this.countryCode}${this.contactNum}`; // e164 format
    },
  },
  methods: {
    countryCodeText(country) {
      return `${country.name} +${country.dialCode}`;
    },
    filterCountries(item, queryText) {
      const escapedQuery = queryText.replace(
        /[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g,
        '\\$&',
      );
      const regex = new RegExp(escapedQuery, 'i');
      return regex.test(`${item.name} +${item.dialCode}`);
    },
    isValidNum() {
      const parsedPhoneNumber = parsePhoneNumber(this.contact);
      return parsedPhoneNumber.valid && parsedPhoneNumber.typeIsMobile;
    },
    sendOtp() {
      this.isSendClicked = true;
      const phoneNum = parsePhoneNumber(this.contact);
      axios
        .post('/twofa/sms/otp', {
          contact: JSON.stringify({
            num: phoneNum.number.e164,
          }),
        })
        .catch((error) => {
          this.$router.push({ name: 'my-account' });
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },
    setOtp() {
      this.$emit('set-otp', this.otp);
    },
  },
};
</script>

<style></style>
