import getTenantFromSubdomain from '../../api/tenant.api';

const state = {
  currentTenantFromSubdomain: '',
};

const getters = {
  currentTenantFromSubdomain: (state) => state.currentTenantFromSubdomain,
};

const mutations = {
  updateCurrentTenantFromSubdomain: (state, payload) => {
    state.currentTenantFromSubdomain = payload;
  },
};

const actions = {
  fetchTenantForTenantManagerUsingSubdomain: async ({ commit }, payload) => {
    try {
      const response = await getTenantFromSubdomain(payload);
      commit('updateCurrentTenantFromSubdomain', response.data);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
