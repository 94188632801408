<template>
  <v-dialog v-model="dialog" width="950px" persistent>
    <v-card class="overflow-x-hidden">
      <v-card-title class="grey lighten-4 px-3 title">
        <span>
          {{ $t('editByImportModal.title') }}
        </span>
        <v-spacer />
        <v-btn large icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="pt-0">
        <v-row>
          <v-col cols="12" class="dropbox align-center">
            <form
              method="post"
              action=""
              enctype="multipart/form-data"
              width="100%"
            >
              <input
                id="file"
                class="input-file"
                type="file"
                name="users-csv"
                accept=".csv, text/csv"
                @change="uploadFile"
              />
            </form>
            <p class="text-center mb-0">
              <v-icon size="150" color="primary">mdi-cloud-upload</v-icon>
            </p>
            <p class="text-center mx-4 mb-3 subtitle-1">
              {{ $t('editByImportModal.chooseOrDrag') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="text-center mb-0">
              <span
                class="subheading blue--text hyperlink"
                @click="downloadSample"
              >
                {{ $t('editByImportModal.downloadSample') }}
              </span>
            </p>
          </v-col>
        </v-row>
        <template v-if="filename">
          <v-row>
            <v-col class="pb-0">
              {{ progressBarTitle }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-progress-linear :value="percentCompleted" />
            </v-col>
          </v-row>
          <v-row v-if="errorMessage">
            <v-col cols="12">
              <v-alert
                color="error"
                border="left"
                elevation="2"
                colored-border
                prominent
                icon="mdi-alert"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <span v-html="errorMessage" />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <import-logs ref="importLogs" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  downloadSampleForAddOrUpdateUsersFromCSV,
  updateUsersFromCsv,
} from '../../../../../api/admin.api';
import ImportLogs from '../../ImportLogs.vue';

export default {
  components: {
    ImportLogs,
  },
  data() {
    return {
      dialog: false,
      filename: '',
      errorMessage: null,
      percentCompleted: 0,
      isImportSuccessful: false,
    };
  },
  computed: {
    progressBarTitle() {
      let title = 'importUsersModal.uploadingFile';
      if (this.errorMessage) {
        title = 'importUsersModal.uploadedFailed';
      } else if (this.isImportSuccessful) {
        title = 'importUsersModal.uploadedFile';
      }
      return this.$t(title, { filename: this.filename });
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$nextTick(() => {
        this.reset();
      });
    },
    close() {
      this.dialog = false;
      this.closeLogs();
    },
    reset() {
      this.filename = '';
      this.errorMessage = null;
      this.percentCompleted = 0;
      this.isImportSuccessful = false;
    },
    openLogs() {
      this.$refs.importLogs.open();
    },
    closeLogs() {
      this.$refs.importLogs.close();
    },
    async uploadFile(event) {
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'uploadEditByImportFile',
        eventValue: 1,
      });
      this.percentCompleted = 0;
      this.isImportSuccessful = false;
      this.errorMessage = null;
      const csvFile = event.target.files[0];
      this.filename = csvFile.name;
      event.target.value = null;

      if (csvFile.size / 1024 / 1024 > 50) {
        this.showFileSizeExceededError();
        return;
      }

      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        this.percentCompleted = percentCompleted;
      };

      try {
        await updateUsersFromCsv(csvFile, onUploadProgress);
        this.$emit('user-edited');
        this.isImportSuccessful = true;
        this.openLogs();
      } catch (error) {
        const errorData = error.response.data;
        if (
          typeof errorData.error !== 'undefined' &&
          errorData.error === 'unexpected_format'
        ) {
          this.$ga.event({
            eventCategory: this.$route.meta.page,
            eventAction: 'importErrorOccurred',
            eventValue: 1,
          });
          this.showHeaderColumnsMismatchError(errorData);
        } else if (errorData === 'incorrect-encoding') {
          this.showIncorrectEncodingError();
        } else if (errorData === 'quota_finished') {
          this.showQuotaFinishedError();
        } else if (errorData.error === 'no_data') {
          this.showNoDataError();
        } else if (
          typeof errorData.message !== 'undefined' &&
          errorData.message.indexOf('invalid') !== -1
        ) {
          if (
            errorData.errors.csvFile[0] ===
            'The csv file must be a file of type: csv, txt.'
          ) {
            this.showInvalidMimeTypeError();
          } else {
            this.showInvalidCsvFileError();
          }
        } else {
          this.showSomeErrorOccured();
        }
      } finally {
        this.percentCompleted = 100;
      }
    },
    showFileSizeExceededError() {
      this.errorMessage = this.$t('importCsvProgress.fileTooLarge');
    },
    showHeaderColumnsMismatchError(errorData) {
      this.errorMessage = this.$t('importCsvProgress.headerColumnsDoNotMatch');
      EventBus.$emit('import_unexpected_format', errorData);
    },
    showIncorrectEncodingError() {
      this.errorMessage = this.$t('importCsvProgress.incorrectEncoding');
    },
    showNoDataError() {
      this.errorMessage = this.$t(
        'importCsvProgress.noUserDataWasDetectedInTheFile',
      );
    },
    showQuotaFinishedError() {
      this.errorMessage = this.$t('userAddEditModal.quotaFinished');
    },
    showInvalidMimeTypeError() {
      this.errorMessage = this.$t('importCsvProgress.incorrectMimeType');
    },
    showInvalidCsvFileError() {
      this.errorMessage = this.$t(
        'importCsvProgress.pleaseUploadAValidCsvFile',
      );
    },
    showSomeErrorOccured() {
      this.errorMessage = this.$t('importCsvProgress.someErrorOccured');
    },
    async downloadSample() {
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'downloadEditByImportSample',
        eventValue: 1,
      });

      const response = await downloadSampleForAddOrUpdateUsersFromCSV();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users-import-sample.csv');
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  position: relative;
  /*background: lightcyan !important;*/
}

.dropbox:hover {
  background: lightblue !important;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

.fixed-height--scrollable {
  max-height: 300px;
  overflow-y: auto;
}

.hyperlink {
  cursor: pointer;
}
</style>
