<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card v-if="showLinkHasExpired" width="800">
            <v-alert outlined type="warning" prominent text>
              <v-row align="center">
                <v-col class="grow">
                  {{ $t('register.linkHasExpiredToJoinNexta') }}
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
          <v-card v-else class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>
                {{ $t('common.appName') }} -
                {{ $t('register.title') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-progress-linear
              v-if="isRegistering"
              :indeterminate="true"
              color="pink"
              class="mt-0 mb-0"
              height="6"
            />
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <!-- refer: https://stackoverflow.com/a/22694173/6402452 -->
                <input style="display: none" />
                <v-col cols="12">
                  <v-text-field
                    v-model="newPassword"
                    :label="$t('register.createPassword')"
                    autocomplete="new-password"
                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show1 ? 'text' : 'password'"
                    :rules="[rules.required, rules.minPasswordLength]"
                    :hint="$t('userSettings.min8CharsHint')"
                    required
                    @click:append="show1 = !show1"
                  />
                  <password-strength-meter :password="newPassword" />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newPasswordConfirmation"
                    :label="$t('register.confirmPassword')"
                    :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show2 ? 'text' : 'password'"
                    :rules="[rules.required, rules.passwordMatch]"
                    required
                    @click:append="show2 = !show2"
                  />
                </v-col>
                <v-alert
                  :value="!!errorMessage"
                  outlined
                  transition="scale-transition"
                  type="error"
                  >{{ errorMessage }}</v-alert
                >

                <v-card-actions>
                  <v-btn
                    :disabled="isJoinButtonDisabled"
                    color="primary"
                    @click="submit"
                    >{{ $t('register.join') }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <login-footer />
    <RedirectionModal ref="redirectionModal" />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import LoginFooter from '../components/LoginFooter.vue';
import RedirectionModal from '../components/RedirectionModal.vue';

export default {
  components: {
    LoginFooter,
    RedirectionModal,
    PasswordStrengthMeter: () =>
      import(
        /* webpackChunkName: "password-strength" */ '../components/PasswordStrengthMeter.vue'
      ),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },
  data() {
    return {
      newPassword: '',
      newPasswordConfirmation: '',
      valid: true,
      isRegistering: false,
      isJoinButtonDisabled: false,
      show1: false,
      show2: false,
      errorMessage: '',
      showLinkHasExpired: false,
    };
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('common.required'),
        minPasswordLength: (value) =>
          value.length >= 8 || this.$t('userSettings.min8CharsRule'),
        passwordMatch: (value) =>
          value === this.newPassword ||
          this.$t('userSettings.passwordsDontMatch'),
      };
    },
  },
  watch: {
    valid(isValid) {
      this.isJoinButtonDisabled = !isValid;
    },
  },
  created() {
    if (this.hasLinkExpired()) {
      this.showLinkHasExpired = true;
    }
  },
  methods: {
    hasLinkExpired() {
      const urlParams = new URLSearchParams(window.location.search);
      let expiresAt = urlParams.get('expires');
      expiresAt = parseInt(expiresAt, 10) * 1000;
      const now = Date.now();
      return now > expiresAt;
    },
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.blue.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },
    submit() {
      this.errorMessage = null;
      if (this.$refs.form.validate()) {
        this.isRegistering = true;
        const signature =
          this.$route.query.signature !== undefined
            ? this.$route.query.signature
            : this.$route.query['amp;signature'];
        const postUrl = `users/register/${this.$route.params.email}?expires=${this.$route.query.expires}&signature=${signature}`;
        axios
          .post(postUrl, {
            password: this.newPassword,
            password_confirmation: this.newPasswordConfirmation,
          })
          .then((response) => {
            this.$store.dispatch('storeUser', response.data.user);
            this.$refs.redirectionModal.open();
          })
          .catch((error) => {
            this.isRegistering = false;
            this.isJoinButtonDisabled = true;
            switch (error.response.data.message) {
              case 'already-registered':
                this.errorMessage = this.$t('register.alreadyRegistered');
                return;
              case 'Invalid signature.':
                this.errorMessage = this.$t('register.urlTampered');
                return;
              case 'account-deleted':
                this.errorMessage = this.$t('register.accountDeletedbyAdmin');
                return;
              default:
                this.errorMessage = this.$t('common.somethingWentWrong');
                throw error;
            }
          });
      }
    },
  },
};
</script>
