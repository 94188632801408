<template>
  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <v-card-title class="grey lighten-4 py-2 title">
        {{ $t('importErrorDetailsModal.importError') }}
        <v-spacer />
        <v-btn icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('importErrorDetailsModal.expectedHeader') }}
                </th>
                <th class="text-left">
                  {{ $t('importErrorDetailsModal.actualHeader') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row in rows"
                :key="row[0] + row[1]"
                :class="{ 'highlight-row': row[0] !== row[1] }"
              >
                <td>{{ row[0] }}</td>
                <td>{{ row[1] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      rows: [],
    };
  },
  created() {
    EventBus.$on('import_unexpected_format', this.open);
  },
  methods: {
    open(error) {
      this.reset();
      this.dialog = true;
      this.computeRows(error);
    },
    reset() {
      this.rows = [];
    },
    close() {
      this.dialog = false;
    },
    computeRows(error) {
      const expected = error.expected_format;
      const given = error.given_format;
      let i = 0;
      for (; i < expected.length; i += 1) {
        const row = [];
        row.push(expected[i]);
        if (given[i] !== 'undefined') {
          row.push(given[i]);
        } else {
          row.push(null);
        }
        this.rows.push(row);
      }
      if (given.length > i) {
        for (; i < given.length; i += 1) {
          const row = [];
          row.push(null);
          row.push(given[i]);
          this.rows.push(row);
        }
      }
    },
  },
};
</script>

<style scoped>
.highlight-row {
  background-color: #f5b5b5;
}
</style>
