<template>
  <v-dialog v-model="isChangePasswordOpen" persistent max-width="500">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="grey lighten-4 py-2 px-3 title">
          {{ $t('userSettings.changePassword') }}
          <v-spacer />
          <v-btn icon color="grey" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="shouldChangePassword">
          {{ $t('userSettings.suggestChangePassword') }}
        </v-card-text>
        <v-alert
          v-if="isWeakPasswordChangeRequired"
          outlined
          type="warning"
          prominent
          border="left"
          class="ma-4"
        >
          {{ $t('resetPassword.weakPasswordChange') }}
        </v-alert>
        <v-container :class="{ 'pt-0': shouldChangePassword }">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="currentPassword"
                :label="$t('userSettings.currentPassword')"
                :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                :type="show1 ? 'text' : 'password'"
                :rules="[rules.required]"
                :error-messages="errorMessages"
                required
                @click:append="show1 = !show1"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newPassword"
                :label="$t('userSettings.newPassword')"
                :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                :type="show2 ? 'text' : 'password'"
                :rules="[
                  rules.required,
                  rules.minPasswordLength,
                  rules.minStrength,
                ]"
                required
                @click:append="show2 = !show2"
              />
              <password-strength-meter
                v-if="isChangePasswordOpen"
                :password="newPassword"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newPasswordConfirmation"
                :label="$t('userSettings.repeatNewPassword')"
                :append-icon="show3 ? 'mdi-eye-off' : 'mdi-eye'"
                :type="show3 ? 'text' : 'password'"
                :rules="[
                  rules.required,
                  passwordMatch,
                  rules.minPasswordLength,
                ]"
                :hint="$t('userSettings.min8CharsHint')"
                required
                @click:append="show3 = !show3"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pb-4">
          <v-spacer />
          <v-btn
            v-if="isWeakPasswordChangeRequired"
            text
            outlined
            @click="close"
          >
            {{ $t('common.maybeLater') }}
          </v-btn>
          <v-btn v-else text outlined @click="close">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            :disabled="shouldDisableSubmit"
            color="primary"
            @click="submitPasswordChangeForm"
          >
            {{ $t('common.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import openSnackbar from '../helpers';

const zxcvbn = require('zxcvbn');

export default {
  components: {
    PasswordStrengthMeter: () =>
      import(
        /* webpackChunkName: "password-strength" */ './PasswordStrengthMeter.vue'
      ),
  },
  data: () => ({
    valid: false,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    show1: false,
    show2: false,
    show3: false,
    errorMessages: [],
  }),
  computed: {
    ...mapGetters([
      'shouldChangePassword',
      'isChangePasswordOpen',
      'isWeakPasswordChangeRequired',
    ]),
    rules() {
      return {
        required: (value) => !!value || this.$t('common.required'),
        minPasswordLength: (value) =>
          (!!value && value.length >= 8) ||
          this.$t('userSettings.min8CharsRule'),
        minStrength: (value) =>
          (!!value && this.isPasswordStrengthAcceptable()) ||
          this.$t('userSettings.minDifficulty'),
      };
    },
    shouldDisableSubmit() {
      return (
        !this.valid ||
        this.newPassword.length < 8 ||
        this.newPassword !== this.newPasswordConfirmation
      );
    },
  },
  watch: {
    currentPassword() {
      this.errorMessages = [];
    },
    isChangePasswordOpen(newVal) {
      if (newVal === true) {
        this.resetForm();
      }
    },
  },
  methods: {
    isPasswordStrengthAcceptable() {
      const response = zxcvbn(this.newPassword);
      return response.score >= 2;
    },
    close() {
      this.$store.dispatch('closeChangePasswordModal');
      this.$store.dispatch('resetShouldChangePassword');

      if (this.isWeakPasswordChangeRequired) {
        this.$store.dispatch('setIsRedirectionModalOpen');
      }
    },
    passwordMatch() {
      return (
        this.newPassword === this.newPasswordConfirmation ||
        this.$t('userSettings.passwordsDontMatch')
      );
    },
    submitPasswordChangeForm() {
      axios
        .patch('/password', {
          current_password: this.currentPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.newPasswordConfirmation,
        })
        .then((response) => {
          switch (response.data) {
            case 'wrong_password':
              this.errorMessages = [this.$t('userSettings.currentPwdWrong')];
              openSnackbar(this.$t('userSettings.currentPwdWrong'), 'error');
              break;
            case 'password_changed':
              this.$store.dispatch('passwordChanged');
              openSnackbar(this.$t('userSettings.pwdChanged'), 'success');
              if (this.isWeakPasswordChangeRequired) {
                this.$store.dispatch('setIsRedirectionModalOpen');
              }
              this.close();
              break;
            default:
              openSnackbar(this.$t('userSettings.pwdChangeFailed'), 'error');
              break;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.currentPassword = '';
      this.newPassword = '';
      this.newPasswordConfirmation = '';
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
  },
};
</script>
