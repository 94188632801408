<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12" class="dropbox align-center">
          <form
            method="post"
            action=""
            enctype="multipart/form-data"
            width="100%"
          >
            <input
              id="file"
              class="input-file"
              type="file"
              name="users-csv"
              accept=".csv, text/csv"
              @change="uploadFile"
            />
          </form>
          <p class="text-center mb-0">
            <v-icon size="150" color="primary">mdi-cloud-upload</v-icon>
          </p>
          <p class="text-center mx-4 mb-3 subtitle-1">
            {{ $t('importUsersModal.chooseOrDrag') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="text-center mb-0">
            <span
              class="subheading blue--text hyperlink"
              @click="downloadSample"
            >
              {{ $t('importUsersModal.downloadSample') }}
            </span>
          </p>
        </v-col>
      </v-row>
      <template v-if="filename">
        <v-row>
          <v-col class="pb-0" v-html="progressBarTitle"> </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-progress-linear :value="percentCompleted" />
          </v-col>
        </v-row>
        <v-row v-if="errorMessage">
          <v-col cols="12">
            <v-alert
              color="error"
              border="left"
              elevation="2"
              colored-border
              prominent
              icon="mdi-alert"
            >
              <v-row align="center">
                <v-col class="grow">
                  <span v-html="errorMessage" />
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import {
  downloadSampleForAddOrUpdateUsersFromCSV,
  addUsersFromCsv,
} from '../../../../api/admin.api';
import openSnackbar from '../../../../helpers';

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filename: '',
      percentCompleted: 0,
      errorMessage: null,
      isImportSuccessful: false,
    };
  },
  computed: {
    progressBarTitle() {
      let title = 'importUsersModal.uploadingFile';
      if (this.errorMessage) {
        title = 'importUsersModal.uploadedFailed';
      } else if (this.isImportSuccessful) {
        title = 'importUsersModal.uploadedFile';
      }
      return this.$t(title, { filename: this.filename });
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.reset();
        });
      }
    },
  },
  methods: {
    open() {
      this.$nextTick(this.reset);
    },
    reset() {
      document.getElementById('file').value = '';
      this.percentCompleted = 0;
      this.isImportSuccessful = false;
      this.filename = '';
      this.errorMessage = null;
    },
    async uploadFile(event) {
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'importFile',
        eventValue: 1,
      });
      this.percentCompleted = 0;
      this.isImportSuccessful = false;
      this.errorMessage = null;

      const csvFile = event.target.files[0];
      this.filename = csvFile.name;
      event.target.value = null;

      if (csvFile.size / 1024 / 1024 > 50) {
        this.showFileSizeExceededError();
        return;
      }

      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        this.percentCompleted = percentCompleted;
      };

      try {
        await addUsersFromCsv(csvFile, onUploadProgress);
        this.isImportSuccessful = true;
        openSnackbar(this.$t('importUsersModal.addedUsers'), 'success');
      } catch (error) {
        const errorData = error.response.data;
        if (
          typeof errorData.error !== 'undefined' &&
          errorData.error === 'unexpected_format'
        ) {
          this.$ga.event({
            eventCategory: this.$route.meta.page,
            eventAction: 'importErrorOccured',
            eventValue: 1,
          });
          this.showHeaderColumnsMismatchError(errorData);
        } else if (errorData === 'incorrect-encoding') {
          this.showIncorrectEncodingError();
        } else if (errorData === 'quota_finished') {
          this.showQuotaFinishedError();
        } else if (
          typeof errorData.message !== 'undefined' &&
          errorData.message.indexOf('invalid') !== -1
        ) {
          if (
            errorData.errors.csvFile[0] ===
            'The csv file must be a file of type: csv, txt.'
          ) {
            this.showInvalidMimeTypeError();
          } else {
            this.showInvalidCsvFileError();
          }
        } else {
          this.showSomeErrorOccured();
        }
      } finally {
        this.percentCompleted = 100;
        this.$emit('user-added');
      }
    },
    showFileSizeExceededError() {
      this.errorMessage = this.$t('importCsvProgress.fileTooLarge');
    },
    showHeaderColumnsMismatchError(errorData) {
      this.errorMessage = this.$t('importCsvProgress.headerColumnsDoNotMatch');
      EventBus.$emit('import_unexpected_format', errorData);
    },
    showIncorrectEncodingError() {
      this.errorMessage = this.$t('importCsvProgress.incorrectEncoding');
    },
    showQuotaFinishedError() {
      this.errorMessage = this.$t('userAddEditModal.quotaFinished');
    },
    showInvalidMimeTypeError() {
      this.errorMessage = this.$t('importCsvProgress.incorrectMimeType');
    },
    showInvalidCsvFileError() {
      this.errorMessage = this.$t(
        'importCsvProgress.pleaseUploadAValidCsvFile',
      );
    },
    showSomeErrorOccured() {
      this.errorMessage = this.$t('importCsvProgress.someErrorOccured');
    },
    downloadSample() {
      downloadSampleForAddOrUpdateUsersFromCSV().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users-import-sample.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  position: relative;
  /*background: lightcyan !important;*/
}

.dropbox:hover {
  background: lightblue !important;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

.fixed-height--scrollable {
  max-height: 300px;
  overflow-y: auto;
}

.hyperlink {
  cursor: pointer;
}
</style>
