import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"fill-height"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"xs":"12","sm":"8","md":"6","lg":"5","xl":"4"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$t('backup.enterBackupCode'))+" ")])],1),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":_vm.$t('backup.enterBackupCode'),"rules":[_vm.rules.required],"hint":_vm.$t('backup.backupCodeHint'),"persistent-hint":""},model:{value:(_vm.backupCode),callback:function ($$v) {_vm.backupCode=$$v},expression:"backupCode"}}),_c(VAlert,{staticClass:"mt-4",attrs:{"value":!!_vm.errorMessage,"outlined":"","transition":"scale-transition","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isSubmitDisabled,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('common.submit'))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('login-footer'),_c('RedirectionModal',{ref:"redirectionModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }