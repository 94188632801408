import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"small":"","rounded":"","outlined":"","color":"accent"}},on),[_c(VIcon,{staticClass:"pr-2"},[_vm._v(" mdi-help-circle ")]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('SecureAccountDialog.letsSecure'))+" ")]),_c(VDivider),_c(VCardText,{staticClass:"mt-4 text--primary"},[_vm._v(" "+_vm._s(_vm.$t('SecureAccountDialog.recommendChangePassword'))+" ")]),_c(VCardText,{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t('SecureAccountDialog.effectOfChangePassword'))+" ")]),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"accent","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c(VBtn,{attrs:{"color":"accent"},on:{"click":_vm.openChangePasswordModal}},[_vm._v(" "+_vm._s(_vm.$t('SecureAccountDialog.changePassword'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }