<template>
  <v-main>
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="6" sm="8">
          <v-card>
            <v-card-title class="grey lighten-4 py-2 px-3 title">
              {{ $t('verifyPassword.title') }}
              <v-spacer />
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent>
                <v-text-field
                  v-model="password"
                  :label="$t('verifyPassword.password')"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.required]"
                  required
                  autocomplete="new-password"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="verifyPassword"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text outlined @click="cancel">
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn :disabled="!valid" color="primary" @click="verifyPassword">
                {{ $t('common.submit') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import openSnackbar from '../helpers';

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },
  data: () => ({
    valid: false,
    password: '',
    showPassword: false,
    fromPath: '',
  }),
  computed: {
    rules() {
      return {
        required: (v) => !!v || this.$t('common.required'),
      };
    },
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.cyan.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },
    verifyPassword() {
      axios
        .post('/password/verify', {
          password: this.password,
          redirect: this.$route.query.redirect
            ? this.$route.query.redirect
            : 'twofa',
        })
        .then(() => {
          this.$router.push({
            name: this.$route.query.redirect
              ? this.$route.query.redirect
              : 'twofa',
          });
        })
        .catch((error) => {
          if (error.response.data.message === 'wrong_password') {
            openSnackbar(this.$t('verifyPassword.wrongPassword'), 'error');
          } else {
            openSnackbar(this.$t('common.somethingWentWrong'), 'error');
            throw error;
          }
        });
    },
    cancel() {
      this.$router.push({ name: 'my-account' });
    },
  },
};
</script>
