import httpClient from './httpClient';

const getAllIps = () => httpClient.get('/admin/whitelisted-ips');

const storeIp = (ipAddress, prefixLength, description) =>
  httpClient.post('/admin/whitelisted-ips', {
    ipAddress,
    prefixLength,
    description,
  });

const deleteIp = (ip) => httpClient.delete(`/admin/whitelisted-ips/${ip.id}`);

const enableIPRestriction = () =>
  httpClient.patch('/admin/enable-ip-restriction');

const disableIPRestriction = () =>
  httpClient.patch('/admin/disable-ip-restriction');

export {
  getAllIps,
  storeIp,
  deleteIp,
  enableIPRestriction,
  disableIPRestriction,
};
