import httpClient from './httpClient';

const END_POINT = '/saml2';

const checkIfThisUserHasSSOForCurrentTenant = (email) =>
  httpClient.get(`${END_POINT}/check-using-email-with-subdomain`, {
    params: {
      email,
    },
  });

const checkIfThisUserHasSSOForAnyTenant = (email) =>
  httpClient.get(`${END_POINT}/check-using-email`, {
    params: {
      email,
    },
  });

export {
  checkIfThisUserHasSSOForCurrentTenant,
  checkIfThisUserHasSSOForAnyTenant,
};
