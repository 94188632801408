import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VStepper,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperItems,[_c(VStepperContent,{staticClass:"pa-0",attrs:{"step":"1"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 px-3 title"},[_vm._v(" "+_vm._s(_vm.$t('RedirectionModal.selectOrganization'))+" ")]),_c(VCardText,[_c(VList,_vm._l((_vm.user.tenants),function(tenant){return _c(VListItem,{key:tenant.name,on:{"click":function($event){return _vm.selectTenant(tenant)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(tenant.name)}})],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-arrow-right-bold")])],1)],1)}),1)],1)],1)],1),_c(VStepperContent,{staticClass:"pa-0",attrs:{"step":"2"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 px-3 title"},[_vm._v(" "+_vm._s(_vm.$t('RedirectionModal.chooseApp'))+" ")]),_c(VCardText,[(_vm.selectedTenant !== null)?_c(VList,[(_vm.selectedTenant.tenant_user.is_user_subscribed_to_meishi)?_c(VListItem,{on:{"click":function($event){return _vm.redirectToApp('meishi')}}},[_c(VListItemIcon,[_c(VAvatar,{attrs:{"tile":"","size":"25"}},[_c('img',{attrs:{"src":_vm.nextaApps.meishi.logo}})])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.nextaApps.meishi.name)}})],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-arrow-right-bold")])],1)],1):_vm._e(),(
                  _vm.selectedTenant.tenant_user.is_user_subscribed_to_scheduler
                )?_c(VListItem,{on:{"click":function($event){return _vm.redirectToApp('scheduler')}}},[_c(VListItemIcon,[_c(VAvatar,{attrs:{"tile":"","size":"25"}},[_c('img',{attrs:{"src":_vm.nextaApps.scheduler.logo}})])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.nextaApps.scheduler.name)}})],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-arrow-right-bold")])],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }