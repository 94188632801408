<template>
  <VDialog v-model="dialog" persistent max-width="400px">
    <VCard>
      <VCardTitle class="py-2 title">
        <span> {{ $t('SubdomainEditDialog.editCustomSubdomain') }} </span>
        <VSpacer />
        <VBtn text icon @click="close">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCardTitle>

      <VCardText class="pb-0 py-4">
        <VForm ref="form" v-model="valid" @submit.prevent>
          <VTextField
            v-model="subdomain"
            :placeholder="$t('SubdomainEditDialog.placeholder')"
            prefix="https://"
            suffix=".tnexta.com"
            outlined
            :rules="subdomainRules"
          />
        </VForm>
      </VCardText>

      <VCardActions>
        <VSpacer />
        <VBtn
          :loading="loading"
          :disabled="shouldDisableSubmit"
          color="accent"
          @click="submit"
        >
          {{ $t('common.submit') }}
        </VBtn>
      </VCardActions>
    </VCard>

    <ConfirmPasswordDialog ref="confirmPasswordDialog" />
  </VDialog>
</template>

<script>
import openSnackbar from '../../../helpers';
import ConfirmPasswordDialog from '../../../components/ConfirmPasswordDialog.vue';

export default {
  components: {
    ConfirmPasswordDialog,
  },

  data() {
    return {
      dialog: false,
      subdomain: '',
      valid: false,
      loading: false,
    };
  },

  computed: {
    subdomainRules() {
      const subdomainRegex = /^[a-zA-Z0-9][\w-]{0,61}[a-zA-Z0-9]$/;
      return [
        (v) =>
          !v ||
          v.length >= 2 ||
          this.$t('SubdomainEditDialog.subdomainMinLength'),
        (v) =>
          !v ||
          subdomainRegex.test(v) ||
          this.$t('SubdomainEditDialog.subdomainInvalid'),
      ];
    },

    shouldDisableSubmit() {
      return this.subdomain === this.originalSubdomain || this.valid === false;
    },
  },

  methods: {
    open(currentCustomSubdomain) {
      this.resetForm();
      this.subdomain = currentCustomSubdomain;

      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.subdomain = '';
      this.valid = false;
    },

    async submit() {
      const confirm = await this.$refs.confirmPasswordDialog.open();
      if (confirm === null) {
        return;
      }
      if (confirm === true) {
        this.submitChangeRequest();
      } else {
        openSnackbar(
          this.$t('SubdomainEditDialog.pleaseEnterCorrectPassword'),
          'error',
        );
      }
    },

    async submitChangeRequest() {
      try {
        await axios.patch('admin/subdomain', {
          subdomain: this.subdomain,
        });
        this.$emit('updated:subdomain', this.subdomain);
      } catch (error) {
        openSnackbar(
          this.$t('SubdomainEditDialog.somethingWentWrong'),
          'error',
        );
        throw error;
      }
    },
  },
};
</script>
