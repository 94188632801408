<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :persistent="isDeleting"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="grey lighten-4 headline">
        <v-icon color="red"> mdi-alert </v-icon>
        {{ $t('adminPage.user.headsUp') }}
        <v-spacer />
        <v-btn text icon color="grey" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4 text--primary">
        <v-simple-table>
          <template #default>
            <tbody>
              <tr>
                <td>{{ $t('adminPage.user.name') }}</td>
                <td>{{ user.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('adminPage.user.department') }}</td>
                <td>{{ user.department.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('adminPage.user.email') }}</td>
                <td>{{ user.email }}</td>
              </tr>
              <tr v-if="isMeishiAllowed">
                <td>{{ $t('adminPage.user.cardsCount') }}</td>
                <td>{{ meishiCount }}</td>
              </tr>
              <tr v-if="isSchedulerAllowed">
                <td>{{ $t('adminPage.user.schedulesCount') }}</td>
                <td>{{ schedulesCount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        {{ $t('adminPage.user.reallyDeleteAccountConfirmation') }}
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn outlined @click="dialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="error"
          :loading="isDeleting"
          :disabled="isDeleting"
          @click="deleteUserWithData"
        >
          {{ $t('adminPage.user.deleteAccountWithData') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  deleteUser,
  fetchMeishiCount,
  fetchSchedulesCount,
} from '../../../api/admin.api';
import openSnackbar from '../../../helpers';

export default {
  data: () => ({
    dialog: false,
    user: {
      name: '',
      email: '',
      department: {
        name: '',
      },
      allowedApps: [],
    },
    meishiCount: null,
    schedulesCount: null,
    isDeleting: false,
  }),
  computed: {
    isMeishiAllowed() {
      return this.user.allowedApps.includes('meishi');
    },
    isSchedulerAllowed() {
      return this.user.allowedApps.includes('scheduler');
    },
  },
  methods: {
    open(user) {
      this.dialog = true;
      this.meishiCount = null;
      this.schedulesCount = null;
      this.user = user;
      if (this.isMeishiAllowed) {
        this.fetchMeishiCount();
      }
      if (this.isSchedulerAllowed) {
        this.fetchSchedulesCount();
      }
    },
    close() {
      this.dialog = false;
    },
    async fetchMeishiCount() {
      const response = await fetchMeishiCount(this.user.id);
      this.meishiCount = response.data;
    },
    async fetchSchedulesCount() {
      const response = await fetchSchedulesCount(this.user.id);
      this.schedulesCount = response.data;
    },
    async deleteUserWithData() {
      this.isDeleting = true;

      try {
        const response = await deleteUser(this.user.id);
        this.$store.dispatch('updateAllowedAppsForTenant');
        if (response.status === 202) {
          openSnackbar(this.$t('adminPage.user.deletionInProgress'), 'success');
        } else if (response.status === 204) {
          openSnackbar(this.$t('adminPage.user.userDeleted'), 'success');
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.error === 'cannot-delete-last-admin'
        ) {
          openSnackbar(
            this.$t('adminPage.user.cannotDeleteLastAdmin'),
            'error',
          );
        } else {
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        }
      }

      this.isDeleting = false;
      this.$emit('deleted');
      this.close();
    },
  },
};
</script>
