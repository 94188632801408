import i18n from './i18n';

function getPathForMeishi() {
  switch (process.env.VUE_APP_BUILD_FOR) {
    case 'staging':
      return `/staging-meishi/`;
    case 'production':
      return `/meishi/`;
    default:
      return `/meishi/`;
  }
}

function getPathForScheduler() {
  switch (process.env.VUE_APP_BUILD_FOR) {
    case 'staging':
      return `/staging-scheduler/`;
    case 'production':
      return `/scheduler/`;
    default:
      return `/scheduler/`;
  }
}

function getPathForMeishiOperator() {
  switch (process.env.VUE_APP_BUILD_FOR) {
    case 'production':
      return `/operator/`;
    default:
      return `/operator/`;
  }
}

const apps = {
  meishi: {
    name: i18n.t('apps.meishi'),
    nameShort: i18n.t('apps.meishiShort'),
    logo: `${process.env.BASE_URL}meishi.png`,
    path: getPathForMeishi(),
  },
  scheduler: {
    name: i18n.t('apps.scheduler'),
    nameShort: i18n.t('apps.schedulerShort'),
    logo: `${process.env.BASE_URL}scheduler.png`,
    path: getPathForScheduler(),
  },
  meishi_operator: {
    name: i18n.t('apps.meishiOperator'),
    nameShort: i18n.t('apps.meishiOperatorShort'),
    logo: `${process.env.BASE_URL}meishiOperator.png`,
    path: getPathForMeishiOperator(),
  },
};

export default apps;
