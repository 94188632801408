import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm.$t('importLogs.importLogs'))+" ")]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"headers":_vm.headers,"disable-sort":true,"disable-pagination":true,"hide-default-footer":true,"items":_vm.logs,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [(items.length === 0)?_c('tbody',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(_vm.$t('importLogs.importLogsWillBeShownHere'))+" ")])]):_vm._e(),_c('tbody',_vm._l((items),function(log){return _c('tr',{key:log.id},[_c('td',[_vm._v(_vm._s(log.filename))]),_c('td',[_vm._v(" "+_vm._s(log.start_time)+" ")]),_c('td',[_vm._v(" "+_vm._s(log.user.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(log.status)+" ")]),_c('td',[_vm._v(_vm._s(log.number_of_users_updated))])])}),0)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }