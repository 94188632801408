<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-col cols="12" class="mb-16">
        <v-img :src="logoPath" height="80" contain />
      </v-col>

      <v-col cols="12" class="mt-16">
        <v-card
          outlined
          rounded="lg"
          class="mx-auto text-start pa-4 text-caption-1"
          max-width="300px"
          style="background-color: #e3f2fd"
        >
          {{ $t('OpenMeishiMobile.explanation') }}
        </v-card>
      </v-col>
      <v-col align="center">
        <v-btn
          dark
          x-large
          color="primary"
          :width="buttonWidth"
          height="60"
          @click="open"
        >
          {{ $t('OpenMeishiMobile.openApp') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import nextaApps from '../nextaApps';

export default {
  data() {
    return {
      nextaApps,
    };
  },

  computed: {
    logoPath() {
      return `${process.env.BASE_URL}logo.png`;
    },

    buttonWidth() {
      const width = this.$vuetify.breakpoint;
      if (width.width < 300) {
        return '100%';
      }
      return '300px';
    },
  },

  methods: {
    open() {
      const currentUrl = new URL(window.location.href);
      const searchParams = new URLSearchParams(currentUrl.search);
      const url = new URL(
        `https://tnexta.com${nextaApps.meishi.path}v2/login-using-sso`,
      );
      url.search = searchParams.toString();
      window.location.href = url;
    },
  },
};
</script>
