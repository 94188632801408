<template>
  <VDialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <VCard>
      <VCardTitle class="headline">
        {{ title }}
      </VCardTitle>

      <VDivider />

      <VCardText v-show="!!message" class="mt-4 text--primary" v-html="message">
      </VCardText>

      <VCardActions>
        <div class="flex-grow-1" />
        <VBtn :color="options.color" text @click="cancel">
          {{ $t('common.cancel') }}
        </VBtn>
        <VBtn :color="options.color" @click.native="agree">
          {{ $t('common.delete') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'error',
      width: 290,
    },
  }),

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
