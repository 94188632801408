<template>
  <v-main>
    <v-container :style="{ 'max-width': '900px' }">
      <v-card>
        <v-card-title>
          {{ $t('SsoSettingPage.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('SsoSettingPage.ssoExplanation') }}
          <PremiumFeatureExplanation v-if="!isFeatureSsoSubscribed" />
        </v-card-text>
        <template v-if="isFeatureSsoSubscribed">
          <v-card-title>
            {{ $t('SsoSettingPage.registeringWithIdP') }}
          </v-card-title>
          <v-card-text>
            {{ $t('SsoSettingPage.registeringWithIdPExplanation') }}
            <br />
            <br />
            <strong>{{ $t('SsoSettingPage.endpointUrlOfTnexta') }}</strong>
            <br />
            {{ acsUrl }}
            <br />
            <br />
            <strong>{{ $t('SsoSettingPage.entityId') }}</strong>
            <br />
            {{ spEntityId }}
          </v-card-text>

          <v-card-title>
            {{ $t('SsoSettingPage.configuringSamlAuthnForTnexta') }}
          </v-card-title>

          <v-form v-model="valid" lazy-validation>
            <v-card-text class="pb-0">
              <v-text-field
                v-model="idpSingleSignOnUrl"
                :label="$t('SsoSettingPage.singleSignOnUrlLabel')"
                :hint="$t('SsoSettingPage.singleSignOnUrlHint')"
                persistent-hint
              />

              <div style="height: 30px"></div>

              <v-text-field
                v-model="idpEntityId"
                :label="$t('SsoSettingPage.entityIdLabel')"
                :hint="$t('SsoSettingPage.entityIdHint')"
                persistent-hint
              />

              <div style="height: 30px"></div>

              <v-text-field
                v-model="idpSingleLogOutUrl"
                :label="$t('SsoSettingPage.singleLogOutUrlLabel')"
              />

              <div style="height: 20px"></div>

              <v-textarea
                v-model="idpCertificate"
                :label="$t('SsoSettingPage.publicCertificateLabel')"
                :hint="$t('SsoSettingPage.publicCertificateHint')"
                persistent-hint
              />

              <div style="height: 30px"></div>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-spacer />
                    <v-btn
                      :disabled="!valid"
                      color="accent"
                      @click="storeAndTry"
                    >
                      {{ $t('SsoSettingPage.storeSettingsAndTrySaml') }}
                    </v-btn>
                    <v-spacer />
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <p>
                      {{
                        $t(
                          'SsoSettingPage.afterPressingAboveButtonSsoFlowWillBeExecuted',
                        )
                      }}
                    </p>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      v-model="isIdpConfigured"
                      :label="$t('SsoSettingPage.enableSSO')"
                      :hint="$t('SsoSettingPage.pleaseStoreSettingsFirst')"
                      :persistent-hint="isSamlSettingsStoredOnServer !== true"
                      :disabled="isSamlSettingsStoredOnServer !== true"
                      @change="updateConfigureSaml"
                    ></v-switch>

                    <v-alert
                      v-if="isIdpConfigured"
                      outlined
                      color="primary"
                      type="info"
                      prominent
                    >
                      <v-row align="center">
                        <v-col class="grow black--text">
                          {{ $t('SsoSettingPage.numberOfUsersWithSsoEnabled') }}

                          {{ countActiveUsersWithSSOEnabled }} /
                          {{ countActiveUsers }}

                          <br />
                          <br />

                          {{ $t('SsoSettingPage.instructions') }}
                        </v-col>
                        <v-col class="shrink">
                          <v-btn
                            color="accent"
                            :to="{ name: 'admin-user-management' }"
                          >
                            {{ $t('SsoSettingPage.openManageUsersPage') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-form>
        </template>
      </v-card>
    </v-container>

    <confirm ref="confirm" />
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import colors from 'vuetify/es5/util/colors';
import Confirm from '../../../components/Confirm.vue';
import accountsUrl from '../../../accountsUrl';
import PremiumFeatureExplanation from '../PremiumFeatureExplanation.vue';
import {
  getSsoSettings,
  storeSsoSettings,
  updateSamlIdpConfiguration,
} from '../../../api/admin.api';
import openSnackbar from '../../../helpers';

export default {
  components: {
    Confirm,
    PremiumFeatureExplanation,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data: () => ({
    idpEntityId: null,
    idpSingleSignOnUrl: null,
    idpSingleLogOutUrl: null,
    idpCertificate: null,
    valid: true,
    serverResponse: null,
    isIdpConfigured: false,
  }),

  computed: {
    ...mapGetters([
      'currentTenant',
      'isFeatureSsoSubscribed',
      'countActiveUsersWithSSOEnabled',
      'activeUsers',
    ]),

    acsUrl() {
      return `${this.accountsUrlWithInitialSubdomain}saml2/all/acs`;
    },

    spEntityId() {
      return `${this.accountsUrlWithInitialSubdomain}saml2/all/metadata`;
    },

    accountsUrlWithInitialSubdomain() {
      return accountsUrl(this.currentTenant.initial_subdomain);
    },

    isSamlSettingsStoredOnServer() {
      return (
        this.serverResponse !== null &&
        this.serverResponse.saml_setting !== null
      );
    },

    countActiveUsers() {
      return this.activeUsers.length;
    },
  },

  created() {
    this.fetchSsoSettings();
    this.updateUsersFromServer();
  },

  methods: {
    ...mapActions(['updateUsersFromServer']),
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    async fetchSsoSettings() {
      const response = await getSsoSettings();
      this.storeSsoSettings(response);
    },

    storeSsoSettings(response) {
      const tenantWithSamlSetting = response.data;
      this.serverResponse = tenantWithSamlSetting;
      this.isIdpConfigured = tenantWithSamlSetting.is_idp_configured === 1;
      if (tenantWithSamlSetting.saml_setting !== null) {
        const samlSettings = tenantWithSamlSetting.saml_setting;
        this.idpEntityId = samlSettings.idp_entity_id;
        this.idpSingleSignOnUrl = samlSettings.idp_sso_url;
        this.idpSingleLogOutUrl = samlSettings.idp_slo_url;
        this.idpCertificate = samlSettings.idp_x509cert;
      }
    },

    async storeAndTry() {
      const response = await storeSsoSettings(
        this.idpEntityId,
        this.idpSingleSignOnUrl,
        this.idpSingleLogOutUrl,
        this.idpCertificate,
      );
      this.storeSsoSettings(response);
      this.trySso();
    },

    trySso() {
      const successPage = `${
        window.location.origin
      }${this.getAccountsAppPathPrefix()}/saml-test-passed`;
      // This route('saml-test-passed') is used in backend to bypass the user permission check in acs function.
      // If you change this, you need to change in backend as well.
      window.open(
        `${this.accountsUrlWithInitialSubdomain}saml2/all/login?RelayState=${successPage}`,
      );
    },

    async updateConfigureSaml(value) {
      if (value === true) {
        this.handleTurnOnSaml();
      } else {
        this.handleTurnOffSaml();
      }
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'SSOEnabledOrDisabledForTenant',
        eventValue: value ? 1 : 0,
      });
    },

    async handleTurnOnSaml() {
      try {
        await updateSamlIdpConfiguration(true);
        openSnackbar(this.$t('SsoSettingPage.ssoEnabledMsg'), 'success');
      } catch (error) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        this.isIdpConfigured = false;
        throw error;
      }
    },

    async handleTurnOffSaml() {
      try {
        await updateSamlIdpConfiguration(false);
        openSnackbar(this.$t('SsoSettingPage.ssoDisabledMsg'), 'success');
      } catch (error) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        this.isIdpConfigured = true;
        throw error;
      }
    },

    getAccountsAppPathPrefix() {
      switch (process.env.VUE_APP_BUILD_FOR) {
        case 'staging':
          return '/staging-accounts';
        case 'production':
          return '/accounts';
        default:
          return '';
      }
    },
  },
};
</script>
