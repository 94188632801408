import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"offset-y":"","min-width":"155","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":"","color":"white"}},{ ...tooltip, ...menu }),[_c(VIcon,[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.support')))])])]}}])},[_c(VList,{staticClass:"pa-0"},[_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.openManualInBrowser}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-text-box-multiple")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('toolbar.manual'))+" ")])],1)],1),_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.openFaqInBrowser}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-help-box-multiple")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('toolbar.faq'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }