<template>
  <v-main>
    <v-banner
      v-if="user.emailVerifiedAt === null"
      transition="slide-y-transition"
    >
      {{ $t('verifyEmail.pleaseVerify') }}
      <template #actions="{ dismiss }">
        <v-btn text color="primary" @click="dismiss">
          {{ $t('verifyEmail.dismiss') }}
        </v-btn>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              text
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="resendVerificationMail"
            >
              {{ $t('verifyEmail.resendEmail') }}
            </v-btn>
          </template>
          <span>
            {{ $t('verifyEmail.whyResend') }}
          </span>
        </v-tooltip>
      </template>
    </v-banner>
    <v-container fluid>
      <v-row justify="center" align="center" class="pt-4">
        <v-col md="6">
          <v-row class="pl-3 pb-2 headline">
            {{ $t('MyAccountManagementPage.myAccountManagement') }}
          </v-row>
          <v-row class="pl-3 pb-4 body-2">
            {{
              $t(
                'MyAccountManagementPage.theseSettingsHelpMaintainAccountSecurity',
              )
            }}
          </v-row>
          <v-card>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  v-if="!usesSSO"
                  @click="$store.dispatch('openChangePasswordModal')"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('MyAccountManagementPage.changePassword') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="!usesSSO" />
                <v-list-item @click="$refs.sessionDevicesModal.open()">
                  <v-list-item-icon>
                    <v-icon>mdi-cellphone-key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('MyAccountManagementPage.sessionDevices') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item @click="$refs.trustedDevicesModal.open()">
                  <v-list-item-icon>
                    <v-icon>mdi-cellphone-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('MyAccountManagementPage.trustedDevices') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="!usesSSO" />
                <v-list-item
                  v-if="!usesSSO"
                  :to="{
                    name: 'verify-password',
                    query: { redirect: 'twofa' },
                  }"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-two-factor-authentication</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('MyAccountManagementPage.twofa') }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-chip
                    v-if="user.twofaMethod === null"
                    class="ma-2"
                    label
                    color="red"
                    outlined
                  >
                    {{ $t('MyAccountManagementPage.disabled') }}
                  </v-chip>
                  <v-chip v-else class="ma-2" label color="green" outlined>
                    {{ $t('MyAccountManagementPage.enabled') }}
                  </v-chip>
                </v-list-item>
                <v-divider id="myAccountManagementPageChangeLanguageDivider" />
                <v-list-item
                  id="myAccountManagementPageChangeLanguageItem"
                  @click="isChangeLanguageOpen = true"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-translate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('MyAccountManagementPage.changeLanguage') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ChangeLanguageModal
      :is-change-language-open="isChangeLanguageOpen"
      @close="isChangeLanguageOpen = false"
    />
    <SessionDevicesModal ref="sessionDevicesModal" />
    <TrustedDevicesModal ref="trustedDevicesModal" />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { mapGetters, mapActions } from 'vuex';
import openSnackbar from '../helpers';
import ChangeLanguageModal from '../components/ChangeLanguageModal.vue';
import SessionDevicesModal from '@/components/Security/SessionDevicesModal.vue';
import TrustedDevicesModal from '@/components/Security/TrustedDevicesModal.vue';
import { checkIfThisUserHasSSOForCurrentTenant } from '@/api/sso.api';

export default {
  components: {
    ChangeLanguageModal,
    SessionDevicesModal,
    TrustedDevicesModal,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getSSOstate();
      vm.setTheme();
    });
  },

  data: () => ({
    isWhyVerifyShown: false,
    isChangeLanguageOpen: false,
  }),

  computed: {
    ...mapGetters(['user', 'usesSSO', 'ssoUrl']),
  },

  methods: {
    ...mapActions(['updateSSOUsage', 'updateSSOUrl']),

    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.cyan.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },

    async getSSOstate() {
      try {
        const response = await checkIfThisUserHasSSOForCurrentTenant(
          this.user.email,
        );
        if (response.data.is_idp_configured) {
          this.updateSSOUsage(true);
          this.updateSSOUrl(response.data.sso_url);
        } else {
          this.updateSSOUsage(false);
          this.updateSSOUrl(null);
        }
      } catch (e) {
        this.updateSSOUsage(false);
        this.updateSSOUrl(null);
        console.log(e);
      }
    },

    resendVerificationMail() {
      axios
        .post(`verification-mail/${this.user.email}`)
        .then(() => {
          openSnackbar(this.$t('verifyEmail.emailSent'), 'success', 5000);
        })
        .catch((error) => {
          console.log(error.response);
          this.isSomethingWrong = true;
        });
    },
  },
};
</script>
