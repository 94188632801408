import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"8","lg":"6"}},[_c(VCard,{attrs:{"width":"1000"}},[_c(VAlert,{attrs:{"outlined":"","type":"success","prominent":"","text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.$t('SamlTestPassPage.message'))+" ")]),_c(VCol,{staticClass:"shrink",on:{"click":_vm.close}},[_c(VBtn,[_vm._v(_vm._s(_vm.$t('common.close')))])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }