<template>
  <v-card class="mb-12 elevation-0">
    <div class="pl-3 pb-2 subtitle-1">{{ $t('twofa.scanBarcode') }}</div>
    <div class="pl-3 body-2 grey--text text--darken-2">
      {{ $t('twofa.scanBarcodeText') }}
      <span class="pl-1" style="cursor: pointer" @click="isCodeShown = true">
        <u>{{ $t('twofa.manualCode') }}</u>
      </span>
    </div>
    <div v-if="isCodeShown" class="pl-3 subtitle-1">{{ manualCode }}</div>
    <div justify="center" align="center" class="pt-2">
      <v-img :src="qrCode" height="200px" contain />
    </div>
    <v-row class="pl-3 pt-3 pb-2 subtitle-1">{{ $t('twofa.enterOtp') }}</v-row>
    <v-row class="pl-3 body-2 grey--text text--darken-2">
      {{ $t('twofa.enterOtpText') }}
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="6">
        <v-otp-input v-model="otp" type="number" @finish="setOtp" />
        <p class="pt-2 pl-2">
          {{ $t('common.pleaseEnterHalfWidthNumbersOnly') }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import openSnackbar from '../../../helpers';

export default {
  data: () => ({
    isCodeShown: false,
    manualCode: null,
    qrCode: '',
    otp: '',
  }),
  methods: {
    getManualAndQrCodes() {
      axios
        .get('/twofa/app/manual-code')
        .then((response) => {
          this.manualCode = response.data.manualCode;
          this.getQrCode();
        })
        .catch((error) => {
          this.$router.push({ name: 'my-account' });
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },
    getQrCode() {
      axios
        .get('/twofa/app/qr')
        .then((response) => {
          this.qrCode = response.data;
        })
        .catch((error) => {
          this.$router.push({ name: 'my-account' });
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },
    setOtp() {
      this.$emit('set-otp', this.otp);
    },
  },
};
</script>
