import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[(!_vm.currentTenant && _vm.isTenantManager)?_c(VAlert,{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('TenantManagerWarning.alert'))+" "+_vm._s(_vm.tenantName))]):_vm._e(),_c(VContainer,{style:({ 'max-width': '800px' })},[_c(VCard,[_c(VToolbar,{attrs:{"text":"","color":"white elevation-0"}},[_c(VBtn,{attrs:{"disabled":_vm.isFetchingDepartments,"color":"accent"},on:{"click":_vm.openDepartmentAddModal}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('adminPage.department.addDepartment'))+" ")],1),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search.search'),"single-line":"","hide-details":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c(VCardText,{staticClass:"pt-0"},[_c('DepartmentManagementTable',{attrs:{"departments":_vm.departments,"query":_vm.query},on:{"edit-department":_vm.editDepartment,"department-deleted":_vm.updateDepartmentsFromServer}})],1)],1)],1),_c('DepartmentAddEditModal',{ref:"departmentAddEditModal",on:{"department-added":_vm.updateDepartmentsFromServer,"department-edited":_vm.updateDepartmentsFromServer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }