const state = {
  drawer: true,
};

const getters = {
  drawer: (state) => state.drawer,
};

const mutations = {
  setDrawer(state, payload) {
    state.drawer = payload;
  },
  toggleNavDrawer(state) {
    state.drawer = !state.drawer;
  },
};

export default {
  state,
  getters,
  mutations,
};
