<template>
  <v-dialog
    v-model="dialog"
    :persistent="tab === Tab.SingleAdd"
    :max-width="width"
  >
    <v-card class="overflow-x-hidden">
      <v-row>
        <v-col cols="10">
          <v-tabs v-model="tab">
            <v-tab>{{ $t('usersAddTabs.addSingleUser') }}</v-tab>
            <v-tab>{{ $t('usersAddTabs.addMultipleUsersUsingCsv') }}</v-tab>
          </v-tabs>
        </v-col>
        <v-col align="right">
          <v-btn large icon color="grey" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <UserAddEditForm
            :dialog="dialog"
            :is-editing="false"
            @user-added="userAdded"
            @close="close"
          />
        </v-tab-item>
        <v-tab-item>
          <import-users-modal :dialog="dialog" @user-added="userAdded" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import UserAddEditForm from '../UserAddEditForm.vue';
import ImportUsersModal from './ImportUsersModal.vue';

const Tab = Object.freeze({ SingleAdd: 0, MultipleAdd: 1 });

export default {
  components: {
    UserAddEditForm,
    ImportUsersModal,
  },
  data() {
    return {
      tab: Tab.SingleAdd,
      dialog: false,
      Tab,
    };
  },
  computed: {
    width() {
      return this.tab === Tab.SingleAdd ? '600px' : '800px';
    },
  },
  methods: {
    open() {
      this.tab = Tab.SingleAdd;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    userAdded() {
      this.$emit('user-added');
    },
  },
};
</script>
