<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="grey lighten-4 headline">
        <v-icon color="red"> mdi-alert </v-icon>
        {{ $t('adminPage.user.headsUp') }}
        <v-spacer />
        <v-btn text icon color="grey" @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4 text--primary">
        <p v-if="isRemovingFromMeishi">
          {{ $t('ConfirmRemoveUserFromApp.warningMessageMeishi') }}
        </p>
        <p v-if="isRemovingFromScheduler">
          {{ $t('ConfirmRemoveUserFromApp.warningMessageScheduler') }}
        </p>
        <v-simple-table>
          <template #default>
            <tbody>
              <tr>
                <td>{{ $t('adminPage.user.name') }}</td>
                <td>{{ userName }}</td>
              </tr>
              <tr>
                <td>{{ $t('adminPage.user.department') }}</td>
                <td>{{ userDepartmentName }}</td>
              </tr>
              <tr>
                <td>{{ $t('adminPage.user.email') }}</td>
                <td>{{ userEmail }}</td>
              </tr>
              <tr v-if="isRemovingFromMeishi">
                <td>{{ $t('adminPage.user.cardsCount') }}</td>
                <td>{{ meishiCount }}</td>
              </tr>
              <tr v-if="isRemovingFromScheduler">
                <td>{{ $t('adminPage.user.schedulesCount') }}</td>
                <td>{{ schedulesCount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="error" @click="agree">
          {{ $t('ConfirmRemoveUserFromApp.deleteUserButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchMeishiCount, fetchSchedulesCount } from '../../../api/admin.api';

export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },

    userName: {
      type: String,
      required: true,
    },

    userEmail: {
      type: String,
      required: true,
    },

    userDepartmentName: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      isRemovingFromMeishi: null,
      isRemovingFromScheduler: null,
      meishiCount: null,
      schedulesCount: null,
    };
  },

  methods: {
    open(isRemovingFromMeishi, isRemovingFromScheduler) {
      this.dialog = true;
      this.meishiCount = null;
      this.schedulesCount = null;
      this.isRemovingFromMeishi = isRemovingFromMeishi;
      this.isRemovingFromScheduler = isRemovingFromScheduler;

      if (isRemovingFromMeishi) {
        this.fetchMeishiCount();
      }
      if (isRemovingFromScheduler) {
        this.fetchSchedulesCount();
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },

    async fetchMeishiCount() {
      const response = await fetchMeishiCount(this.userId);
      this.meishiCount = response.data;
    },

    async fetchSchedulesCount() {
      const response = await fetchSchedulesCount(this.userId);
      this.schedulesCount = response.data;
    },
  },
};
</script>
