import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VAvatar,_vm._g({directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"white--text title",staticStyle:{"cursor":"pointer"},attrs:{"size":"40px","color":"accent"}},on),[_vm._v(_vm._s(_vm.user.name.charAt(0)))])]}}])},[_c(VList,{staticClass:"pa-0"},[(_vm.doesUserBelongToMultipleTenants)?[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();return _vm.openSwitchOrganizationModal.apply(null, arguments)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-domain-switch")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('UserMenu.switchOrganization'))+" ")])],1)],1),_c(VDivider)]:_vm._e(),_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.logout}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-exit-to-app")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('toolbar.logout')))])],1)],1)],2),_c('TheSwitchOrganizationModal',{ref:"theSwitchOrganizationModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }