<template>
  <v-row class="px-4" justify="space-between">
    <v-col>
      <v-btn
        :id="addUserButtonId"
        :disabled="fetchingUsersData || isNoSeatAvailable"
        color="accent"
        class="mb-2 mr-2"
        @click="openUserAddModal"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('adminPage.user.addUser') }}
      </v-btn>

      <v-btn
        class="mb-2"
        color="accent"
        :disabled="fetchingUsersData"
        @click="openEditByImportModal"
      >
        <v-icon>mdi-file-document-edit</v-icon>
        {{ $t('adminPage.user.editByImport') }}
      </v-btn>
    </v-col>

    <v-col class="d-flex justify-end">
      <v-tooltip v-if="usersWithNullLastActiveAt.length > 0" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="accent"
            class="mr-2 mt-1"
            text
            small
            :loading="isSubmittingResetInvite"
            :disabled="isInviteSentToAll"
            v-bind="attrs"
            v-on="on"
            @click="resendInvite(notYetInvitedUsers)"
          >
            <span v-if="isInviteSentToAll">
              {{
                $t('UserManagementPageToolbar.inviteSentToNPeople', {
                  count: usersWithNullLastActiveAt.length,
                })
              }}
            </span>
            <span v-else>
              {{
                $t('UserManagementPageToolbar.resendInviteToNPeople', {
                  count: notYetInvitedUsers.length,
                })
              }}
            </span>
          </v-btn>
        </template>
        <span>{{ $t('UserManagementPageToolbar.tooltip') }}</span>
      </v-tooltip>

      <v-menu>
        <template #activator="{ on }">
          <v-btn icon color="accent" class="mx-0" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="$router.push({ name: 'admin-deactivated-user-management' })"
          >
            <v-btn icon>
              <v-icon>mdi-account-off</v-icon>
            </v-btn>
            <v-list-item-title>
              {{ $t('adminPage.user.viewDeactivatedAccounts') }}
            </v-list-item-title>
          </v-list-item>

          <template v-if="showSsoOptions">
            <v-list-item
              v-if="isSsoEnabledForAllUsers"
              @click="emitClickDisableSsoForAllUsers"
            >
              <v-btn icon>
                <v-icon>mdi-shield-key</v-icon>
              </v-btn>
              <v-list-item-title>
                {{ $t('adminPage.user.disableSsoForAllUsers') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-else @click="emitClickEnableSsoForAllUsers">
              <v-btn icon>
                <v-icon>mdi-shield-key</v-icon>
              </v-btn>
              <v-list-item-title>
                {{ $t('adminPage.user.enableSsoForAllUsers') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </v-col>

    <edit-by-import-modal
      ref="editByImportModal"
      @user-edited="$emit('user-edited')"
    />
    <import-error-details-modal />
    <users-add-tabs ref="usersAddTabs" @user-added="$emit('user-added')" />

    <confirm ref="confirm" />
  </v-row>
</template>

<script>
import 'intro.js/minified/introjs.min.css';
import introJS from 'intro.js';

import { mapActions, mapGetters } from 'vuex';

import UsersAddTabs from './Add/UsersAddTabs.vue';
import EditByImportModal from './Edit/Import/EditByImportModal.vue';
import ImportErrorDetailsModal from './ImportErrorDetailsModal.vue';
import { resendInvite } from '../../../api/admin.api';
import openSnackbar from '../../../helpers';
import Confirm from '../../../components/Confirm.vue';

export default {
  components: {
    UsersAddTabs,
    EditByImportModal,
    ImportErrorDetailsModal,
    Confirm,
  },

  props: {
    numberOfUsers: {
      type: Number,
      default: null,
    },

    fetchingUsersData: {
      type: Boolean,
      default: true,
    },

    usersWithNullLastActiveAt: {
      type: Array,
      default() {
        return [];
      },
    },

    inviteSentForUsers: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      addUserButtonId: 'add-user-button',
      isSubmittingResetInvite: false,
    };
  },

  computed: {
    ...mapGetters([
      'currentTenant',
      'activeUsers',
      'isTenantManager',
      'currentTenantFromSubdomain',
    ]),

    isNoSeatAvailable() {
      return this.$store.getters.allowedAppsForTenant.every(
        (app) => !app.isSeatAvailable,
      );
    },

    notYetInvitedUsers() {
      return this.usersWithNullLastActiveAt.filter(
        (user) => !this.isInviteSent(user),
      );
    },

    isInviteSentToAll() {
      return this.notYetInvitedUsers.length === 0;
    },

    isSsoEnabledForAllUsers() {
      return this.activeUsers.every((user) => user.is_sso_enabled);
    },

    showSsoOptions() {
      if (!this.currentTenant && this.isTenantManager) {
        return (
          this.currentTenantFromSubdomain.is_idp_configured &&
          this.currentTenantFromSubdomain.feature_sso_users_limit !== 0
        );
      }
      return (
        this.currentTenant.is_sso_enabled &&
        this.currentTenant.feature_sso_users_limit !== 0
      );
    },
  },

  watch: {
    numberOfUsers(value) {
      /* showIntro requires this watcher because
      numberOfUsers updates later on API response but default is zero */
      if (
        localStorage.getItem(this.addUserButtonId) !== 'true' &&
        value === 1
      ) {
        this.showIntro();
      }
    },
  },

  created() {
    this.updateAllowedAppsForTenant();
  },

  methods: {
    ...mapActions(['updateAllowedAppsForTenant']),

    isInviteSent(user) {
      return this.inviteSentForUsers.some(
        (invitedUser) => invitedUser.id === user.id,
      );
    },

    showIntro() {
      return introJS()
        .setOptions({
          steps: [
            {
              element: document.querySelector(`#${this.addUserButtonId}`),
              title: this.$t('tutorials.addUserTitle'),
              intro: this.$t('tutorials.addUserBody'),
              position: 'bottom',
            },
          ],
          showBullets: false,
          nextLabel: this.$t('tutorials.next'),
          prevLabel: this.$t('tutorials.back'),
          doneLabel: this.$t('tutorials.done'),
        })
        .onchange((targetElement) => {
          localStorage.setItem(targetElement.id, 'true');
        })
        .start();
    },

    openUserAddModal() {
      this.$refs.usersAddTabs.open();
    },

    openEditByImportModal() {
      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'openEditByImportModal',
        eventValue: 1,
      });
      this.$refs.editByImportModal.open();
    },

    async resendInvite(users) {
      const confirm = await this.$refs.confirm.open(
        this.$t('UserManagementPageToolbar.confirmResendInviteTitle'),
        this.$t('UserManagementPageToolbar.confirmResendInviteBody'),
        { color: 'warning', width: '450px' },
      );
      if (confirm === true) {
        this.isSubmittingResetInvite = true;
        await resendInvite(users);
        this.isSubmittingResetInvite = false;
        this.$emit('update:inviteSentForUsers', [
          ...this.inviteSentForUsers,
          ...users,
        ]);
        openSnackbar(
          this.$t('UserManagementPageToolbar.snackbar', {
            count: users.length,
          }),
          'success',
        );
      }
    },

    emitClickEnableSsoForAllUsers() {
      this.$emit('click:enable-sso-for-all-users');
    },

    emitClickDisableSsoForAllUsers() {
      this.$emit('click:disable-sso-for-all-users');
    },
  },
};
</script>
