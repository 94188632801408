const state = {
  shouldChangePassword: false,
  isChangePasswordOpen: false,
  isPasswordChanged: false,
  isWeakPasswordChangeRequired: false,
};

const getters = {
  shouldChangePassword: (state) => state.shouldChangePassword,
  isChangePasswordOpen: (state) => state.isChangePasswordOpen,
  isPasswordChanged: (state) => state.isPasswordChanged,
  isWeakPasswordChangeRequired: (state) => state.isWeakPasswordChangeRequired,
};

const mutations = {
  updateShouldChangePassword: (state, payload) => {
    state.shouldChangePassword = payload;
  },
  updateIsChangePasswordOpen: (state, payload) => {
    state.isChangePasswordOpen = payload;
  },
  updateIsPasswordChanged: (state, payload) => {
    state.isPasswordChanged = payload;
  },
  updateIsWeakPasswordChangeRequired: (state, payload) => {
    state.isWeakPasswordChangeRequired = payload;
  },
};

const actions = {
  setShouldChangePassword: ({ commit }) => {
    commit('updateShouldChangePassword', true);
  },
  resetShouldChangePassword: ({ commit }) => {
    commit('updateShouldChangePassword', false);
  },
  setIsWeakPasswordChangeRequired: ({ commit }) => {
    commit('updateIsWeakPasswordChangeRequired', true);
  },
  openChangePasswordModal: ({ commit }) => {
    commit('updateIsChangePasswordOpen', true);
  },
  closeChangePasswordModal: ({ commit }) => {
    commit('updateIsChangePasswordOpen', false);
  },
  passwordChanged: ({ commit }) => {
    commit('updateIsPasswordChanged', true);
  },
  resetPasswordChanged: ({ commit }) => {
    commit('updateIsPasswordChanged', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
