<template>
  <div>
    <TooltipButton
      icon="mdi-pencil"
      :tooltip="$t('common.edit')"
      :is-disabled="isDisabled"
      color="accent"
      @click="emitEditDepartment"
    />

    <TooltipButton
      icon="mdi-delete"
      :tooltip="$t('common.delete')"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      color="accent"
      @click="deleteDepartment"
    />

    <Confirm ref="confirm" />
  </div>
</template>

<script>
import { deleteDepartment } from '../api/department.api';
import openSnackbar from '../helpers';

import Confirm from './Confirm.vue';
import TooltipButton from './TooltipButton.vue';

export default {
  components: { TooltipButton, Confirm },
  props: {
    department: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDisabled: false,
      isLoading: false,
    };
  },

  methods: {
    emitEditDepartment() {
      this.$emit('edit-department', this.department);
    },
    async deleteDepartment(department) {
      const confirm = await this.openConfirmDeleteDialog();
      if (confirm) {
        this.performDepartmentDelete(department);
      }
    },

    openConfirmDeleteDialog() {
      return this.$refs.confirm.open(
        this.$t('adminPage.department.deleteDepartment'),
        this.$t('adminPage.department.deleteConfirm'),
        { color: 'warning' },
      );
    },

    async performDepartmentDelete() {
      this.isLoading = true;
      this.isDisabled = true;
      try {
        await deleteDepartment(this.department.id);
        this.$emit('department-deleted');
        openSnackbar(
          this.$t('adminPage.department.departmentDeleted'),
          'success',
        );
      } catch (error) {
        const errorMessage = error.response.data;
        if (errorMessage === 'cannot-delete-department-with-users') {
          openSnackbar(
            this.$t('adminPage.department.cannotDeleteAsUserExists'),
            'error',
            10000,
          );
        } else {
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        }
      } finally {
        this.isDisabled = false;
        this.isLoading = false;
      }
    },
  },
};
</script>
