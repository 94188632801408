<template>
  <VDialog
    :value="isDialogOpen"
    persistent
    width="500px"
    @input="handleDialogStateChange"
  >
    <VCard width="500px">
      <VCardTitle>
        {{ title }}

        <VSpacer />

        <VBtn icon @click="closeDialog">
          <VIcon> mdi-close </VIcon>
        </VBtn>
      </VCardTitle>

      <VForm ref="form" lazy-validation>
        <VCardText>
          <VTextField
            v-model="details.companyName"
            :label="$t('ContactDetailsPage.companyName')"
            :rules="requiredRule"
          />
          <VTextField
            v-model="details.name"
            :label="$t('ContactDetailsPage.name')"
            :rules="requiredRule"
          />
          <VTextField
            v-model="details.department"
            :label="$t('ContactDetailsPage.department')"
          />
          <VTextField
            v-model="details.position"
            :label="$t('ContactDetailsPage.jobTitle')"
          />
          <VTextField
            v-model="details.email"
            :label="$t('ContactDetailsPage.email')"
            :rules="emailRules"
          />
          <VTextField
            v-model="details.contactNumber"
            :label="$t('ContactDetailsPage.phoneNumber')"
            :rules="requiredRule"
          />
          <VTextField
            v-model="details.postalCode"
            :label="$t('ContactDetailsPage.postalCode')"
            :rules="requiredRule"
          />
          <VTextField
            v-model="details.address"
            :label="$t('ContactDetailsPage.address')"
            :rules="requiredRule"
          />
        </VCardText>
      </VForm>

      <VCardActions>
        <VSpacer />
        <VBtn color="accent" @click="handleSubmit">
          {{ $t('common.submit') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  data() {
    return {
      isDialogOpen: false,
      isPrimaryContact: false,
      isEdit: false,
      requiredRule: [
        (item) => (!!item && item.trim() !== '') || this.$t('common.required'),
      ],

      details: {
        companyName: '',
        name: '',
        department: '',
        position: '',
        email: '',
        contactNumber: '',
        postalCode: '',
        address: '',
      },
    };
  },

  computed: {
    title() {
      if (this.isPrimaryContact) {
        return this.$t('TenantContactAddEditDialog.editPrimaryContact');
      }

      if (this.isEdit) {
        return this.$t('TenantContactAddEditDialog.editBillingContact');
      }

      return this.$t('TenantContactAddEditDialog.addBillingContact');
    },

    emailRules() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return [
        (v) => !!v || this.$t('common.required'),
        (v) => emailRegex.test(v) || this.$t('validation.emailValidMust'),
      ];
    },
  },

  methods: {
    openEditForPrimary(details) {
      this.isPrimaryContact = true;
      this.isEdit = true;
      this.isDialogOpen = true;

      this.assignDetailsForEdit(details);
    },

    openAddForBilling() {
      this.isPrimaryContact = false;
      this.isEdit = false;
      this.isDialogOpen = true;
    },

    openEditForBilling(details) {
      this.isPrimaryContact = false;
      this.isEdit = true;
      this.isDialogOpen = true;

      this.assignDetailsForEdit(details);
    },

    assignDetailsForEdit(details) {
      this.details.id = details.id;
      this.details.companyName = details.company_name;
      this.details.name = details.name;
      this.details.department = details.department;
      this.details.position = details.position;
      this.details.email = details.email;
      this.details.contactNumber = details.contact_number;
      this.details.postalCode = details.postal_code;
      this.details.address = details.address;
    },

    handleDialogStateChange(isOpen) {
      if (isOpen) {
        return;
      }

      this.closeDialog();
    },

    closeDialog() {
      this.isDialogOpen = false;
      this.reset();
    },

    handleSubmit() {
      const isValid = this.$refs.form.validate(true);

      if (!isValid) {
        return;
      }

      if (this.isPrimaryContact) {
        this.$emit('edit:primary-contact', { ...this.details });
        this.closeDialog();
        return;
      }

      if (this.isEdit) {
        this.$emit('edit:billing-contact', { ...this.details });
        this.closeDialog();
        return;
      }

      this.$emit('add:billing-contact', { ...this.details });
      this.closeDialog();
    },

    reset() {
      this.isPrimaryContact = false;
      this.isEdit = false;

      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>
