import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{style:({ 'max-width': '800px' })},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('TenantSettingsPage.title'))+" ")]),_c(VCardText,[_c('div',[_vm._v(" "+_vm._s(_vm.$t('TenantSettingsPage.youCanSetCustomSubdomain'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('TenantSettingsPage.initialSubdomainWillStillBeAccessible'))+" ")]),_c(VSimpleTable,{staticClass:"mt-4",attrs:{"width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t('TenantSettingsPage.subdomain')))]),_c('th',[_vm._v(_vm._s(_vm.$t('TenantSettingsPage.nextaUrl')))]),_c('th')])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('TenantSettingsPage.initialSubdomain')))]),_c('td',[_vm._v(_vm._s(_vm.initialSubdomain))]),_c('td',[_vm._v(" "+_vm._s(_vm.initialSubdomainUrl)+" "),_c('TooltipButton',{attrs:{"icon":"mdi-content-copy","tooltip":_vm.$t('TenantSettingsPage.copy')},on:{"click":function($event){return _vm.copy(_vm.initialSubdomainUrl)}}})],1),_c('td')]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('TenantSettingsPage.customSubdomain')))]),(_vm.customSubdomainUrl)?[_c('td',[_vm._v(_vm._s(_vm.customSubdomain))]),_c('td',[_vm._v(" "+_vm._s(_vm.customSubdomainUrl)+" "),_c('TooltipButton',{attrs:{"icon":"mdi-content-copy","tooltip":_vm.$t('TenantSettingsPage.copy')},on:{"click":function($event){return _vm.copy(_vm.customSubdomainUrl)}}})],1)]:[_c('td',[_vm._v(_vm._s(_vm.$t('TenantSettingsPage.na')))]),_c('td',[_vm._v(_vm._s(_vm.$t('TenantSettingsPage.na')))])],_c('td',[_c('TooltipButton',{attrs:{"icon":"mdi-pencil","tooltip":_vm.$t('TenantSettingsPage.editCustomSubdomain'),"color":"accent"},on:{"click":_vm.openSubdomainEditDialog}})],1)],2)])]},proxy:true}])})],1)],1)],1),_c('SubdomainEditDialog',{ref:"subdomainEditDialog",on:{"updated:subdomain":_vm.refreshPage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }