<template>
  <v-main>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col xs="12" sm="8" md="6" lg="5" xl="4">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t('otp.enterOtp') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent>
                <v-otp-input v-model="otp.otp" type="number" />
                <p class="pt-2 pl-2">
                  {{ $t('common.pleaseEnterHalfWidthNumbersOnly') }}
                </p>
                <v-checkbox
                  v-model="otp.rememberDevice"
                  :label="$t('otp.rememberDevice')"
                  class="pl-4"
                  hide-details
                />
                <v-alert
                  :value="!!errorMessage"
                  outlined
                  transition="scale-transition"
                  type="error"
                  >{{ errorMessage }}</v-alert
                >
                <v-card-actions class="pb-4">
                  <v-spacer />
                  <v-btn
                    :disabled="isSubmitButtonDisabled"
                    color="primary"
                    @click="submit"
                    >{{ $t('common.submit') }}</v-btn
                  >
                </v-card-actions>
                <v-divider />
                <v-subheader class="subtitle-2">{{
                  $t('otp.havingProblems')
                }}</v-subheader>
                <ul class="ml-4">
                  <li v-if="$store.getters.user.twofaMethod === 'sms'">
                    <resend-otp />
                  </li>
                  <li>
                    <router-link
                      class="routerLink"
                      :to="{ name: 'backup-login', query: $route.query }"
                    >
                      {{ $t('otp.loginBackupCodes') }}
                    </router-link>
                  </li>
                </ul>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <login-footer />
    <RedirectionModal ref="redirectionModal" />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import LoginFooter from '../../components/LoginFooter.vue';
import ResendOtp from './ResendOtp.vue';
import RedirectionModal from '../../components/RedirectionModal.vue';

import throttleErrorMessage from '../../mixins/throttleErrorMessage';
import outlookLogin from '../../mixins/outlookLogin';

export default {
  components: {
    LoginFooter,
    ResendOtp,
    RedirectionModal,
  },

  mixins: [throttleErrorMessage, outlookLogin],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data: () => ({
    otp: {
      otp: '',
      rememberDevice: false,
    },
    valid: false,
    isSubmitButtonDisabled: true,
  }),

  watch: {
    valid(isValid) {
      if (isValid) {
        this.isSubmitButtonDisabled = false;
      } else {
        this.isSubmitButtonDisabled = true;
      }
    },
  },

  created() {
    this.handleOutlookInitialize();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.blue.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },

    submit() {
      this.errorMessage = null;
      if (!this.$refs.form.validate()) {
        return;
      }
      axios
        .post('otp/verify', { otp: JSON.stringify(this.otp) })
        .then((response) => {
          this.$store.dispatch('storeUser', response.data.user);
          if (this.isOutlookAddInLogin) {
            this.handleOutlookLogin();
            return;
          }
          this.$refs.redirectionModal.open();
        })
        .catch((error) => {
          console.log(error);
          this.displayErrorMessage(error.response.data);
        });
    },

    displayErrorMessage(error) {
      switch (error.error) {
        case 'invalid-otp':
        case undefined:
          this.errorMessage = this.$t('otp.incorrectOtp');
          return;
        case 'locked-out':
          this.disableSubmitButton();
          this.displayRetryAfterXSecondsError(error.duration, 'otp');
          return;
        default:
          break;
      }
      this.$router.push({
        name: 'login',
        query: this.$route.query,
      });
    },

    disableSubmitButton() {
      this.valid = false;
    },

    enableOtpSubmitButton() {
      this.valid = true;
    },
  },
};
</script>
