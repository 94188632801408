<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
      <v-card>
        <v-card-title class="grey lighten-4 py-2 px-3 title">
          {{ $t('userSettings.confirmPassword') }}
          <v-spacer />
          <v-btn icon color="grey" @click="abort(null)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container class="pt-0">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="currentPassword"
                :label="$t('userSettings.currentPassword')"
                :rules="[rules.required]"
                :error-messages="errorMessages"
                type="password"
                required
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pb-4 pr-4 pt-0">
          <v-spacer />
          <v-btn color="primary" @click="submit">
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    valid: false,
    currentPassword: '',
    show1: false,
    errorMessages: [],
  }),

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('common.required'),
      };
    },
  },

  methods: {
    open() {
      this.dialog = true;
      this.currentPassword = '';

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    accept() {
      this.resolve(true);
      this.dialog = false;
    },

    abort(resolution = false) {
      this.password = '';
      this.resolve(resolution);
      this.dialog = false;
    },

    submit() {
      axios
        .post('password/verify', {
          password: this.currentPassword,
          redirect: 'admin/subdomain',
        })
        .then((response) => {
          const { data } = response;
          const { message } = data;

          if (message === 'verified') {
            this.accept();
          } else {
            this.abort();
          }
        })
        .catch(() => {
          this.abort();
        });
    },
  },
};
</script>
