import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"6","md":"6","sm":"8"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 px-3 title"},[_vm._v(" "+_vm._s(_vm.$t('verifyPassword.title'))+" "),_c(VSpacer)],1),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":_vm.$t('verifyPassword.password'),"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.showPassword ? 'text' : 'password',"rules":[_vm.rules.required],"required":"","autocomplete":"new-password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.verifyPassword.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","outlined":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.verifyPassword}},[_vm._v(" "+_vm._s(_vm.$t('common.submit'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }