import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 title"},[_vm._v(" "+_vm._s(_vm.$t('importErrorDetailsModal.importError'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('importErrorDetailsModal.expectedHeader'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('importErrorDetailsModal.actualHeader'))+" ")])])]),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row[0] + row[1],class:{ 'highlight-row': row[0] !== row[1] }},[_c('td',[_vm._v(_vm._s(row[0]))]),_c('td',[_vm._v(_vm._s(row[1]))])])}),0)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }