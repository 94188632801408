import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.isChangePasswordOpen),callback:function ($$v) {_vm.isChangePasswordOpen=$$v},expression:"isChangePasswordOpen"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey lighten-4 py-2 px-3 title"},[_vm._v(" "+_vm._s(_vm.$t('userSettings.changePassword'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.shouldChangePassword)?_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('userSettings.suggestChangePassword'))+" ")]):_vm._e(),(_vm.isWeakPasswordChangeRequired)?_c(VAlert,{staticClass:"ma-4",attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t('resetPassword.weakPasswordChange'))+" ")]):_vm._e(),_c(VContainer,{class:{ 'pt-0': _vm.shouldChangePassword }},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('userSettings.currentPassword'),"append-icon":_vm.show1 ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.show1 ? 'text' : 'password',"rules":[_vm.rules.required],"error-messages":_vm.errorMessages,"required":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('userSettings.newPassword'),"append-icon":_vm.show2 ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.show2 ? 'text' : 'password',"rules":[
                _vm.rules.required,
                _vm.rules.minPasswordLength,
                _vm.rules.minStrength,
              ],"required":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),(_vm.isChangePasswordOpen)?_c('password-strength-meter',{attrs:{"password":_vm.newPassword}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('userSettings.repeatNewPassword'),"append-icon":_vm.show3 ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.show3 ? 'text' : 'password',"rules":[
                _vm.rules.required,
                _vm.passwordMatch,
                _vm.rules.minPasswordLength,
              ],"hint":_vm.$t('userSettings.min8CharsHint'),"required":""},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}})],1)],1)],1),_c(VCardActions,{staticClass:"pb-4"},[_c(VSpacer),(_vm.isWeakPasswordChangeRequired)?_c(VBtn,{attrs:{"text":"","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('common.maybeLater'))+" ")]):_c(VBtn,{attrs:{"text":"","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.shouldDisableSubmit,"color":"primary"},on:{"click":_vm.submitPasswordChangeForm}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }