import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import router from '../router/index';

function getEnvironment() {
  const isProductionOrStaging = process.env.NODE_ENV === 'production';
  let environment = 'localhost';
  if (isProductionOrStaging) {
    if (process.env.VUE_APP_BUILD_FOR === 'staging') {
      environment = 'staging';
    } else {
      environment = 'production';
    }
  }
  return environment;
}

const environment = getEnvironment();

Sentry.init({
  Vue,
  dsn: 'https://73a40738faa39a4a8139bdd38ab67183@o406554.ingest.us.sentry.io/4507140775084032',
  autoSessionTracking: true,
  logErrors: true,
  environment,
  enabled: environment !== 'localhost',
  release: environment === 'production' ? process.env.VUE_APP_APP_VERSION : '',
});

const setUserInSentry = (email) => {
  Sentry.setUser({ email });
};

const setTenantTagInSentry = (subdomain) => {
  Sentry.setTag('tenant', subdomain);
};
export { setUserInSentry, setTenantTagInSentry };
