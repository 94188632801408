import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"400"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('MyAccountManagementPage.changeLanguage'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.isOpen = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('MyAccountManagementPage.changeLanguageBody'))+" "),_c(VRadioGroup,{model:{value:(_vm.chosenLanguage),callback:function ($$v) {_vm.chosenLanguage=$$v},expression:"chosenLanguage"}},[_c(VRadio,{staticClass:"pb-2",attrs:{"label":"English","value":"en"}}),_c(VRadio,{attrs:{"label":"日本語","value":"ja"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }