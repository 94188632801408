import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6","lg":"5","xl":"4"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$t('common.appName'))+" - "+_vm._s(_vm.$t('forgotPassword.forgotPassword'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"white"},on:{"click":function($event){return _vm.$router.push({
                  name: 'login',
                  query: _vm.$route.query,
                })}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" "+_vm._s(_vm.$t('login.logIn'))+" ")],1)],1),(_vm.sendingMail)?_c(VProgressLinear,{staticClass:"mt-0 mb-0",attrs:{"indeterminate":true,"color":"pink","height":"6"}}):_vm._e(),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"label":_vm.$t('forgotPassword.enterRegisteredEmail'),"prepend-icon":"mdi-account","type":"email","required":"","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VAlert,{attrs:{"value":_vm.serverResponse.message !== null,"type":_vm.serverResponse.type,"outlined":"","transition":"scale-transition"}},[_vm._v(" "+_vm._s(_vm.serverResponse.message)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isLoginButtonDisabled,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('forgotPassword.getLink'))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('login-footer'),_c('RedirectionModal',{ref:"redirectionModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }