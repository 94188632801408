<template>
  <v-main>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col xs="12" sm="8" md="6" lg="5" xl="4">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>
                {{ $t('backup.enterBackupCode') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent>
                <v-text-field
                  v-model="backupCode"
                  :label="$t('backup.enterBackupCode')"
                  :rules="[rules.required]"
                  :hint="$t('backup.backupCodeHint')"
                  persistent-hint
                />
                <v-alert
                  class="mt-4"
                  :value="!!errorMessage"
                  outlined
                  transition="scale-transition"
                  type="error"
                >
                  {{ errorMessage }}
                </v-alert>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="isSubmitDisabled"
                    color="primary"
                    @click="submit"
                  >
                    {{ $t('common.submit') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <login-footer />
    <RedirectionModal ref="redirectionModal" />
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import LoginFooter from '../../components/LoginFooter.vue';
import RedirectionModal from '../../components/RedirectionModal.vue';

import throttleErrorMessage from '../../mixins/throttleErrorMessage';
import outlookLogin from '../../mixins/outlookLogin';

export default {
  components: {
    LoginFooter,
    RedirectionModal,
  },

  mixins: [throttleErrorMessage, outlookLogin],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data: () => ({
    backupCode: '',
    valid: false,
    isSubmitDisabled: true,
  }),

  computed: {
    rules() {
      return {
        required: (v) => !!v || this.$i18n.t('common.required'),
      };
    },
  },

  watch: {
    valid(isValid) {
      if (isValid) {
        this.isSubmitDisabled = false;
      } else {
        this.isSubmitDisabled = true;
      }
    },
  },

  created() {
    this.handleOutlookInitialize();
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.blue.darken2;
      this.$vuetify.theme.themes.light.accent = colors.pink.base;
    },

    submit() {
      this.errorMessage = null;
      if (!this.$refs.form.validate()) {
        return;
      }
      axios
        .post('backup-code', { backupCode: this.backupCode })
        .then((response) => {
          this.$store.dispatch('storeUser', response.data.user);
          if (this.isOutlookAddInLogin) {
            this.handleOutlookLogin();
            return;
          }
          this.$refs.redirectionModal.open();
        })
        .catch((error) => {
          this.displayErrorMessage(error.response.data);
        });
    },

    displayErrorMessage(error) {
      switch (error.error) {
        case 'invalid-backup-code':
          this.errorMessage = this.$t('backup.incorrectBackupCode');
          return;
        case 'locked-out':
          this.disableSubmitButton();
          this.displayRetryAfterXSecondsError(error.duration, 'backup');
          return;
        default:
          break;
      }
      this.$router.push({
        name: 'login',
        query: this.$route.query,
      });
    },

    disableSubmitButton() {
      this.valid = false;
    },

    enableBackupSubmitButton() {
      this.valid = true;
    },
  },
};
</script>
