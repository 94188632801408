import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('GetMoreServicesPageConfirmationDialog.title'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.isOpen = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('GetMoreServicesPageConfirmationDialog.messageLine1'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('GetMoreServicesPageConfirmationDialog.messageLine2'))+" ")]),_c(VSimpleTable,{staticClass:"mt-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.fields),function(item){return _c('tr',{key:item.key},[_c('td',[_vm._v(_vm._s(_vm.$t('GetMoreServicesPage.' + item.key)))]),_c('td',[_vm._v(" "+_vm._s(item.value === true ? _vm.$t('common.yes') : item.value)+" ")])])}),0)]},proxy:true}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c(VBtn,{attrs:{"color":"accent"},on:{"click":function($event){_vm.$emit('submit');
          _vm.isOpen = false;}}},[_vm._v(" "+_vm._s(_vm.$t('common.apply'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }