<template>
  <v-main>
    <v-container :style="{ 'max-width': '900px' }">
      <v-card>
        <v-card-title>
          {{ $t('IpAddressRestrictionPage.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('IpAddressRestrictionPage.description') }}

          <PremiumFeatureExplanation v-if="!isFeatureIpRestrictionSubscribed" />

          <template v-else>
            <IpAddressRestrictionPageTable
              :loading="loading"
              :whitelisted-ips="whitelistedIps"
              :is-ip-restriction-enabled="isIPRestrictionEnabled"
              class="my-4"
              @refresh="refresh"
            />

            <v-switch
              v-model="isIPRestrictionEnabled"
              class="d-inline-block mx-4"
              :label="$t('IpAddressRestrictionPage.enableIpRestriction')"
              :disabled="whitelistedIps.length === 0"
              @click.native.prevent.stop.capture="
                handleUpdateIPRestrictionConfiguration
              "
            />
          </template>
        </v-card-text>
      </v-card>
      <Confirm ref="confirm" />
    </v-container>
  </v-main>
</template>

<script>
import colors from 'vuetify/es5/util/colors';
import { mapGetters } from 'vuex';
import Confirm from '../../components/Confirm.vue';
import openSnackbar from '../../helpers';
import IpAddressRestrictionPageTable from './IpAddressRestrictionPageTable.vue';
import PremiumFeatureExplanation from './PremiumFeatureExplanation.vue';
import {
  getAllIps,
  enableIPRestriction,
  disableIPRestriction,
} from '../../api/ipRestriction.api';

export default {
  components: {
    IpAddressRestrictionPageTable,
    Confirm,
    PremiumFeatureExplanation,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data() {
    return {
      isIPRestrictionEnabled: false,
      loading: false,
      whitelistedIps: [],
    };
  },

  computed: {
    ...mapGetters({
      isIpRestrictionEnabledForCurrentTenant: 'isIPRestrictionEnabled',
      isFeatureIpRestrictionSubscribed: 'isFeatureIpRestrictionSubscribed',
    }),
  },

  watch: {
    isIpRestrictionEnabledForCurrentTenant(val) {
      this.isIPRestrictionEnabled = val;
    },
  },

  created() {
    if (this.isFeatureIpRestrictionSubscribed) {
      this.fetchAllowedIPs();
    }
    this.isIPRestrictionEnabled = this.isIpRestrictionEnabledForCurrentTenant;
  },

  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    async fetchAllowedIPs() {
      this.loading = true;
      try {
        const { data } = await getAllIps();
        this.whitelistedIps = data;
      } catch (error) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async handleUpdateIPRestrictionConfiguration() {
      if (this.isIPRestrictionEnabled === false) {
        const response = await this.openConfirmationDialog();
        if (response !== true) {
          return;
        }
        this.enableIPRestriction();
      } else {
        this.disableIPRestriction();
      }
    },

    openConfirmationDialog() {
      const title = this.$t('IpAddressRestrictionPage.confirmation.title');
      const message = this.$t('IpAddressRestrictionPage.confirmation.message');
      return this.$refs.confirm.open(title, message, { width: '450px' });
    },

    async enableIPRestriction() {
      try {
        await enableIPRestriction();
        openSnackbar(
          this.$t('IpAddressRestrictionPage.enabledSuccessfully'),
          'success',
        );
        this.isIPRestrictionEnabled = true;
        this.$store.dispatch('getUserInfo');
      } catch (error) {
        this.showErrorSnackbar(error);
      }

      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'adminEnabledIpRestriction',
      });
    },

    async disableIPRestriction() {
      try {
        await disableIPRestriction();
        openSnackbar(
          this.$t('IpAddressRestrictionPage.disabledSuccessfully'),
          'success',
        );
        this.isIPRestrictionEnabled = false;
        this.$store.dispatch('getUserInfo');
      } catch (error) {
        console.error(error);
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      }

      this.$ga.event({
        eventCategory: this.$route.meta.page,
        eventAction: 'adminDisabledIpRestriction',
      });
    },

    showErrorSnackbar(error) {
      const { message, ipAddress } = error.response.data;
      if (message === 'your-current-ip-address-is-not-whitelisted') {
        openSnackbar(
          this.$t('IpAddressRestrictionPage.currentIpNotWhitelisted', {
            ip: ipAddress,
          }),
          'error',
        );
      } else {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      }
    },

    async refresh() {
      this.loading = true;
      try {
        const { data } = await getAllIps();
        this.whitelistedIps = data;
        this.$store.dispatch('getUserInfo');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
