<template>
  <div>
    <v-main>
      <v-alert v-if="!currentTenant && isTenantManager" type="info">
        {{ $t('TenantManagerWarning.alert') }}
        {{ tenantName }}
      </v-alert>
      <v-container :style="{ 'max-width': '1500px' }">
        <v-card>
          <v-container fluid>
            <UserManagementPageToolbar
              :number-of-users="activeUsers.length"
              :fetching-users-data="isLoading"
              :users-with-null-last-active-at="usersWithNullLastActiveAt"
              :invite-sent-for-users.sync="inviteSentForUsers"
              @user-added="updateUsersFromServer"
              @user-edited="updateUsersFromServer"
              @click:enable-sso-for-all-users="handleEnableSsoForAllUsers"
              @click:disable-sso-for-all-users="handleDisableSsoForAllUsers"
            />
            <v-row justify="end">
              <v-col cols="6" class="pt-0">
                <v-text-field
                  v-model="query"
                  append-icon="mdi-magnify"
                  :label="$t('search.search')"
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-text class="pt-0">
            <v-data-table
              :headers="headers"
              :items="activeUsers"
              :options.sync="options"
              :footer-props="footerProps"
              :search="query"
              :sort-by="'created_at'"
              :sort-desc="true"
              :loading="isLoading"
            >
              <template #[`item.name`]="{ item }">
                {{ item.name }}
                <v-chip
                  v-if="item.operationStatus === 'deleting'"
                  color="warning"
                  x-small
                  class="mt-1"
                >
                  {{ $t('UserManagementPage.deleting') }}
                </v-chip>
              </template>
              <template #[`item.last_active_at`]="{ item }">
                <v-tooltip v-if="item.last_active_at !== null" bottom>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ getTimeFromNow(item.last_active_at) }}
                    </span>
                  </template>
                  <span>{{ formatTimestamp(item.last_active_at) }}</span>
                </v-tooltip>
                <span v-else>
                  <v-btn
                    text
                    small
                    color="accent"
                    class="px-0"
                    :disabled="isInviteSent(item)"
                    :loading="isSubmittingResetInviteForUser === item.id"
                    @click="resendInvite(item)"
                  >
                    <span v-if="isInviteSent(item)">
                      {{ $t('UserManagementPage.inviteSent') }}
                    </span>
                    <span v-else>
                      {{ $t('UserManagementPage.resendInvite') }}
                    </span>
                  </v-btn>
                </span>
              </template>

              <template #[`item.is_sso_enabled`]="{ item }">
                {{
                  item.is_sso_enabled
                    ? $i18n.t('adminPage.user.enabled')
                    : $i18n.t('adminPage.user.disabled')
                }}
              </template>

              <template #[`item.isTwofaEnabled`]="{ item }">
                {{
                  item.isTwofaEnabled
                    ? $i18n.t('adminPage.user.enabled')
                    : $i18n.t('adminPage.user.disabled')
                }}
              </template>

              <template #[`item.created_at`]="{ item }">
                <span v-if="item.created_at !== null">
                  {{ getDateString(item.created_at) }}
                </span>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      color="accent"
                      class="mx-0"
                      v-on="on"
                      @click="editUser(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('common.edit') }}</span>
                </v-tooltip>
                <v-menu>
                  <template #activator="{ on }">
                    <v-btn icon color="accent" class="mx-0" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="confirmDeactivate(item)">
                      <v-btn icon>
                        <v-icon>mdi-account-off</v-icon>
                      </v-btn>
                      <v-list-item-title>{{
                        $t('adminPage.user.deactivate')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteUser(item)">
                      <v-btn icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-list-item-title>{{
                        $t('common.delete')
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template #[`item.additionalDepartments`]="{ item }">
                <span>
                  {{
                    getAdditionalDepartmentsNames(item.additionalDepartments)
                  }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              text
              small
              color="primary"
              @click="fetchActivityLogsExportCsv"
            >
              {{ $t('UserManagementPage.downloadAuditLogs') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>

    <user-edit-modal ref="userEditModal" @user-edited="updateUsersFromServer" />

    <Confirm ref="confirm" />

    <DeleteUserWithCards
      ref="deleteUserWithCards"
      @deleted="updateUsersFromServer"
    />
  </div>
</template>

<script>
import colors from 'vuetify/es5/util/colors';

import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import openSnackbar from '../../../helpers';

import UserEditModal from './Edit/UserEditModal.vue';
import Confirm from '../../../components/Confirm.vue';
import UserManagementPageToolbar from './UserManagementPageToolbar.vue';
import DeleteUserWithCards from './DeleteUserWithCards.vue';
import {
  deactivateUser,
  resendInvite,
  getActivityLogsExportCsv,
  disableSsoForAllUsers,
  enableSsoForAllUsers,
} from '../../../api/admin.api';
import dateTimeHelpers from '../../../mixins/dateTimeHelpers';

export default {
  components: {
    UserEditModal,
    Confirm,
    UserManagementPageToolbar,
    DeleteUserWithCards,
  },

  mixins: [dateTimeHelpers],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTheme();
    });
  },

  data() {
    return {
      query: '',
      options: {
        itemsPerPage: 25,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25, 100],
      },
      isSubmittingResetInviteForUser: null,
      inviteSentForUsers: [],
      isSsoToggleInProgress: false,
    };
  },

  computed: {
    ...mapGetters([
      'activeUsers',
      'isFetchingUsers',
      'allowedAppsForTenant',
      'currentTenant',
      'isTenantManager',
      'currentTenantFromSubdomain',
    ]),

    headers() {
      const headers = [
        {
          text: this.$i18n.t('adminPage.user.department'),
          value: 'department.name',
          width: '14%',
        },
        {
          text: this.$i18n.t('adminPage.user.name'),
          value: 'name',
          width: '14%',
        },
        {
          text: this.$i18n.t('adminPage.user.email'),
          value: 'email',
          width: '24%',
        },
        {
          text: this.$i18n.t('adminPage.user.userType'),
          value: 'role',
          width: '9%',
        },
        {
          text: this.$i18n.t('adminPage.user.lastActiveAt'),
          value: 'last_active_at',
          width: '13%',
        },
      ];
      if (this.showSsoOptions) {
        headers.push({
          text: this.$i18n.t('adminPage.user.sso'),
          value: 'is_sso_enabled',
          width: '6%',
        });
      }
      headers.push(
        {
          text: this.$i18n.t('adminPage.user.2fa'),
          value: 'isTwofaEnabled',
          width: '6%',
        },
        {
          text: this.$i18n.t('adminPage.user.createdAt'),
          value: 'created_at',
          width: '10%',
        },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          sortable: false,
          width: '10%',
        },
      );
      const additionalDepartmentHeader = {
        text: this.$i18n.t('adminPage.user.additionalDepartments'),
        value: 'additionalDepartments',
        width: '10%',
      };
      if (this.isSchedulerAllowedForTenant) {
        headers[0].width = '10%';
        headers[1].width = '10%';
        headers.splice(1, 0, additionalDepartmentHeader);
      }
      return headers;
    },

    isSchedulerAllowedForTenant() {
      const allowedApps = this.allowedAppsForTenant;
      if (allowedApps.some((app) => app.key === 'scheduler')) {
        return true;
      }
      return false;
    },

    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    usersWithNullLastActiveAt() {
      return this.activeUsers.filter((user) => user.last_active_at === null);
    },

    tenantName() {
      if (this.currentTenantFromSubdomain === '') {
        return '';
      }
      return this.currentTenantFromSubdomain.name;
    },

    isLoading() {
      return this.isFetchingUsers || this.isSsoToggleInProgress;
    },

    showSsoOptions() {
      if (!this.currentTenant && this.isTenantManager) {
        return (
          this.currentTenantFromSubdomain.is_idp_configured &&
          this.currentTenantFromSubdomain.feature_sso_users_limit !== 0
        );
      }
      return (
        this.currentTenant.is_sso_enabled &&
        this.currentTenant.feature_sso_users_limit !== 0
      );
    },
  },

  created() {
    this.getUserInfo();
    this.updateUsersFromServer();
    this.updateDepartmentsFromServer();
  },

  methods: {
    ...mapActions([
      'updateDepartmentsFromServer',
      'updateUsersFromServer',
      'getUserInfo',
    ]),

    setTheme() {
      this.$vuetify.theme.themes.light.primary = colors.purple.darken2;
      this.$vuetify.theme.themes.light.accent = colors.teal.base;
    },

    async deleteUser(user) {
      this.$refs.deleteUserWithCards.open(user);
    },

    async confirmDeactivate(user) {
      const confirm = await this.$refs.confirm.open(
        this.$t('adminPage.user.deactivate'),
        this.$t('adminPage.user.deactivateConfirmation', {
          allowedApps: this.getAllowedAppsString(user.allowedApps),
        }),
        { color: 'warning', width: '450px' },
      );
      if (confirm === true) {
        this.deactivate(user.id);
      }
    },

    getAllowedAppsString(allowedApps) {
      let appsDisplay = '<ul>';
      allowedApps.forEach((appName) => {
        appsDisplay += `<li>${this.$t(`appNames.${appName}`)}</li>`;
      });
      appsDisplay += '</ul>';
      return appsDisplay;
    },

    async deactivate(id) {
      try {
        await deactivateUser(id);
        this.updateUsersFromServer();
        this.$store.dispatch('updateAllowedAppsForTenant');
        openSnackbar(
          this.$t('adminPage.user.deactivatedSuccessfully'),
          'success',
        );
      } catch (error) {
        if (error.response.data === 'cannot-deactivate-last-admin') {
          openSnackbar(
            this.$t('adminPage.user.cannotDeactivateLastAdmin'),
            'error',
          );
        } else {
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        }
      }
    },

    editUser(user) {
      this.$refs.userEditModal.open(user);
    },

    getAdditionalDepartmentsNames(additionalDepartments) {
      const departmentNames = additionalDepartments
        .map((addDept) => addDept.name)
        .join(', ');

      return departmentNames;
    },

    async resendInvite(user) {
      const confirm = await this.$refs.confirm.open(
        this.$t('UserManagementPage.confirmResendInviteTitle'),
        this.$t('UserManagementPage.confirmResendInviteBody'),
        { color: 'warning', width: '450px' },
      );
      if (confirm === true) {
        this.isSubmittingResetInviteForUser = user.id;
        await resendInvite([user]);
        this.isSubmittingResetInviteForUser = null;
        this.inviteSentForUsers.push(user);
        openSnackbar(
          this.$t('UserManagementPage.invitedPersonX', { name: user.name }),
          'success',
        );
      }
    },

    isInviteSent(user) {
      return this.inviteSentForUsers.some(
        (invitedUser) => invitedUser.id === user.id,
      );
    },

    getDateString(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    async fetchActivityLogsExportCsv() {
      const { data, headers } = await getActivityLogsExportCsv();
      this.prepareAndTriggerCsvDownload(data, headers);
    },

    prepareAndTriggerCsvDownload(data, headers) {
      const blob = this.createBlobFromResponse(data, headers['content-type']);
      const link = this.createCsvDownloadLink(blob);
      this.triggerCsvDownload(link);
    },
    createBlobFromResponse(data, type) {
      const csvDataWithUtf8Bom = this.prependUtf8BomToCsvData(data);
      const blob = new Blob([csvDataWithUtf8Bom], { type });
      return blob;
    },

    prependUtf8BomToCsvData(data) {
      const utf8Bom = '\ufeff';
      const csvData = data;
      const csvDataWithUtf8Bom = utf8Bom + csvData;
      return csvDataWithUtf8Bom;
    },

    createCsvDownloadLink(blob) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const currentTimeStamp = this.getCurrentTimestamp();
      link.download = `${this.$t(
        'UserManagementPage.userManagementAuditLogs',
      )}_${currentTimeStamp}.csv`;
      return link;
    },

    triggerCsvDownload(link) {
      link.click();
    },

    async handleEnableSsoForAllUsers() {
      this.isSsoToggleInProgress = true;
      try {
        const response = await enableSsoForAllUsers();
        if (response.data.message === 'cannot-enable-sso-for-all-users') {
          openSnackbar(
            this.$t('UserManagementPage.cannotEnableSsoForAllUsers'),
            'error',
            10000,
          );
        } else {
          openSnackbar(
            this.$t('UserManagementPage.ssoEnabledForAllUsers'),
            'success',
          );
        }
      } catch (error) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      } finally {
        this.isSsoToggleInProgress = false;
        this.updateUsersFromServer();
      }
    },

    async handleDisableSsoForAllUsers() {
      this.isSsoToggleInProgress = true;
      try {
        await disableSsoForAllUsers();
        openSnackbar(
          this.$t('UserManagementPage.ssoDisabledForAllUsers'),
          'success',
        );
      } catch (error) {
        openSnackbar(this.$t('common.somethingWentWrong'), 'error');
        throw error;
      } finally {
        this.isSsoToggleInProgress = false;
        this.updateUsersFromServer();
      }
    },
  },
};
</script>
