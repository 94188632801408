<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="true"
    fixed
    app
    mobile-breakpoint="960"
    width="250"
  >
    <v-list expand shaped nav>
      <v-list-item color="primary" :exact="true" :to="{ name: 'my-account' }">
        <v-list-item-action>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.myAccount') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-subheader v-if="isAdmin || isTenantManager">
        {{ $t('navDrawer.adminSettings') }}
      </v-subheader>

      <v-list-item
        v-if="isAdmin || isTenantManager"
        color="primary"
        :exact="true"
        :to="{ name: 'admin-user-management' }"
      >
        <v-list-item-action>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.usersManagement') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isAdmin || isTenantManager"
        color="primary"
        :exact="true"
        :to="{ name: 'admin-department-management' }"
      >
        <v-list-item-action>
          <v-icon>mdi-sitemap</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.manageDepartments') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isAdmin"
        color="primary"
        :exact="true"
        :to="{ name: 'tenant-settings' }"
      >
        <v-list-item-action>
          <v-icon>mdi-link</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.tenantSettings') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isAdmin"
        color="primary"
        :exact="true"
        :to="{ name: 'sso-setting' }"
      >
        <v-list-item-action>
          <v-icon>mdi-shield-key</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.sso') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isAdmin"
        color="primary"
        :exact="true"
        :to="{ name: 'ip-restriction' }"
      >
        <v-list-item-action>
          <v-icon>mdi-ip</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('navDrawer.ipRestriction') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="isAdmin">
        <v-divider class="mb-2" />

        <v-list-group :value="true">
          <template #activator>
            <v-list-item-icon>
              <v-icon> mdi-credit-card-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('navDrawer.billing') }}
            </v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            :exact="true"
            :to="{ name: 'contact-details' }"
          >
            <v-list-item-title class="ml-8">
              {{ $t('navDrawer.contactDetails') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            color="primary"
            :exact="true"
            :to="{ name: 'subscriptions' }"
          >
            <v-list-item-title class="ml-8">
              {{ $t('navDrawer.subscriptions') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            color="primary"
            :exact="true"
            :to="{ name: 'get-more-services' }"
          >
            <v-list-item-title class="ml-8">
              {{ $t('navDrawer.getMoreServices') }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>

      <v-divider v-if="isAdmin" />

      <v-subheader v-if="currentTenant">
        {{ $t('navDrawer.nextaApps') }}
      </v-subheader>

      <v-list-item
        v-if="
          currentTenant &&
          currentTenant.tenant_user.is_user_subscribed_to_meishi
        "
        :href="nextaApps.meishi.path"
      >
        <v-list-item-action class="mr-2">
          <v-avatar tile size="25">
            <img :src="nextaApps.meishi.logo" />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1">
            {{ nextaApps.meishi.nameShort }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="
          currentTenant &&
          currentTenant.tenant_user.is_user_subscribed_to_scheduler
        "
        :href="nextaApps.scheduler.path"
      >
        <v-list-item-action class="mr-2">
          <v-avatar tile size="25">
            <img :src="nextaApps.scheduler.logo" />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1">
            {{ nextaApps.scheduler.nameShort }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="
          currentTenant &&
          currentTenant.tenant_user.is_user_subscribed_to_meishi_operator
        "
        :href="nextaApps.meishi_operator.path"
      >
        <v-list-item-action class="mr-2">
          <v-avatar tile size="25">
            <img :src="nextaApps.meishi_operator.logo" />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1">
            {{ nextaApps.meishi_operator.nameShort }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-row justify="center">
        <v-col cols="8">
          <v-btn text small class="text-none">
            {{ $t('common.appNameShort') }} {{ $appVersion }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import nextaApps from '../nextaApps';

export default {
  data() {
    return {
      nextaApps,
    };
  },

  computed: {
    ...mapGetters(['user', 'currentTenant', 'isAdmin', 'isTenantManager']),
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(value) {
        this.$store.commit('setDrawer', value);
      },
    },
  },

  mounted() {
    this.$i18n.locale = this.user.language;
  },
};
</script>
