export default (initialSubdomain) => {
  let accountsPublicPath = '/';
  if (process.env.VUE_APP_BUILD_FOR === 'staging') {
    accountsPublicPath = '/staging-accounts/';
  } else if (process.env.NODE_ENV === 'production') {
    accountsPublicPath = '/accounts/';
  }

  const subdomain = window.App.helpers.getSubdomain();
  let { origin } = window.location;
  if (process.env.NODE_ENV === 'production') {
    if (subdomain === null) {
      const url = new URL(origin);
      origin = `${url.protocol}//${initialSubdomain}.${url.host}`;
    } else {
      origin = origin.replace(subdomain, initialSubdomain);
    }
  } else {
    origin = 'http://localhost:8100';
  }
  return origin + accountsPublicPath;
};
