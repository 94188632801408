<template>
  <v-footer app>
    <v-container fluid class="caption font-weight-thin pa-0">
      <v-row dense>
        <v-col cols="8" class="pb-0">
          <span
            :class="{ 'font-weight-bold': $i18n.locale === 'ja' }"
            style="cursor: pointer"
            @click="setLanguage('ja')"
          >
            日本語 (Japanese)
          </span>
          |
          <span
            :class="{ 'font-weight-bold': $i18n.locale === 'en' }"
            style="cursor: pointer"
            @click="setLanguage('en')"
          >
            英語 (English)
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center pt-0" cols="12">
          &copy; {{ new Date().getFullYear() }} {{ $t('common.tonichi') }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  methods: {
    setLanguage(value) {
      this.$i18n.locale = value;
    },
  },
};
</script>
