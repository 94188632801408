<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-stepper v-model="step">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-card>
            <v-card-title class="grey lighten-4 py-2 px-3 title">
              {{ $t('RedirectionModal.selectOrganization') }}
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="tenant in user.tenants"
                  :key="tenant.name"
                  @click="selectTenant(tenant)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="tenant.name" />
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-card>
            <v-card-title class="grey lighten-4 py-2 px-3 title">
              {{ $t('RedirectionModal.chooseApp') }}
            </v-card-title>
            <v-card-text>
              <v-list v-if="selectedTenant !== null">
                <v-list-item
                  v-if="selectedTenant.tenant_user.is_user_subscribed_to_meishi"
                  @click="redirectToApp('meishi')"
                >
                  <v-list-item-icon>
                    <v-avatar tile size="25">
                      <img :src="nextaApps.meishi.logo" />
                    </v-avatar>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="nextaApps.meishi.name" />
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item
                  v-if="
                    selectedTenant.tenant_user.is_user_subscribed_to_scheduler
                  "
                  @click="redirectToApp('scheduler')"
                >
                  <v-list-item-icon>
                    <v-avatar tile size="25">
                      <img :src="nextaApps.scheduler.logo" />
                    </v-avatar>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="nextaApps.scheduler.name" />
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import nextaApps from '../nextaApps';
import { setTenantTagInSentry } from '../plugins/sentry';

export default {
  data() {
    return {
      dialog: false,
      step: null,
      selectedTenant: null,
      nextaApps,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'isRedirectionModalOpen',
      'isTenantManager',
      'currentTenant',
    ]),

    continuePath() {
      const url = new URL(window.location.href);
      const continuePath = url.searchParams.get('continue');
      if (!continuePath) {
        return '/';
      }
      return continuePath;
    },

    isTenantManagerInContinuePath() {
      const app = 'tenant-manager';
      return this.continuePath.includes(app);
    },
  },
  watch: {
    selectedTenant(tenant) {
      if (tenant !== null) {
        setTenantTagInSentry(tenant.initial_subdomain);
        this.redirect();
      }
    },

    isRedirectionModalOpen(val) {
      if (val === true) {
        this.open();
      }
    },
  },
  methods: {
    open() {
      this.setTenant();
    },

    setTenant() {
      const subdomain = window.App.helpers.getSubdomain();
      if (subdomain === null) {
        if (this.isTenantManager && this.isTenantManagerInContinuePath) {
          this.redirectToTenantManagerApp();
        } else if (this.user.tenants.length === 1) {
          [this.selectedTenant] = this.user.tenants;
        } else {
          this.showChooseTenantStep();
        }
      } else {
        const tenant = this.$store.getters.currentTenant;
        if (typeof tenant === 'undefined') {
          this.showChooseTenantStep();
        } else {
          this.selectedTenant = tenant;
        }
      }
    },

    showChooseTenantStep() {
      this.dialog = true;
      this.step = 1;
    },

    redirect() {
      if (
        this.continuePath !== '/' &&
        this.isUserSubscribedToAppInContinuePath()
      ) {
        this.redirectTo(this.continuePath);
      } else {
        this.chooseAppAndRedirect();
      }
    },

    redirectTo(path) {
      let url = window.location.href;
      const subdomain = App.helpers.getSubdomain();
      let subdomainToRedirectTo = this.selectedTenant.initial_subdomain;
      if (this.selectedTenant.subdomain !== null) {
        subdomainToRedirectTo = this.selectedTenant.subdomain;
      }
      if (process.env.NODE_ENV === 'production') {
        if (subdomain === null) {
          url = this.prependSubdomainInUrl(subdomainToRedirectTo);
        } else {
          url = this.replaceSubdomainInUrl(subdomainToRedirectTo);
        }
      }
      url = new URL(url);
      window.location.href = `${url.protocol}//${url.host}${path}`;
    },

    prependSubdomainInUrl(subdomain) {
      const url = new URL(window.location.href);
      return `${url.protocol}//${subdomain}.${url.host}${url.pathname}${url.search}`;
    },

    replaceSubdomainInUrl(subdomain) {
      const url = window.location.href;
      return url.replace(url.split('//')[1].split('.')[0], subdomain);
    },

    chooseAppAndRedirect() {
      const apps = this.getAppsUserIsSubscribedToInTenant(this.selectedTenant);
      const numberOfApps = apps.length;
      if (numberOfApps <= 0) {
        this.redirectToAccountsApp();
      } else if (numberOfApps === 1) {
        this.redirectToApp(apps[0]);
      } else {
        this.showChooseAppStep();
      }
    },

    getAppsUserIsSubscribedToInTenant(tenant) {
      const apps = [];
      if (tenant.tenant_user.is_user_subscribed_to_meishi) {
        apps.push('meishi');
      }
      if (tenant.tenant_user.is_user_subscribed_to_scheduler) {
        apps.push('scheduler');
      }
      if (tenant.tenant_user.is_user_subscribed_to_meishi_operator) {
        apps.push('operator');
      }
      if (this.isTenantManager) {
        apps.push('tenant-manager');
      }
      return apps;
    },

    redirectToAccountsApp() {
      const path = this.getPathForAccountsSecurityPage();
      this.redirectTo(path);
    },

    redirectToTenantManagerApp() {
      const url = new URL(window.location.href);
      window.location.href = `${url.protocol}//${url.host}${this.continuePath}`;
    },

    getPathForAccountsSecurityPage() {
      switch (process.env.VUE_APP_BUILD_FOR) {
        case 'staging':
          return '/staging-accounts/my-account';
        default:
          return '/accounts/my-account';
      }
    },

    redirectToApp(app) {
      let path = null;
      switch (app) {
        case 'meishi':
          path = this.nextaApps.meishi.path;
          break;
        case 'scheduler':
          path = this.nextaApps.scheduler.path;
          break;
        default:
          path = this.getPathForAccountsSecurityPage();
          break;
      }
      this.redirectTo(path);
    },

    showChooseAppStep() {
      this.dialog = true;
      this.step = 2;
    },

    selectTenant(tenant) {
      this.selectedTenant = tenant;
    },

    isUserSubscribedToAppInContinuePath() {
      const apps = this.getAppsUserIsSubscribedToInTenant(this.selectedTenant);
      apps.push('accounts');

      for (let index = 0; index < apps.length; index++) {
        const app = apps[index];
        if (this.continuePath.includes(app)) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
